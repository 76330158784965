import React from "react";

import Lottie from "lottie-light-react";

const UIContent = ({ animation, content }) => {
    return (
        <div className='uiux__content'>
            <div className='uiux__content-paragraphs ui'>
                {content.map((uxParagraph) => (
                    <div className='uiux__content-paragraph' key={uxParagraph.id}>
                        <span className='uiux__content-paragraph-title'>{uxParagraph.title}</span>
                        <p className='uiux__content-paragraph-description'>{uxParagraph.description}</p>
                    </div>
                ))}
            </div>
            <div className='uiux__content-schema ui'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default UIContent;
