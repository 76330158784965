import { LoadingActionTypes } from "../actionTypes/loadingActionTypes";

// MAIN PAGE
export const setMainPageIsLoading = (payload) => ({
    type: LoadingActionTypes.MAIN_PAGE_SET_IS_LOADING,
    payload,
});

export const setMainPageIsInitialState = (payload) => ({
    type: LoadingActionTypes.MAIN_PAGE_SET_IS_INITIAL_STATE,
    payload,
});

// ALIEN PAGE
export const setAlienPageIsLoading = (payload) => ({
    type: LoadingActionTypes.ALIEN_PAGE_SET_IS_LOADING,
    payload,
});

export const setAlienPageIsInitialState = (payload) => ({
    type: LoadingActionTypes.ALIEN_PAGE_SET_IS_INITIAL_STATE,
    payload,
});

// AFFPRO PAGE
export const setAffproPageIsLoading = (payload) => ({
    type: LoadingActionTypes.AFFPRO_PAGE_SET_IS_LOADING,
    payload,
});

export const setAffproPageIsInitialState = (payload) => ({
    type: LoadingActionTypes.AFFPRO_PAGE_SET_IS_INITIAL_STATE,
    payload,
});
