export const aimlParagraphs = [
    {
        id: 1,
        title: "Define the purpose of the project and set the objective",
        description:
            "You need to define what problem you want to solve and what data you are going to use.",
    },
    {
        id: 2,
        title: "Data collection",
        description: "Collect all the necessary data and carry out initial analysis.",
    },
    {
        id: 3,
        title: "Prepare the data",
        description:
            "Prepare the data for use in training the model, by doing the necessary cleaning and transformations.",
    },
    {
        id: 4,
        title: "Visualize the data",
        description:
            "Construct graphs and charts to visually analyze the data and identify relationships between them.",
    },
    {
        id: 5,
        title: "Select a model",
        description: "Select an appropriate model for the task, and set its hyperparameters.",
    },
    {
        id: 6,
        title: "Train the model",
        description: "Train the model on the data and validate it.	",
    },
    {
        id: 7,
        title: "Evaluating the model",
        description: "Assess the quality of the model with the selected metrics.",
    },
    {
        id: 8,
        title: "Deploy the model",
        description: "Deploy the model to the chosen platform and test its performance in real-time.",
    },
    {
        id: 9,
        title: "Update the model",
        description: "Update the model as needed by adding new data or changing parameters.",
    },
    {
        id: 10,
        title: "Extend functionality",
        description:
            "If your model is performing well, you can add new features and machine learning algorithms to solve more complex problems.",
    },
];
