import React, { Fragment, useState, useEffect, useRef, memo } from "react";
import { ReactSVG } from "react-svg";
import { routePaths } from "../../shared/routes";
import Menu from "../Menu/Menu";
import logoAnimation from "../../assets/animations/fds-logo-animation.json";
import fireAnimation from "../../assets/animations/letsWork/fire.json";
import tridentSvg from "../../assets/svg/trident.svg";
import lottie from "lottie-web";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

const Header = (props) => {
    const { setIsMenuOpen } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isTop, setIsTop] = useState("");
    const navigate = useNavigate();
    const containerHeader = useRef(null);
    const animationHeader = useRef(null);
    const containerBtn = useRef(null);
    const animationBtn = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const containerRefs = [
            {
                containerRef: containerBtn,
                animationRef: animationBtn,
                animation: fireAnimation,
            },
            {
                containerRef: containerHeader,
                animationRef: animationHeader,
                animation: logoAnimation,
            },
        ];

        containerRefs.forEach(({ containerRef, animationRef, animation }) => {
            const animationConfigs = {
                container: containerRef.current,
                renderer: "svg",
                loop: true,
                autoplay: false,
                animationData: animation,
            };

            animationRef.current = lottie.loadAnimation(animationConfigs);
        });

        return () => {
            containerRefs.forEach(({ animationRef }) => {
                if (animationRef.current) {
                    animationRef.current.destroy();
                }
            });
        };
    }, []);

    const handleProductClick = (url) => {
        navigate(url);
        window.scrollTo(0, 0);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setIsMenuOpen(!isOpen);
        isOpen ? (document.body.style.overflow = "auto") : (document.body.style.overflow = "hidden");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsTop("scrolled");
            } else {
                setIsTop("");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLogoClick = (url) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate(url);
    };

    return (
        <Fragment>
            <header className={`header ${isTop}`} id='fds-header'>
                <div className='wrapper'>
                    <div className='headerContainer'>
                        <div className='header__logo'>
                            <div onClick={() => handleLogoClick(routePaths.mainPage)}>
                                <div
                                    ref={containerHeader}
                                    onMouseEnter={() => {
                                        animationHeader.current.playSegments(
                                            [0, animationHeader.current.totalFrames],
                                            true,
                                        );
                                    }}
                                    onMouseLeave={() => {
                                        animationHeader.current.goToAndStop(0);
                                    }}
                                />
                            </div>
                        </div>

                        <a
                            href='https://u24.gov.ua/'
                            className='supportLink'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            <ReactSVG
                                src={tridentSvg}
                                className='supportLink__svg'
                                wrapper='span'
                                beforeInjection={(svg) => {
                                    svg.classList.add("supportLink__svg");
                                }}
                                fallback={() => <Loader />}
                            />
                            <div>
                                <h4 className='supportLink__text'>
                                    {t("component.header.support-text")}
                                </h4>
                                <h5 className='supportLink__text supportLink__text--donate'>
                                    {t("component.header.donate-text")}
                                </h5>
                            </div>
                        </a>

                        <div className='headerContainer__right'>
                            <div
                                onClick={() => handleProductClick(routePaths.clientForm)}
                                className='headerContainer__letsWorkBtn mainBtn'
                                onMouseEnter={() => {
                                    animationBtn.current.playSegments(
                                        [0, animationBtn.current.totalFrames],
                                        true,
                                    );
                                }}
                                onMouseLeave={() => {
                                    animationBtn.current.goToAndStop(0);
                                }}
                            >
                                <span> {t("component.header.button-label")}</span>

                                <div ref={containerBtn}></div>
                            </div>

                            <button
                                type='button'
                                className={`burgerBtn ${isOpen ? "burgerBtn--close" : ""}`}
                                onClick={toggleMenu}
                            >
                                <div className='burgerBtn__container'>
                                    <div className='burgerBtn__line'></div>
                                    <div className='burgerBtn__line'></div>
                                    <div className='burgerBtn__line'></div>
                                </div>
                            </button>

                            <Menu
                                className={`${isOpen ? "opened" : ""}`}
                                setIsOpen={setIsOpen}
                                setIsMenuOpen={setIsMenuOpen}
                            />
                        </div>
                    </div>
                </div>

                <div className='header__border header__border--left'></div>
                <div className='header__border header__border--right'></div>
            </header>
        </Fragment>
    );
};

export default memo(Header);
