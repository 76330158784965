import {
    englishLevelOptions,
    kindOfProjectOptions,
    // soloTeamlOptions,
} from "../SelectOptions/dataSelection";
import { designFormModelObject } from "../FormModels/designFormModel";

const {
    formField: {
        full_name,
        location,
        languages_level,
        availability,
        // solo_team,
        // team_expirience,

        position,
        employee_level,
        experience,
        skills,

        hourly_salary,
        email,
        phone_numbers,
        telegram,
        linkedin,
        portfolio,
    },
} = designFormModelObject;

export const designFormInitialValuesObject = {
    [full_name.name]: "",
    [location.name]: "",
    [languages_level.name]: englishLevelOptions[0].value,
    [availability.name]: kindOfProjectOptions[0].value,
    // [solo_team.name]: soloTeamlOptions[0].value,
    // [team_expirience.name]: '',

    [position.name]: "",
    [employee_level.name]: "",
    [experience.name]: "",
    [skills.name]: [],

    [hourly_salary.name]: "",
    [email.name]: "",
    [phone_numbers.name]: "",
    [telegram.name]: "",
    [linkedin.name]: "",
    [portfolio.name]: "",
};
