import React from "react";
import { efficiencyParagraphs } from "../ElevantePhase.constants";
import "./EfficiencyContent.scss";
import animation from "../../../../../assets/animations/servicesTabs/elevate-efficenty-tab-animation.json";
import Lottie from "lottie-light-react";

const EfficiencyContent = () => {
    return (
        <div className='phase__content'>
            <div className='phase__content-paragraphs'>
                {efficiencyParagraphs.map((paragraph) => (
                    <div className='phase__content-paragraph' key={paragraph.id}>
                        <span className='phase__content-paragraph-title'>{paragraph.title}</span>
                        <p className='phase__content-paragraph-description'>{paragraph.description}</p>
                    </div>
                ))}
            </div>
            <div className='phase__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default EfficiencyContent;
