import { React } from "react";
const renderAsterisk = (text, isError) => {
    if (typeof text === "string" && text.length > 0) {
        const asteriskIndex = text.indexOf("*");
        if (asteriskIndex !== -1) {
            const textBeforeAsterisk = text.substring(0, asteriskIndex);
            const asterisk = text.substring(asteriskIndex, asteriskIndex + 1);
            const textAfterAsterisk = text.substring(asteriskIndex + 1);

            return (
                <div>
                    <span>{textBeforeAsterisk}</span>
                    <span className={`green-asterisk ${isError && "red-asterisk"}`}>{asterisk}</span>
                    <span>{textAfterAsterisk}</span>
                </div>
            );
        }
    }

    return text;
};

export default renderAsterisk;
