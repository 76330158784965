import axios from "axios";

const fdsApiBaseUrl = process.env.REACT_APP_FDS_API_BASE_URL;
const fdsPeopleForceBaseUrl = "https://app.peopleforce.io/api/public/v2/recruitment/candidates";
const peopleForseApiKey = "9XNN4EhJNyQfUXCWM6i54rsR2asqTBfqBZo42w5qotsAd7Vax7NS";

const checkEmailValues = {
    clientFormValue: "lead_form_client_exists",
    devFormValue: "lead_form_one_dev_exists",
    teamFormValue: "lead_form_team_exists",
};

const isEmailAlreadyUsed = async (value, emailCheckValue) => {
    let emailWasUsed = false;
    let param = "email=" + value;
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://newdevapi.focusdigitalsyndicate.com/api/v1/lead_form/check_email/?${param}`,
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        },
    };

    await axios.request(config).then((res) => {
        emailWasUsed = res.data?.[emailCheckValue];
    });

    return emailWasUsed;
};

const submitClientForm = (formData) => {
    if (!formData.phone_numberss || formData.phone_numberss === "") {
        delete formData.phone_numbers;
    }

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: fdsApiBaseUrl + "lead_form/client_lead_form/",
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        },
        data: formData,
    };

    return axios.request(config);
};

// const buildObjectForJsonFormat = (data, propKeyName, propValueName) => {
//     const object = {};
//     const keys = data.map((item) => item[propKeyName]);
//     const values = data.map((item) => item[propValueName]);
//     keys.forEach((key, i) => (object[key] = values[i]));
//     return object;
// };

const submitEmployeeForm = async (formData) => {
    const applicantStateId = 53384;
    let candidateConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: fdsPeopleForceBaseUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "X-API-KEY": peopleForseApiKey,
        },
        data: formData,
    };

    const candidateRequestResult = await axios.request(candidateConfig);

    let vacancyConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://app.peopleforce.io/api/public/v2/recruitment/vacancies/${formData.vacancy_id}/applications`,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-KEY": peopleForseApiKey,
        },
        data: {
            vacancy_id: formData.vacancy_id,
            applicant_id: candidateRequestResult.data.data.id,
            applicant_state_id: applicantStateId,
        },
    };

    axios.request(vacancyConfig);
};

export { submitClientForm, submitEmployeeForm, checkEmailValues, isEmailAlreadyUsed };
