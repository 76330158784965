import React, { memo, useState, useEffect } from "react";
import { motion } from "framer-motion";
import LetsWork from "../../components/LetsWork/LetsWork";
import DesignTab from "./DesignTab/DesignTab";
// import IndustryTab from './IndustryTab';

import "./Services.scss";
import EngineeringTab from "./EngineeringTab/EngineeringTab";
import Lottie from "lottie-light-react";
import servicesMainAnimation from "../../assets/animations/services-main-animation.json";
import designMainAnimation from "../../assets/animations/services/design/design-main-animation.json";
import outstaffMainAnimation from "../../assets/animations/services/outstaf/outstaff-main-animation.json";
import OutstaffTab from "./OutstaffTab/OutstaffTab";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentServiceGroup } from "../../redux/actions/servicesActions";
// import i18next from 'i18next'
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Services = ({ style }) => {
    const letsWorkTitle = "Let`s work on your project together";
    const [currentTab, setCurrentTab] = useState("engineering");
    const [currentAnimation, setCurrentAnimation] = useState(servicesMainAnimation);
    const dispatch = useDispatch();
    const currentServiceGroup = useSelector((state) => state.servicesReducer.currentServiceGroup);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    useEffect(() => {
        if (isMobile) return;
        const animationMap = {
            design: designMainAnimation,
            engineering: servicesMainAnimation,
            outstaff: outstaffMainAnimation,
        };
        setCurrentAnimation(animationMap[currentServiceGroup]);
    }, [isMobile, currentAnimation, currentServiceGroup]);

    const handleTabClick = (tabId) => {
        setCurrentTab(tabId);
        dispatch(setCurrentServiceGroup(tabId));
    };
    const tabs = [
        {
            id: "engineering",
            title: t("page.main-page.services-section.tab-buttons.engineering-button"),
            content: <EngineeringTab />,
        },
        {
            id: "design",
            title: t("page.main-page.services-section.tab-buttons.design-button"),
            content: <DesignTab />,
        },
        {
            id: "outstaff",
            title: t("page.main-page.services-section.tab-buttons.outstaff-button"),
            content: <OutstaffTab />,
        },
    ];
    return (
        <motion.section
            id='servicesSection'
            className='pageSection'
            style={style}
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ duration: 0.5 }}
        >
            <div className='wrapper'>
                <motion.div
                    className={`services ${"services--" + currentServiceGroup}`}
                    // initial={{ opacity: 0, y: 50 }}
                    // animate={{ opacity: 1, y: 0 }}
                    // transition={{ duration: 0.5 }}
                >
                    <div className='servicesInfo'>
                        <div className='servicesText'>
                            <h1 className='servicesText__title h2'>
                                {t("page.main-page.services-section.title")}
                            </h1>
                            <p className='servicesText__desc'>
                                {t("page.main-page.services-section.description")}
                            </p>
                        </div>

                        <div className='servicesImg'>
                            <Lottie
                                className='servicesImg__lottie'
                                animationData={currentAnimation}
                                autoplay={true}
                                loop={true}
                            />
                        </div>
                    </div>

                    <div className='servicesTabs' id='servicesContentModule'>
                        <div className='tabsList'>
                            {tabs.map((tab) => (
                                <motion.button
                                    type='button'
                                    key={tab.id}
                                    id={tab.id}
                                    data-active={currentServiceGroup === `${tab.id}`}
                                    className='tabsList__item'
                                    onClick={() => {
                                        handleTabClick(tab.id);
                                    }}
                                    // whileHover={{ scale: 1.05 }}
                                    // whileTap={{ scale: 0.95 }}
                                >
                                    {tab.title}
                                </motion.button>
                            ))}
                        </div>

                        <div className='servicesContent'>
                            {tabs.map((tab) => (
                                <motion.div
                                    className='servicesItems'
                                    data-content={currentServiceGroup === `${tab.id}`}
                                    key={tab.id}
                                    initial={{ display: "none" }}
                                    animate={{
                                        display: currentServiceGroup === tab.id ? "flex" : "none",
                                    }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {tab.content}
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    <LetsWork letsWorkTitle={letsWorkTitle} currentTab={currentTab} />
                </motion.div>
            </div>
        </motion.section>
    );
};

export default memo(Services);
