import { useState, useEffect } from "react";

export const useHeaderHeight = (layerHeaderId) => {
    const [headerHeightPx, setHeaderHeightPx] = useState(0);
    const [headerTitleSectionHeightPx, setHeaderTitleSectionHeightPx] = useState(0);

    useEffect(() => {
        const calculateHeaderHeight = () => {
            const headerHeightElement = document.getElementById("fds-header");
            const headerTitleSectionElement = document.getElementById(layerHeaderId + "0");

            if (headerHeightElement && headerTitleSectionElement) {
                setHeaderHeightPx(headerHeightElement.offsetHeight);
                setHeaderTitleSectionHeightPx(headerTitleSectionElement.offsetHeight);
            }
        };

        calculateHeaderHeight(); // Initial calculation

        const handleResize = () => {
            calculateHeaderHeight();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const checkHeaderHeight = () => {
            const headerHeightElement = document.getElementById("fds-header");
            const headerTitleSectionElement = document.getElementById(layerHeaderId + "0");

            if (headerHeightElement && headerTitleSectionElement) {
                setHeaderHeightPx(headerHeightElement.offsetHeight);
                setHeaderTitleSectionHeightPx(headerTitleSectionElement.offsetHeight);
            }
        };

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
            setTimeout(checkHeaderHeight, 1000); // Delayed execution to ensure correct height calculation
        }
    }, []);

    return { headerHeightPx, headerTitleSectionHeightPx };
};
