import React, { memo, useEffect, useRef, useState } from "react";
import "./ModernaCasaBlock.scss";
import { ReactSVG } from "react-svg";
import modernaCasaIcon from "../../../assets/svg/moderna-casa-logo.svg";
import rightArrow from "../../../assets/svg/link-right-arrow.svg";
import linkIcon from "../../../assets/svg/link-icon.svg";
import modernaCasaLogo from "../../../assets/svg/moderna-white-logo.svg";
import behanceIcon from "../../../assets/svg/behance.svg";
import devices from "../../../assets/images/moderna/moderna-devices.png";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import HeaderTabletComponent from "./HeaderTabletComponent/HeaderTabletComponent";
import BottomTabletComponent from "./BottomTabletComponent/BottomTabletComponent";
import Loader from "../../../components/Loader/Loader";

export const services = [
    "Engineering",
    "Web development",
    "Web design",
    "Construction",
    "Real estate",
    "Research",
    "Prototyping",
    "UI",
    "UX",
];

export const stack = [
    {
        id: 1,
        title: "Research",
        subtitles: [
            {
                id: 11,
                subtitle: "Research and Analysis",
            },
            {
                id: 12,
                subtitle: "User Persona Development",
            },
            {
                id: 13,
                subtitle: "Content Strategy",
            },
            {
                id: 14,
                subtitle: "Information Architecture",
            },
            {
                id: 15,
                subtitle: "Usability Testing",
            },
        ],
    },
    {
        id: 2,
        title: "Design",
        subtitles: [
            {
                id: 21,
                subtitle: "Style Exploration",
            },
            {
                id: 22,
                subtitle: "Sketching and Ideation",
            },
            {
                id: 23,
                subtitle: "Wireframing",
            },
            {
                id: 24,
                subtitle: "Prototyping",
            },
            {
                id: 25,
                subtitle: "Visual Design",
            },
        ],
    },
    {
        id: 3,
        title: "Development",
        subtitles: [
            {
                id: 31,
                subtitle: "Front-end development",
            },
            {
                id: 32,
                subtitle: "Back-end development",
            },
            {
                id: 33,
                subtitle: "Testing and quality assurance",
            },
        ],
    },
];

export const modernaCasaLink = "https://modernacasa.es/";

const ModernaCasaBlock = () => {
    const [currentStack, setCurrentStack] = useState(stack[0]);
    const aboutInfoBlockRef = useRef(null);
    const aboutImgBlockRef = useRef(null);
    const colorsBlockRef = useRef(null);

    const isDesktop = useMediaQuery({
        query: `(min-width: 1281px)`,
    });

    const isMobile = useMediaQuery({
        query: `(max-width: 719px)`,
    });

    useEffect(() => {
        const handleScroll = () => {
            if (!isDesktop) {
                return;
            }
            const leftBlock = aboutInfoBlockRef.current;
            const leftBlockBottom = leftBlock.getBoundingClientRect().bottom;
            const rightBlock = aboutImgBlockRef.current;
            const rightBlockBottom = rightBlock.getBoundingClientRect().bottom;
            const colorsBlock = colorsBlockRef.current;
            const colorsBlockBottom = colorsBlock.getBoundingClientRect().bottom;
            const windowHeight = window.innerHeight;

            if (leftBlockBottom < windowHeight) {
                leftBlock.classList.add("moderna-animate-in");
            }

            if (rightBlockBottom < windowHeight) {
                rightBlock.classList.add("moderna-animate-in");
            }

            if (colorsBlockBottom < windowHeight) {
                colorsBlock.classList.add("moderna-animate-in");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isDesktop]);

    return (
        <div className='moderna-casa__wrapper'>
            <div className='moderna-casa__navigation'>
                {!isMobile && (
                    <div className='moderna-casa__item'>
                        <div className='moderna-casa__item-svg'>
                            <ReactSVG
                                src={modernaCasaIcon}
                                wrapper='span'
                                style={{
                                    width: "20px",
                                    height: "16px",
                                }}
                                loading={() => <Loader />}
                            />
                        </div>
                        <span className='moderna-casa__item-title'>Modernacasa</span>
                    </div>
                )}
                <div className='moderna-casa__item'>2023</div>
                <div className='moderna-casa__item'>Spain, Barcelona</div>
                {!isMobile && (
                    <NavLink to={modernaCasaLink} className='moderna-casa__item' target='_blank'>
                        <span className='moderna-casa__item-title'>modernacasa.es</span>
                        <div className='moderna-casa__item-svg'>
                            <ReactSVG
                                src={rightArrow}
                                wrapper='span'
                                style={{
                                    width: "20px",
                                    height: "16px",
                                }}
                                loading={() => <Loader />}
                            />
                        </div>
                    </NavLink>
                )}
            </div>
            {isDesktop ? (
                <header className='moderna-casa__header'>
                    <div className='moderna-casa__header-img' />
                    <div className='moderna-casa__header-info'>
                        <NavLink
                            to='https://www.behance.net/designfds'
                            className='moderna-casa__header-link'
                            target='_blank'
                        >
                            <h1 className='moderna-casa__header-title'>Modernacasa.es</h1>
                            <ReactSVG
                                src={linkIcon}
                                className='moderna-casa__header-svg'
                                wrapper='span'
                                style={{
                                    width: "36px",
                                    height: "26px",
                                }}
                                loading={() => <Loader />}
                            />
                        </NavLink>
                        <h4 className='moderna-casa__header-description'>
                            Recharging your concept of the construction cycle is a must to avoid
                            immersing in chaos when building your dream home. MODERNACASA is perfect to
                            give a boost to your genuine ideas and harmonize every phase of your project
                            implementation. We provide a complete service from the initial requirements
                            collection and analysis to deliver high-quality results.
                        </h4>
                        <div className='moderna-casa__header-list'>
                            {services.map((service, index) => (
                                <div className='moderna-casa__header-list-item' key={service + index}>
                                    #{service}
                                </div>
                            ))}
                        </div>
                        <NavLink
                            className='moderna-casa__header-behance'
                            to='https://www.behance.net/designfds'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span>View the project on Behance</span>
                            <ReactSVG
                                src={behanceIcon}
                                wrapper='span'
                                style={{
                                    width: "24x",
                                    height: "16px",
                                }}
                                loading={() => <Loader />}
                            />
                        </NavLink>
                    </div>
                </header>
            ) : (
                <HeaderTabletComponent />
            )}

            <div className='moderna-casa__stack'>
                <div className='moderna-casa__stack-titles'>
                    {stack.map((stackItem) => (
                        <div
                            key={stackItem.id}
                            className={`moderna-casa__stack-title ${
                                stackItem.id === currentStack.id ? "active-stack-title" : ""
                            }`}
                            onClick={() => setCurrentStack(stackItem)}
                        >
                            {stackItem.title}
                        </div>
                    ))}
                </div>
                <div className='moderna-casa__stack-subtitles'>
                    {currentStack.subtitles.map((subtitleItem) => (
                        <div key={subtitleItem.id} className='moderna-casa__stack-subtitle'>
                            {subtitleItem.subtitle}
                        </div>
                    ))}
                </div>
            </div>
            <div className='moderna-casa__about'>
                <div className='moderna-casa__about-info' ref={aboutInfoBlockRef}>
                    <div className='moderna-casa__about-title'>
                        <h2>About</h2>
                        <h2>Modernacasa</h2>
                    </div>
                    <p className='moderna-casa__about-description'>
                        MODERNACASA is a cutting-edge construction service that delivers safe,
                        innovative, and high-quality engineering, home design, and building solutions.
                        Our strength is the strict adherence to the core values which are efficiency,
                        ingenuity, reliability, and customer-obsessed approach. We apply our
                        employees&apos; extensive experience, best practices, and modern technologies to
                        optimize workflow, quality, and cost in construction project development and
                        fulfillment. Our strategy of meeting the client&apos;s needs is based on a
                        distinct methodology that involves expense and schedule benchmarking, design
                        development, procurement of building materials, working process arranging, and
                        bringing the most daring ideas into reality. Ambitious goals and near-impossible
                        deadlines don&apos;t intimidate us since we are used to getting inspired by
                        extraordinary challenges that are preferred to take up for exceptional results.
                        Inventive projects need inclusion, creativity, and accuracy to be exceptional.
                        That&apos;s why they require MODERNACASA.
                    </p>
                </div>
                {isDesktop && <div className='moderna-casa__about-img' ref={aboutImgBlockRef} />}
            </div>
            {isDesktop ? (
                <div className='moderna-casa__content'>
                    <div className='moderna-casa__content_top'>
                        <div className='moderna-casa__content_top-logo moderna-bottom-block'>
                            <ReactSVG
                                src={modernaCasaLogo}
                                wrapper='span'
                                className='moderna-casa__top-svg'
                                loading={() => <Loader />}
                            />
                        </div>
                        <div className='moderna-casa__content_top-colors moderna-bottom-block'>
                            <div className='moderna-casa__content_top-colors-block' ref={colorsBlockRef}>
                                <div className='moderna-casa__content_top-color-block'>
                                    <div className='rico__content_top-color moderna-onyx' />
                                    <div className='moderna-casa__content_top-color-title '>#323436</div>
                                </div>
                                <div className='moderna-casa__content_top-color-block'>
                                    <div className='rico__content_top-color moderna-maize' />
                                    <div className='moderna-casa__content_top-color-title'>#FFE662</div>
                                </div>
                                <div className='moderna-casa__content_top-color-block'>
                                    <div className='rico__content_top-color moderna-black' />
                                    <div className='moderna-casa__content_top-color-title'>#000000</div>
                                </div>
                            </div>
                        </div>
                        <div className='moderna-casa__content_top-img' />
                    </div>
                    <div className='moderna-casa__content_bottom'>
                        <div className='moderna-casa__content_bottom-info-block'>
                            <div className='moderna-casa__content_bottom-info moderna-bottom-block'>
                                <h3 className='moderna-casa__content_bottom-info-title'>
                                    Building the future together
                                </h3>
                                <p className='moderna-casa__content_bottom-info-description'>
                                    Building Your Dreams: Elevate your living experience with our
                                    comprehensive and innovative construction services that make your
                                    dream home a reality. Our unwavering commitment to safety and luxury
                                    is reflected in every aspect of our work, from using the latest
                                    technology to employing the finest craftsmanship, resulting in
                                    exceptional quality and precision. You can trust us to bring your
                                    unique vision to life, creating a space that perfectly encapsulates
                                    your personality, lifestyle, and aspirations. With our expert team at
                                    the helm, you can sit back, relax, and enjoy the journey toward your
                                    dream home.
                                </p>
                            </div>
                            <div className='moderna-casa__content_bottom-images'>
                                <div className='moderna-casa__content_bottom-first-img' />
                                <div className='moderna-casa__content_bottom-second-img' />
                            </div>
                        </div>
                        <div className='moderna-casa__content_bottom-devices-block moderna-bottom-block'>
                            <img
                                src={devices}
                                className='moderna-casa__content_bottom-devices-img'
                                alt='Moderna devices'
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <BottomTabletComponent />
            )}
        </div>
    );
};

export default memo(ModernaCasaBlock);
