export const graphicDesignParagraps = [
    {
        id: 1,
        title: "Discovery",
        descriptions: [
            `Initial consultation to understand the client's goals`,
            `Researching the brand's personality, style and values`,
            `Defining the message and tone to be conveyed through motion design`,
            `Agreeing on the scope of work, terms and stages of the project`,
            `Preparation and submission of the proposal`,
        ],
    },
    {
        id: 2,
        title: "Design development",
        descriptions: [
            `Development of creative concept, storyboard and script`,
            "Development of visual elements, including characters, backgrounds and props",
            `Review and feedback with the client to refine the creative concept`,
            `Agreeing on the scope of work, terms and stages of the project`,
            `Approval of the creative concept by the client`,
        ],
    },
];

export const motionDesignParagraps = [
    {
        id: 1,
        title: "Production",
        descriptions: [
            `Creation of animation based on approved storyboard and script`,
            `Realization of visual effects and sound design`,
            `Review and feedback with the client to make sure the animation meets their expectations`,
            `Iterations to incorporate client feedback and finalize animation`,
        ],
    },
    {
        id: 2,
        title: "Finalization and Delivery",
        descriptions: [
            `Finalizing the animation and delivering it to the client in the desired format`,
            "Obtaining client approval and sign-off on the final product",
            `Handing over all source files and documentation to the client`,
        ],
    },
];
