import React, { memo } from "react";
import { ReactSVG } from "react-svg";
import { useTabletMedia } from "../../utils/useMediaQueries";
import FooterCopyright from "./FooterCopyright";
import { routePaths } from "../../shared/routes";
import footerLogo from "../../assets/svg/logo.svg";

import "./Footer.scss";
import { useDispatch } from "react-redux";
import { setCurrentService, setCurrentServiceGroup } from "../../redux/actions/servicesActions";
import { servicesConstants, servicesGroupsConstants } from "../../pages/Services/Services.constants";
import { useNavigate } from "react-router";
import { useMobileMedia } from "../../utils/useMediaQueries";
import Loader from "../Loader/Loader";

const footerData = [
    {
        title: "Legal",
        links: [
            {
                url: routePaths.privacyPolicyPage,
                label: "Terms and conditions",
                label2: "Privacy Policy",
            },
            {
                url: "mailto:support@focusdigitalsyndicate.com",
                label: "Feedback",
                className: "feedback-text",
            },
        ],
    },

    {
        title: "Services",
        links: [
            {
                url: routePaths.mainPage,
                label: "Web/Mobile development",
                serviceGroup: servicesGroupsConstants.engineering,
                service: servicesConstants.webDev,
            },
            {
                url: routePaths.mainPage,
                label: "AI/ML services",
                serviceGroup: servicesGroupsConstants.engineering,
                service: servicesConstants.aiml,
            },
            {
                url: routePaths.mainPage,
                label: "DevOps",
                serviceGroup: servicesGroupsConstants.engineering,
                service: servicesConstants.devops,
            },
            {
                url: routePaths.mainPage,
                label: "Elevate Phase ",
                serviceGroup: servicesGroupsConstants.engineering,
                service: servicesConstants.phase,
            },
            {
                url: routePaths.mainPage,
                label: "UI/UX Services",
                serviceGroup: servicesGroupsConstants.design,
                service: servicesConstants.ux,
            },
            {
                url: routePaths.mainPage,
                label: "Graphic & Motion",
                serviceGroup: servicesGroupsConstants.design,
                service: servicesConstants.motionDesign,
            },
            {
                url: routePaths.mainPage,
                label: "Outstaff",
                serviceGroup: servicesGroupsConstants.outstaff,
                service: servicesConstants.outstaff,
            },
        ],
    },
    {
        title: "Phone",
        className: "footerBlock--phone",
        links: [
            { url: "tel:+34674740712", label: "+34674740712" },
            { url: "tel:+34931291889", label: "+34931291889" },
        ],
    },
    {
        title: "Email",
        links: [
            {
                url: "mailto:sales@focusdigitalsyndicate.com",
                label: "sales@focusdigitalsyndicate.com",
            },
            {
                url: "mailto:partner@focusdigitalsyndicate.com",
                label: "partner@focusdigitalsyndicate.com",
            },
            {
                url: "mailto:career@focusdigitalsyndicate.com",
                label: "career@focusdigitalsyndicate.com",
            },
        ],
    },
];

const Footer = () => {
    const isMobile = useMobileMedia();
    const isTablet = useTabletMedia();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let mobileTop = isMobile ? 120 : 100;

    const handleLinkClick = (serviceGroupId, service, url) => {
        navigate(url);

        setTimeout(() => {
            const element = document.getElementById("servicesContentModule");

            if (element) {
                const topOffset = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: topOffset - mobileTop,
                    behavior: "smooth",
                });
            }
        }, 10);

        dispatch(setCurrentServiceGroup(serviceGroupId));
        dispatch(setCurrentService(service));
    };

    // const currentService = useSelector((state) => state.servicesReducer.currentService) // there was prittier error

    return (
        <footer className={`footer `}>
            <div className='wrapper'>
                <div className='footerContent'>
                    <div className='footerContent__logo'>
                        <ReactSVG
                            src={footerLogo}
                            wrapper='span'
                            className='footerContent__logo-svg'
                            loading={() => <Loader />}
                        />
                        <p className='footerContent__logo-year'>{new Date().getFullYear()}</p>

                        {isTablet && <FooterCopyright />}
                    </div>

                    <div className='footerInfo'>
                        {footerData.map((block, index) => (
                            <div key={index} className={`footerBlock ${block.className}`}>
                                <h3 className='footerBlock__title'>{block.title}</h3>

                                <div className='footerBlock__actions'>
                                    {block.links.map((link, index) => (
                                        <p className='footerBlock__link' key={index}>
                                            {link.serviceGroup ? (
                                                <button
                                                    type='button'
                                                    onClick={() =>
                                                        handleLinkClick(
                                                            link?.serviceGroup,
                                                            link?.service,
                                                            link?.url,
                                                        )
                                                    }
                                                >
                                                    {link.label}
                                                </button>
                                            ) : (
                                                <a href={link?.url}>
                                                    {link.label}{" "}
                                                    {link?.label2 && (
                                                        <>
                                                            <br />
                                                            <br /> {link.label2}{" "}
                                                        </>
                                                    )}{" "}
                                                </a>
                                            )}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {!isTablet && (
                <>
                    <div className='wrapper'>
                        <span className='footer__line'></span>

                        <FooterCopyright />
                    </div>
                </>
            )}
        </footer>
    );
};

export default memo(Footer);
