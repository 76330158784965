import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const HoverIconsAnimation = ({ animationData, currentItem, currentNumb }) => {
    const container = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        animationRef.current = lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: true,
            autoplay: currentItem === currentNumb,
            animationData: animationData,
        });

        return () => {
            animationRef.current.destroy();
        };
    }, [animationData, currentItem, currentNumb]);

    useEffect(() => {
        if (currentItem === currentNumb) {
            animationRef.current.play();
        } else {
            animationRef.current.stop();
        }
    }, [currentItem, currentNumb]);

    return <div ref={container} />;
};

export default HoverIconsAnimation;
