import {
    initialMainPageAnimationsStatus,
    initialAlienPageAnimationsStatus,
    initialAffproPageAnimationsStatus,
} from "../../loadingServices/loading.service";
import { LoadingActionTypes } from "../actionTypes/loadingActionTypes";

const initialState = {
    mainPageAnimationsData: initialMainPageAnimationsStatus,
    alienPageAnimationsData: initialAlienPageAnimationsStatus,
    affproPageAnimationsData: initialAffproPageAnimationsStatus,
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        // MAIN PAGE
        case LoadingActionTypes.MAIN_PAGE_SET_IS_INITIAL_STATE:
            return {
                ...state,
                mainPageAnimationsData: action.payload,
            };

        case LoadingActionTypes.MAIN_PAGE_SET_IS_LOADING:
            return {
                ...state,
                mainPageAnimationsData: {
                    ...state.mainPageAnimationsData,
                    [action.payload.key]: action.payload.value,
                },
            };

        // ALIEN PAGE
        case LoadingActionTypes.ALIEN_PAGE_SET_IS_INITIAL_STATE:
            return {
                ...state,
                alienPageAnimationsData: action.payload,
            };

        case LoadingActionTypes.ALIEN_PAGE_SET_IS_LOADING:
            return {
                ...state,
                alienPageAnimationsData: {
                    ...state.alienPageAnimationsData,
                    [action.payload.key]: action.payload.value,
                },
            };

        // AFFPRO PAGE
        case LoadingActionTypes.AFFPRO_PAGE_SET_IS_INITIAL_STATE:
            return {
                ...state,
                affproPageAnimationsData: action.payload,
            };

        case LoadingActionTypes.AFFPRO_PAGE_SET_IS_LOADING:
            return {
                ...state,
                affproPageAnimationsData: {
                    ...state.affproPageAnimationsData,
                    [action.payload.key]: action.payload.value,
                },
            };

        default:
            return state;
    }
};

export default loadingReducer;
