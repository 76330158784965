import React from "react";
import ufoIcon from "../../../assets/animations/alienclick/ufo.svg";
import men from "../../../assets/svg/men.svg";
import alien from "../../../assets/svg/alien-icon.svg";
import coins from "../../../assets/svg/coins.svg";
import ufo from "../../../assets/svg/ufo-icon.svg";
import phone from "../../../assets/svg/phone-icon.svg";
import parachute from "../../../assets/svg/parachute.svg";
import puzzle from "../../../assets/svg/puzzle.svg";
import sensorCloud from "../../../assets/svg/sensor-cloud.svg";

import { ReactSVG } from "react-svg";

export const tickerElements = [
    {
        id: 1,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },
    {
        id: 2,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },
    {
        id: 3,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },
    {
        id: 4,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },
    {
        id: 5,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },

    {
        id: 6,
        title: (
            <div className='affpro__animation-svg'>
                <ReactSVG
                    src={ufoIcon}
                    wrapper='span'
                    style={{
                        height: "60px",
                        width: `100%`,
                    }}
                />
            </div>
        ),
        // animation: affproAnimation,
    },
];

export const iconsList = [
    {
        id: 1,
        icon: men,
    },
    {
        id: 2,
        icon: ufo,
    },
    {
        id: 3,
        icon: coins,
    },
    {
        id: 4,
        icon: alien,
    },
    {
        id: 5,
        icon: parachute,
    },
    {
        id: 6,
        icon: sensorCloud,
    },
    {
        id: 7,
        icon: phone,
    },
    {
        id: 8,
        icon: puzzle,
    },
];
