import { combineReducers } from "redux";
import servicesReducer from "./servicesReducer";
import countryCodeReducer from "./countryCodeReducer";
import loadingReducer from "./loadingReducer";

const rootReducer = combineReducers({
    servicesReducer,
    loadingReducer,
    countryCodeReducer,
    // add more reducers here
});

export default rootReducer;
