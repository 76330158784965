import React, { useState } from "react";
import "./DevopmentBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import microchipIcon from "../../../../assets/svg/services/microchip.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import TechnologyContent from "./TechnologyContent/TechnologyContent";

import DevelopmentProcessContent from "./ProcessContent/DevelopmentProcessContent";

import developmentAnimation from "../../../../assets/animations/services/engineering/web-development.json";
import Lottie from "lottie-light-react";
import { useMediaQuery } from "react-responsive";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import animation from "../../../../assets/animations/servicesTabs/web-tab-animation.json";
import { devProcessParagraphs } from "./DevopmentBlock.constants";
const DevopmentBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("Process");
    const [isShema, setIsShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });

    return (
        <div className='development__wrapper'>
            <div className='development__title'>
                <h4 className='development__title-text'>Web/Mobile development</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={developmentAnimation} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='development__description'>
                Service provided by an FDS involves designing, building, and maintaining websites and
                web-based applications for clients, using the latest technologies and platforms. It
                includes a range of tasks, such as website design, front-end and back-end development,
                web content creation, website maintenance, and optimization for search engines. The goal
                is to help clients establish a strong online presence, increase their visibility, and
                engage with their target audience through a well-designed and user-friendly website or
                application.
            </div>
            <ServiceNavigation
                firstNavItem='Process'
                secondNavItem='Technology'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={currentNavItem === "Process" ? wayIcon : microchipIcon}
            />
            <div className='development__content'>
                {currentNavItem === "Process" ? (
                    !isMobile ? (
                        <DevelopmentProcessContent
                            animation={animation}
                            content={devProcessParagraphs}
                        />
                    ) : (
                        <ServicesMobileTab
                            isShema={isShema}
                            setIsShema={setIsShema}
                            animation={animation}
                            content={devProcessParagraphs}
                        />
                    )
                ) : (
                    <TechnologyContent />
                )}
            </div>
        </div>
    );
};

export default DevopmentBlock;
