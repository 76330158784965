import React, { useState } from "react";
import "./EngineeringTab.scss";
import DevopsBlock from "./DevopsBlock/DevopsBlock";
import DevopmentBlock from "./DevelopmentBlock/DevopmentBlock";
import ElevantePhaseBlock from "./ElevantePhaseBlock/ElevantePhaseBlock";
import AIMLBlock from "./AIMLBlock/AIMLBlock";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentService } from "../../../redux/actions/servicesActions";
import aiAnimation from "../../../assets/animations/services/engineering/ai-services.json";
import devopsAnimation from "../../../assets/animations/services/engineering/DevOps.json";
import developmentAnimation from "../../../assets/animations/services/engineering/web-development.json";
import elevateAnimation from "../../../assets/animations/services/engineering/Elevate.json";
import HoverIconsAnimation from "../../../components/HoverIconsAnimation/HoverIconsAnimation";
import { handleTabClick } from "../../../shared/handleTabClick";

const dataEngineering = [
    {
        number: 1,
        id: "webDev",
        icon: developmentAnimation,
        title: "Web/Mobile",
        titleBottom: "development",
        className: "webDev",
        children: <DevopmentBlock />,
    },
    {
        number: 2,
        id: "aiml",
        icon: aiAnimation,
        title: "AI/ML",
        titleBottom: "services",
        className: "aiml",
        children: <AIMLBlock />,
    },
    {
        number: 3,
        id: "devops",
        icon: devopsAnimation,
        title: "DevOps",
        titleBottom: "",
        className: "devops",
        children: <DevopsBlock />,
    },
    {
        number: 4,
        id: "phase",
        icon: elevateAnimation,
        title: "Elevate Phase ",
        titleBottom: "",
        className: "phase",
        children: <ElevantePhaseBlock />,
    },
];

const EngineeringTab = () => {
    const [currentItem, setCurrentItem] = useState(0);
    const dispatch = useDispatch();
    const currentService = useSelector((state) => state.servicesReducer.currentService);

    return (
        <div className='service_tab'>
            <div className='service_tab-titles'>
                {dataEngineering.map((item, index) => (
                    <div
                        className={`servicesItem ${"servicesItem--" + item.className} ${
                            currentService === item.id ? "active" : ""
                        }`}
                        key={item.id}
                        onClick={() =>
                            handleTabClick(item.id, dispatch, setCurrentService, currentService)
                        }
                        onMouseEnter={() =>
                            item.number === index + 1 ? setCurrentItem(item.number) : 0
                        }
                        onMouseLeave={() => {
                            setCurrentItem(0);
                        }}
                    >
                        <div className='servicesItem__icon'>
                            <HoverIconsAnimation
                                animationData={item.icon}
                                currentItem={currentItem}
                                currentNumb={item.number}
                            />
                        </div>
                        <div className='servicesItem__text'>
                            <p>
                                {item.title}{" "}
                                <span className='servicesItem__textLine'>{item.titleBottom}</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                {
                    dataEngineering.find((engeneeringItem) => engeneeringItem.id === currentService)
                        ?.children
                }
            </div>
        </div>
    );
};

export default EngineeringTab;
