import React, { memo } from "react";
import "./Loader.scss";
import Lottie from "lottie-light-react";
import LoaderAnimation from "../../assets/animations/fds-loader.json";

const Loader = () => {
    return (
        <section className='loader__wrapper'>
            <div className='loader__animation'>
                <Lottie animationData={LoaderAnimation} autoplay loop playsInline />
            </div>
        </section>
    );
};

export default memo(Loader);
