import React, { memo, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./ArchivableLayer.scss";
import Loader from "../Loader/Loader";
import { initialMainPageAnimationsStatus } from "../../loadingServices/loading.service";
import { useDispatch } from "react-redux";
import { setMainPageIsInitialState } from "../../redux/actions/loadingActions";
import { useHeaderHeight } from "../../shared/hooks/useHeaderHeight";

const ArchivableLayer = (props) => {
    const {
        layers,
        className = "",
        layerBoxId = "layerBox-",
        layerHeaderId = "layerHeaderBox-",
        layerContentId = "layerContentBox-",
    } = props;

    const dispatch = useDispatch();
    const { headerHeightPx } = useHeaderHeight(layerHeaderId);

    const [allAnimationsLoaded, setAllAnimationsLoaded] = useState(false);

    const onLayerHeaderClick = useCallback((index) => {
        const element = document.getElementById(layerBoxId + index);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            document.body.style.overflow = "auto";
        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setAllAnimationsLoaded(true), 3000);

        return () => {
            clearTimeout(timeout);
            dispatch(setMainPageIsInitialState(initialMainPageAnimationsStatus));
        };
    }, []);

    const getLayers = (layers, index) => {
        if (!layers || index >= layers.length) return <></>;
        return (
            <Box key={layerBoxId + index} id={layerBoxId + index}>
                {!allAnimationsLoaded && <Loader />}
                <Box
                    sx={{
                        position: "sticky",
                        border: "1px solid black",
                        borderRadius: "1rem 1rem 0 0",
                        cursor: "pointer",
                        top: headerHeightPx / 16 + index * 1 + "rem",
                        backgroundColor: layers[index]?.backgroundColor || "#FFFFFF",
                        zIndex: 501,
                    }}
                    key={layerHeaderId + index}
                    id={layerHeaderId + index}
                    className={index < layers.length - 1 ? "layerHeaderBox" : ""}
                    onClick={() => {
                        onLayerHeaderClick(index);
                    }}
                >
                    <h5 className='layerTitle'>{layers[index].title}</h5>
                </Box>
                <Box key={layerContentId + index} id={layerContentId + index}>
                    {layers[index].content}
                </Box>
                {getLayers(layers, index + 1)}
            </Box>
        );
    };

    return <Box className={className}>{getLayers(layers, 0)}</Box>;
};

export default memo(ArchivableLayer);
