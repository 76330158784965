import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import { FormHelperText } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import hoverCursor from "../../../assets/hover-cursor.png";
import renderAsterisk from "../../../shared/renderAsterisk";

const SelectField = (props) => {
    const { label, data, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, "touched", "error");
    const isError = touched && error && true;

    const renderHelperText = () => {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    };

    return (
        <>
            <FormControl {...rest} error={isError} sx={{ marginBottom: "2rem" }} fullWidth>
                <InputLabel id={"select-label-id-" + label}>{renderAsterisk(label, isError)}</InputLabel>
                <Select
                    labelId={"select-label-id-" + label}
                    label={label}
                    {...field}
                    value={selectedValue ? selectedValue : ""}
                    className='selectorInput'
                    sx={{
                        "& .MuiSelect-select": {
                            cursor: `url(${hoverCursor}), pointer`,
                        },
                        "& .selectorMenuItem": {
                            cursor: `url(${hoverCursor}), pointer`,
                        },
                    }}
                >
                    {data.map((item, index) => (
                        <MenuItem
                            className='selectorMenuItem'
                            key={index}
                            value={item.value}
                            sx={{
                                cursor: `url(${hoverCursor}), pointer`,
                            }}
                            onClick={() =>
                                item.value === "Google Meet" &&
                                window.open("https://paul4ufe.setmore.com/paulbourgeais", "_blank")
                            }
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {renderHelperText()}
            </FormControl>
        </>
    );
};
SelectField.defaultProps = {
    data: [],
};

SelectField.propTypes = {
    data: PropTypes.array.isRequired,
};

export default SelectField;
