import React, { memo, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import gif from "./img/focus.gif";
import gif2 from "./img/cosmos.gif";
import gif3 from "./img/cat.gif";
import triangle from "./img/triangle.svg";
import Lottie from "lottie-light-react";

import {
    useLargeMedia,
    useHeightMedia,
    useTabletMedia,
    useMobileMedia,
    useSmallMobileMedia,
} from "../../utils/useMediaQueries";

import "./Home.scss";
import Loader from "../../components/Loader/Loader";

import { fetchCountryCode } from "../../redux/actions/countyCodeActions";

import { mainPageAnimationTitles } from "../../loadingServices/loading.service";
import mainAnimation from "../../assets/animations/services-main-animation.json";
import { setMainPageIsLoading } from "../../redux/actions/loadingActions";
import { useTranslation } from "react-i18next";

const Home = () => {
    const dispatch = useDispatch();
    const isLargeMedia = useLargeMedia();
    const isHeightMedia = useHeightMedia();
    const isTabletMedia = useTabletMedia();
    const isMobileMedia = useMobileMedia();
    const isSmallMobileMedia = useSmallMobileMedia();
    const [hovered, setHovered] = useState("");
    const { t } = useTranslation();

    const titleInfo = [
        {
            title: "focus",
            descriptionAfter: t("page.main-page.who-we-are-section.description-focus-after"),
            id: "focus",
            titleImgClass: "titleImg--focus",
            img: gif,
            animationName: mainPageAnimationTitles.focusAnimation,
        },
        {
            title: "digital",
            descriptionBefore: t("page.main-page.who-we-are-section.description-digital-before"),
            descriptionAfter: t("page.main-page.who-we-are-section.description-digital-after"),
            id: "digital",
            titleImgClass: "titleImg--digital",
            img: gif2,
            animationName: mainPageAnimationTitles.digitalAnimation,
        },
        {
            title: "syndicate",
            descriptionAfter: t("page.main-page.who-we-are-section.description-syndicate-after"),
            id: "syndicate",
            titleImgClass: "titleImg--syndicate",
            img: gif3,
            animationName: mainPageAnimationTitles.syndicateAnimation,
        },
    ];

    useEffect(() => {
        dispatch(fetchCountryCode());
    }, [dispatch]);

    const setIsLoading = (key, value) => {
        dispatch(
            setMainPageIsLoading({
                key: key,
                value: value,
            }),
        );
    };

    const handleHover = (id) => {
        setHovered(id);
    };

    const handleLeave = () => {
        setHovered("");
    };

    // for mobile title clicking
    const handleClick = (id) => {
        setHovered(id === hovered ? "" : id);
    };

    // responsive position of title animation
    let titleHeight = 468;
    let imgHeight = 337;
    let focusImgTop = 164;
    let digitalImgTop = 275;
    let syndicateImgTop = 385;
    // let widthImg = window.innerWidth;
    let widthImg = "100%";
    let titleHeightInitial = "auto";

    if (isSmallMobileMedia) {
        titleHeight = 342;
        imgHeight = 260;
        focusImgTop = 120;
        digitalImgTop = 168;
        syndicateImgTop = 216;
        titleHeightInitial = 48;
    } else if (isMobileMedia) {
        titleHeight = 342;
        imgHeight = 260;
        focusImgTop = 120;
        digitalImgTop = 142;
        syndicateImgTop = 216;
        titleHeightInitial = 48;
    } else if (isTabletMedia) {
        titleHeight = 496;
        imgHeight = 420;
        focusImgTop = 180;
        digitalImgTop = 260;
        syndicateImgTop = 338;
    } else if (isHeightMedia) {
        titleHeight = 302;
        imgHeight = 228;
        focusImgTop = 114;
        digitalImgTop = 188;
        syndicateImgTop = 263;
    } else if (isLargeMedia) {
        titleHeight = 420;
        imgHeight = 300;
        focusImgTop = 158;
        digitalImgTop = 270;
        syndicateImgTop = 380;
    }

    const variants = {
        cursor: {
            opacity: [1, 0],
            height: "auto",
            transition: { duration: 0.2, repeat: 4, repeatType: "reverse" },
        },

        cursorBack: {
            opacity: [1, 0],
            transition: { duration: 0.2, delay: 0.4 },
        },

        titleState: {
            height: titleHeight,
        },

        titleStateBack: {
            height: titleHeightInitial,
        },

        focusImg: {
            width: widthImg,
            height: imgHeight,
            top: focusImgTop,
            right: 0,
            rotate: 0,
        },

        digitalImg: {
            width: widthImg,
            height: isMobileMedia && !isSmallMobileMedia ? 286 : imgHeight,
            top: digitalImgTop,
            right: 0,
            rotate: 0,
        },

        syndicateImg: {
            width: widthImg,
            height: imgHeight,
            top: syndicateImgTop,
            right: 0,
            rotate: 0,
        },

        focusImgInitial: {
            rotate: -30,
            // top: 'calc(100% - 206px)',
            // right: 40
        },

        digitalImgInitial: {
            rotate: 4,
            // top: 'calc(100% - 226px)',
            // right: 146
        },

        syndicateImgInitial: {
            rotate: 11,
            // top: 'calc(100% - 316px)',
            // right: 78
        },
    };

    return (
        <section id='homeSection' className='pageSection'>
            <div className='wrapper'>
                <div className='main-animation-wrapper'>
                    <Lottie
                        style={{ opacity: hovered ? 0 : 0.1 }}
                        animationData={mainAnimation}
                        autoplay={true}
                        loop={true}
                    />
                </div>

                <div className='mainPage'>
                    <div className={`mainTitle ${hovered && "active"}`}>
                        {titleInfo.map((item) => (
                            <motion.div
                                className={`titleItem ${item.id} ${
                                    hovered === item.id ? "hovered" : ""
                                }`}
                                key={item.title}
                                onMouseLeave={handleLeave}
                                variants={variants}
                                animate={hovered === item.id ? `titleState` : "titleStateBack"}
                                transition={
                                    isTabletMedia
                                        ? { ease: "easeOut", duration: 0.6 }
                                        : {
                                              height: {
                                                  ease: [0.2, 0.5, 0.5, 1.15],
                                                  duration: 0.6,
                                              },
                                          }
                                }
                            >
                                <div className='titleText '>
                                    <motion.div
                                        className='titleText__before '
                                        onClick={isTabletMedia ? () => handleClick(item.id) : null}
                                        onMouseEnter={!isTabletMedia ? () => handleHover(item.id) : null}
                                        // onMouseEnter={() => handleHover(item.id)}
                                    >
                                        {item.descriptionBefore ? (
                                            <div className='titleText__desc h3 titleText__desc--before'>
                                                <motion.h3
                                                    className=' titleText__desc-h3'
                                                    animate={
                                                        hovered === item.id
                                                            ? {
                                                                  width: "auto",
                                                                  paddingRight: "4px",
                                                                  transition: { duration: 0.6 },
                                                              }
                                                            : { width: 0, transition: { duration: 0.4 } }
                                                    }
                                                >
                                                    {item.descriptionBefore}
                                                </motion.h3>

                                                <motion.span
                                                    variants={variants}
                                                    animate={
                                                        hovered === item.id ? "cursor" : "cursorBack"
                                                    }
                                                >
                                                    |
                                                </motion.span>
                                            </div>
                                        ) : (
                                            false
                                        )}
                                        <h1
                                            className='titleText__main h1'
                                            // style={{ fontSize: fontSizeTitle }}
                                        >
                                            {item.title}

                                            {isTabletMedia && (
                                                <ReactSVG
                                                    src={triangle}
                                                    className='titleText__mainSvg'
                                                    loading={() => <Loader />}
                                                />
                                            )}
                                        </h1>
                                    </motion.div>

                                    <div className='titleText__desc h3'>
                                        <motion.h3
                                            className='titleText__desc-h3'
                                            animate={
                                                hovered === item.id
                                                    ? {
                                                          width: "auto",
                                                          paddingRight: "4px",
                                                          transition: { duration: 0.6 },
                                                      }
                                                    : { width: 0, transition: { duration: 0.4 } }
                                            }
                                        >
                                            {item.descriptionAfter}
                                        </motion.h3>

                                        <motion.span
                                            variants={variants}
                                            animate={hovered === item.id ? "cursor" : "cursorBack"}
                                        >
                                            |
                                        </motion.span>
                                    </div>

                                    <motion.div
                                        onAnimationComplete={() => {
                                            setIsLoading(item.animationName, true);
                                        }}
                                        className={`titleImg ${item.titleImgClass}`}
                                        variants={variants}
                                        initial={`${item.id}ImgInitial`}
                                        animate={
                                            hovered === item.id
                                                ? `${item.id}Img`
                                                : `${item.id}ImgInitial`
                                        }
                                        transition={
                                            isTabletMedia
                                                ? { ease: "easeOut", duration: 0.6 }
                                                : {
                                                      ease: [0.2, 0.5, 0.5, 1.15],
                                                      duration: 0.6,
                                                  }
                                        }
                                    >
                                        <img src={item.img} alt={`${item.animationName}`} />
                                    </motion.div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(Home);
