import React, { useRef, useState, useEffect, memo, useMemo } from "react";
import { ReactSVG } from "react-svg";

import DrawCanvas from "./DrawCanvas";
import ReactPlayer from "react-player";
import videoUrl from "./img/connect.mp4";
import videoRico from "./img/rico.mp4";
import videoAfpro from "./img/affpro.mp4";
import videoAlienGreen from "./img/alien-green.mp4";
import react from "./img/react.svg";
import reduxIcon from "./img/redux.svg";
import tsIcon from "./img/typescript.svg";
import styledIcon from "./img/styled.svg";
import githubIcon from "./img/github.svg";
import pythonIcon from "./img/python.svg";
import restIcon from "./img/rest.svg";
import materialIcon from "./img/material.svg";
import nodejsIcon from "./img/nodejs.svg";
import exIcon from "./img/ex.svg";
import firebaseIcon from "./img/firebase.svg";

import Lottie from "lottie-light-react";
import alienJson from "./img/click.json";

import { routePaths } from "../../shared/routes";

import "./Products.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setMainPageIsLoading } from "../../redux/actions/loadingActions";
import { mainPageAnimationTitles } from "../../loadingServices/loading.service";
import Loader from "../../components/Loader/Loader";

const Products = () => {
    // const isTabletMedia = useTabletMedia();
    const isTabletMedia = useMediaQuery({ query: "(max-width: 768px)" });
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const videoRicoRef = useRef(null);
    const videoAfproRef = useRef(null);
    const videoAlienRef = useRef(null);
    // const [intervalId, setIntervalId] = useState(null)
    const [intervalIdRico, setIntervalIdRico] = useState(null);
    const [intervalIdAffpro, setIntervalIdAffpro] = useState(null);
    const [isHoveredConnect, setIsHoveredConnect] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProductClick = (url) => {
        // dispatch(setCurrentServiceGroup(servicesGroupsConstants.engineering));
        // dispatch(setCurrentService(null));

        navigate(url);
        window.scrollTo(0, 0);
    };

    const playVideoRicoFromStart = () => {
        videoRicoRef?.current?.play().catch((error) => console.log("Autoplay error:", error));

        const idPlayBack = setInterval(() => {
            if (videoRicoRef.current) {
                if (videoRicoRef.current.currentTime >= 3.8) {
                    videoRicoRef.current.currentTime = 0;
                }
            }
        }, 1000);
        setIntervalIdRico(idPlayBack);
    };

    const playVideoAffproFromStart = () => {
        videoAfproRef?.current?.play().catch((error) => console.log("Autoplay error:", error));

        const id = setInterval(() => {
            if (videoAfproRef.current) {
                if (videoAfproRef.current.currentTime >= 2.4) {
                    videoAfproRef.current.currentTime = 0;
                }
            }
        }, 100);

        setIntervalIdAffpro(id);
    };

    useEffect(() => {
        if (!isTabletMedia) {
            if (videoRicoRef.current) {
                videoRicoRef.current.currentTime = 0;
                playVideoRicoFromStart();
            }
            if (videoAfproRef.current) {
                videoAfproRef.current.currentTime = 0;
                playVideoAffproFromStart();
            }
        } else {
            if (videoRef.current) {
                videoRef.current.currentTime = 5;
                videoRef?.current?.play().catch((error) => console.log("Autoplay error:", error));
            }

            if (videoRicoRef.current) {
                videoRicoRef.current.currentTime = 0;
                videoRicoRef?.current?.play().catch((error) => console.log("Autoplay error:", error));

                const idPlay = setInterval(() => {
                    if (videoRicoRef.current) {
                        if (videoRicoRef.current.currentTime >= 14.3) {
                            videoRicoRef.current.currentTime = 2.3;
                        }
                    }
                }, 1000);

                setIntervalIdRico(idPlay);
            }

            if (videoAfproRef.current) {
                videoAfproRef.current.currentTime = 0;
                videoAfproRef?.current?.play().catch((error) => console.log("Autoplay error:", error));

                const idPlay = setInterval(() => {
                    if (videoAfproRef.current) {
                        if (videoAfproRef.current.currentTime >= 9) {
                            videoAfproRef.current.currentTime = 1;
                        }
                    }
                }, 1000);

                setIntervalIdAffpro(idPlay);
            }
        }
    }, [isTabletMedia]);

    const handleHoverRico = () => {
        clearInterval(intervalIdRico);

        videoRicoRef.current.currentTime = 2.3;

        videoRicoRef.current.play().catch((error) => console.log("Autoplay error:", error));

        const idPlay = setInterval(() => {
            if (videoRicoRef.current) {
                if (videoRicoRef.current.currentTime >= 14.3) {
                    videoRicoRef.current.currentTime = 2.3;
                }
            }
        }, 1000);

        setIntervalIdRico(idPlay);
    };

    const handleLeaveRico = () => {
        clearInterval(intervalIdRico);

        if (videoRicoRef.current) {
            videoRicoRef.current.currentTime = 0;
            playVideoRicoFromStart();
        }
    };

    const handleHoverAffpro = () => {
        clearInterval(intervalIdAffpro);

        videoAfproRef.current.currentTime = 2.6;
        videoAfproRef?.current?.play().catch((error) => console.log("Autoplay error:", error));

        const idPlay = setInterval(() => {
            if (videoAfproRef.current) {
                if (videoAfproRef.current.currentTime >= 9) {
                    videoAfproRef.current.currentTime = 1;
                }
            }
        }, 1000);

        setIntervalIdAffpro(idPlay);
    };

    const handleLeaveAffpro = () => {
        clearInterval(intervalIdAffpro);

        if (videoAfproRef.current) {
            videoAfproRef.current.currentTime = 0;
            playVideoAffproFromStart();
        }
    };

    const products = useMemo(
        () => [
            {
                id: 1,
                activeProduct: "active",
                url: routePaths.rico,
                afterHoverImgs: videoRico,
                videoRef: videoRicoRef,
                videoName: mainPageAnimationTitles.ricoVideo,
                videoHover: handleHoverRico,
                videoHoverLeave: handleLeaveRico,
                location: "Spain, Barcelona",
                duration: "2 weeks",
                // stack: [stack, react, php, rpc],
                text: `Transform your rental experience with the help of our app. It's convenient to use and allows you to choose from thousands of properties in Spain to rent. Whether you're looking for an affordable apartment or a luxe villa within the country, Rico is here for you. Enjoy up-to-the-minute real estate selection by location or applying custom search criteria.`,

                services: [
                    { name: "Engineering", active: "" },
                    { name: "Web development", active: "" },
                    { name: "Web design", active: "" },
                    { name: "Research", active: "" },
                    { name: "Prototyping", active: "" },
                    { name: "Construction", active: "" },
                    { name: "Real estate", active: "" },
                    { name: "UI", active: "active" },
                    { name: "UX", active: "active" },
                ],
            },
            {
                id: 2,
                notReady: "notReady",
                url: routePaths.modernaCasa,
                afterHoverImgs: [""],
                location: "Spain, Barcelona",
                duration: "2 months",
                stack: [react, reduxIcon, tsIcon, styledIcon, githubIcon, pythonIcon, restIcon],
                text: `Recharge your concept of construction cycle with the advanced service that is aimed to provide next-gen home design, engineering and building solutions. MODERNACASA makes it possible to implement even the most non-trivial ideas into reality and reinvigorate classical approach to construction services.`,

                services: [
                    { name: "Engineering", active: "active" },
                    { name: "Web development", active: "" },
                    { name: "Web design", active: "" },
                    { name: "Research", active: "" },
                    { name: "Prototyping", active: "" },
                    { name: "Construction", active: "" },
                    { name: "Real estate", active: "" },
                    { name: "UI", active: "active" },
                    { name: "UX", active: "active" },
                ],
            },

            {
                id: 3,
                activeProduct: "active",
                url: routePaths.affPro,
                afterHoverImgs: videoAfpro,
                videoRef: videoAfproRef,
                videoHover: handleHoverAffpro,
                videoHoverLeave: handleLeaveAffpro,
                videoName: mainPageAnimationTitles.affProVideo,
                location: "Worldwide",
                duration: "3 months",
                stack: [react, tsIcon, materialIcon, nodejsIcon, exIcon, firebaseIcon, githubIcon],
                text: `Take advantage of the advanced multi-vertical affiliate network and boost your profits by implementing AFFPRO's phenomenal marketing expertise into your promotion strategy! The service is aimed to provide an all-inclusive service based on techniques and mechanisms showing proven results within a specific niche. Employ the effective traffic-generating model and watch your business grow at unprecedented rates!`,

                services: [
                    { name: "Engineering", active: "active" },
                    { name: "Web development", active: "" },
                    { name: "Web design", active: "" },
                    { name: "Research", active: "" },
                    { name: "Prototyping", active: "" },
                    { name: "Construction", active: "" },
                    { name: "Real estate", active: "" },
                    { name: "UI", active: "active" },
                    { name: "UX", active: "active" },
                ],
            },

            {
                id: 4,
                activeProduct: "notReady",
                url: routePaths.alienClickPage,
                location: "Worldwide",
                duration: "Now in Developing",
                afterHoverImgs: videoAlienGreen,
                videoRef: videoAlienRef,

                stack: [react, tsIcon, materialIcon, nodejsIcon, exIcon, firebaseIcon, githubIcon],
                text: `Alien Click is an innovative Ad Network that provides an effective partnership between advertisers and media buyers. Alien Click offers a unique technology that allows advertisers to get more leads and higher conversions.`,

                services: [
                    { name: "Engineering", active: "active" },
                    { name: "Web development", active: "" },
                    { name: "Web design", active: "" },
                    { name: "Research", active: "" },
                    { name: "Prototyping", active: "" },
                    { name: "Construction", active: "" },
                    { name: "Real estate", active: "" },
                    { name: "UI", active: "active" },
                    { name: "UX", active: "active" },
                ],
            },
        ],
        [videoAfproRef, videoAlienRef, videoRicoRef],
    );

    const setIsLoading = (key, value) => {
        dispatch(
            setMainPageIsLoading({
                key: key,
                value: value,
            }),
        );
    };

    const handleHoverIn = () => {
        setIsHoveredConnect(true);
    };

    const handleHoverOut = () => {
        setIsHoveredConnect(false);
    };

    const handleProgress = ({ playedSeconds }) => {
        if (isTabletMedia) return;
        if (isHoveredConnect) {
            playerRef.current.seekTo(4.7);
        }

        if (playedSeconds >= 3 && !isHoveredConnect) {
            playerRef.current.seekTo(2.5);
        }
    };

    return (
        <section id='productsSection' className='pageSection'>
            <div className='wrapper'>
                <div className='productsInfo'>
                    <h1 className='productsTitle basicTitle'>PRODUCTS & CASES</h1>

                    <p className='productsDesc'>
                        We are{" "}
                        <span className='productsDesc__main'>more than just a service provider</span>. We
                        are also a product IT company that develops innovative digital products to help
                        businesses thrive. We are always open to new partnerships and collaborations.
                    </p>
                </div>

                <div className='productsList'>
                    {products.map((product, index) => (
                        <div
                            onClick={() => {
                                handleProductClick(product.url);
                            }}
                            className={`product ${product.activeProduct}`}
                            key={index}
                            onMouseEnter={!isTabletMedia ? product.videoHover : null}
                            onMouseLeave={!isTabletMedia ? product.videoHoverLeave : null}
                        >
                            <div
                                className={`productImg ${
                                    product.activeProduct ? "productImg--active" : ""
                                } `}
                            >
                                {product.activeProduct && (
                                    <>
                                        <div className='productImg__main'>
                                            {product.url === routePaths.alienClickPage ? (
                                                <Lottie
                                                    animationData={alienJson}
                                                    autoplay={true}
                                                    loop={true}
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            ) : (
                                                <video
                                                    ref={product.videoRef}
                                                    muted
                                                    playsInline
                                                    onCanPlayThrough={() =>
                                                        product.videoName &&
                                                        setIsLoading(product.videoName, true)
                                                    }
                                                >
                                                    <source
                                                        src={product.afterHoverImgs}
                                                        type='video/mp4'
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                        </div>
                                    </>
                                )}

                                {product.notReady && (
                                    <>
                                        <div className='photoContainer'>
                                            <DrawCanvas />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='productDetails'>
                                <div className='productInfo'>
                                    <div className='productInfo__item'>
                                        <p className='productInfo__title'>Location</p>
                                        <p className='productInfo__name'>{product.location}</p>
                                    </div>

                                    <div className='productInfo__item'>
                                        <p className='productInfo__title'>Project duration</p>
                                        <p className='productInfo__name'>{product.duration}</p>
                                    </div>

                                    {product.stack && (
                                        <div className='productInfo__item productInfo__item--stack'>
                                            <p className='productInfo__title'>Tech stack</p>
                                            <div className='productInfo__stack'>
                                                {product.stack.map((item, i) => (
                                                    <ReactSVG
                                                        key={i}
                                                        src={item}
                                                        className='productInfo__stack-svg'
                                                        loading={() => <Loader />}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='productText'>
                                    <p>{product.text}</p>
                                </div>

                                <div className='productServices'>
                                    {product.services.map((service, index) => (
                                        <p
                                            className={`productServices__item ${service.active}`}
                                            key={index}
                                        >
                                            #{service.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='productsCoop'>
                    We are <span className='productsCoop__main'>looking for</span> interesting projects
                    for <span className='productsCoop__main'>cooperation</span>
                </div>

                <a href={routePaths.clientForm} className='productsConnect'>
                    <div
                        className='productsConnect__item'
                        onMouseEnter={handleHoverIn}
                        onMouseLeave={handleHoverOut}
                    >
                        <ReactPlayer
                            ref={playerRef}
                            url={videoUrl}
                            preload='false'
                            playing
                            loop
                            width='100%'
                            height='auto'
                            onProgress={handleProgress}
                            muted
                            playsinline
                            controls={false}
                        />
                    </div>
                </a>
            </div>
        </section>
    );
};

export default memo(Products);
