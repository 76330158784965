import React from "react";

import "./LaunchContent.scss";

import Lottie from "lottie-light-react";
const LaunchContent = ({ animation, content }) => {
    return (
        <div className='phase__content'>
            <div className='phase__content-paragraphs'>
                {content.map((devProcessParagraph) => (
                    <div className='phase__content-paragraph' key={devProcessParagraph.id}>
                        <span className='phase__content-paragraph-title'>
                            {devProcessParagraph.title}
                        </span>
                        <p className='phase__content-paragraph-description'>
                            {devProcessParagraph.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className='phase__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default LaunchContent;
