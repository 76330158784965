import React, { memo, useEffect, useRef } from "react";
import { routePaths } from "../../shared/routes";
import "./LetsWork.scss";
import lottie from "lottie-web";

const LetsWork = ({ letsWorkTitle, topText, currentTab }) => {
    const containerLetsWork = useRef(null);
    let animationLetsWork = null;
    const engineeringTab = currentTab === "engineering" ? "engineering-hover" : "";
    const designTab = currentTab === "design" ? "design-hover" : "";
    const outstaffTab = currentTab === "outstaff" ? "outstaff-hover" : "";

    useEffect(() => {
        animationLetsWork = lottie.loadAnimation({
            container: containerLetsWork.current,
            renderer: "svg",
            loop: true,
            autoplay: false,
            animationData: require("../../assets/animations/letsWork/continue.json"),
        });

        return () => {
            animationLetsWork?.destroy();
        };
    }, [currentTab]);

    const handleHover = () => {
        animationLetsWork?.play();
    };

    const handleLeave = () => {
        animationLetsWork?.stop();
    };

    return (
        <a
            className={`letsWork ${engineeringTab} ${outstaffTab} ${designTab} `}
            href={routePaths.clientForm}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
        >
            <div className='letsWork__text'>
                {topText && <p className='letsWork__top'>{topText}</p>}
                <h3 className='letsWork__title'>{letsWorkTitle}</h3>
            </div>
            <button className='letsWork__btn mainBtn'>
                <span>Continue</span>
                <div ref={containerLetsWork} className='letsWork__icon'></div>
            </button>
            <span className='letsWork__bg'></span>
        </a>
    );
};

export default memo(LetsWork);
