import React, { useState } from "react";
import "./OutstaffTab.scss";
import OutstaffBlock from "./DevopsBlock/OutstaffBlock";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentService } from "../../../redux/actions/servicesActions";
import { handleTabClick } from "../../../shared/handleTabClick";
import HoverIconsAnimation from "../../../components/HoverIconsAnimation/HoverIconsAnimation";
import outstaffAnimation from "../../../assets/animations/services/outstaf/outstaf.json";
const outstaff = [
    {
        number: 1,
        id: "outstaff",
        icon: outstaffAnimation,
        title: "Outstaff",
        titleBottom: "",
        className: "outstaff",
        children: <OutstaffBlock />,
    },
];

const OutstaffTab = () => {
    const [currentItem, setCurrentItem] = useState(0);
    const dispatch = useDispatch();
    const currentService = useSelector((state) => state.servicesReducer.currentService);

    return (
        <div className='service_tab'>
            <div className='service_tab-titles'>
                {outstaff.map((item, index) => (
                    <div
                        className={`servicesItem ${"servicesItem--" + item.className} ${
                            currentService === item.id ? "active" : ""
                        }`}
                        key={item.id}
                        onClick={() =>
                            handleTabClick(item.id, dispatch, setCurrentService, currentService)
                        }
                        onMouseEnter={() =>
                            item.number === index + 1 ? setCurrentItem(item.number) : 0
                        }
                        onMouseLeave={() => {
                            setCurrentItem(0);
                        }}
                    >
                        <div className='servicesItem__icon'>
                            <HoverIconsAnimation
                                animationData={item.icon}
                                currentItem={currentItem}
                                currentNumb={item.number}
                            />
                        </div>
                        <div className='servicesItem__text'>
                            <p>
                                {item.title}{" "}
                                <span className='servicesItem__textLine'>{item.titleBottom}</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div>{outstaff.find((outstaffItem) => outstaffItem.id === currentService)?.children}</div>
        </div>
    );
};

export default OutstaffTab;
