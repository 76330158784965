export const seniorityLevelOptions = [
    { value: "Junior", label: "Junior" },
    { value: "Junior Strong", label: "Junior Strong" },
    { value: "Middle", label: "Middle" },
    { value: "Middle Strong", label: "Middle Strong" },
    { value: "Senior", label: "Senior" },
    { value: "Senior Strong/Lead", label: "Senior Strong/Lead" },
];

export const yearsOptions = [{ value: 11, label: 11 }];

export const chooseServiceOptions = [
    { id: 1, value: "Full Cycle Development" },
    { id: 2, value: "UI/UX" },
    { id: 3, value: "App Development" },
    { id: 4, value: "Outstaffing" },
    { id: 5, value: "Elevate Phase" },
    { id: 6, value: "Web Development" },
    { id: 7, value: "Graphic/Motion Design" },
    { id: 8, value: "AI/ML" },
    { id: 9, value: "DevOps" },
];

export const budgetOptions = [
    { id: 1, value: "<10k" },
    { id: 2, value: "10k-50k" },
    { id: 3, value: "50k-100k" },
    { id: 4, value: "100k-500k" },
    { id: 5, value: "500k-1MIL" },
];

export const kindOfProjectOptions = [
    { value: "Full-time", label: "Full-time", sublabel: "(40h / week)" },
    { value: "Part-time", label: "Part-time", sublabel: "(20-35h / week)" },
    { value: "Less than part-time", label: "Less", sublabel: "(20h / week)" },
];

export const specializationOptions = [
    { value: "Front-end Web Developer", label: "Front-end Web Developer" },
    { value: "Back-end Web Developer", label: "Back-end Web Developer" },
    { value: "Full-stack Web Developer", label: "Full-stack Web Developer" },
    { value: "Mobile Developer", label: "Mobile Developer" },
    { value: "Manual QA Engineer", label: "Manual QA Engineer" },
    { value: "Automation QA Engineer", label: "Automation QA Engineer" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "AI Engineer", label: "AI Engineer" },
    { value: "DevOps", label: "DevOps" },
    { value: "Site Reliability Engineer", label: "Site Reliability Engineer" },
    { value: "Game Developer", label: "Game Developer" },
    { value: "Team lead", label: "Team lead" },
    { value: "Tech lead", label: "Tech lead" },
    { value: "Architect", label: "Architect" },
];

export const englishLevelOptions = [
    { id: 1, value: "A1-A2" },
    { id: 2, value: "B1-B2" },
    { id: 3, value: "C1-C2" },
];

export const soloTeamlOptions = [
    { id: 1, value: "Solo" },
    { id: 2, value: "Team" },
];

export const designSpecialityOptions = [
    { value: "UI/UX Designer", label: "UI/UX Designer" },
    { value: "Graphic designer", label: "Graphic designer" },
    { value: "Illustrator", label: "Illustrator" },
    { value: "Concept Artist", label: "Concept Artist" },
    { value: "Motion Designer", label: "Motion Designer" },
    { value: "Sound Designer", label: "Sound Designer" },
];

export const skillsOptions = [
    { value: "2DSketch", label: "2DSketch" },
    { value: "Adobe Photoshop", label: "Adobe Photoshop" },
    { value: "Adobe Illustrator", label: "Adobe Illustrator" },
    { value: "Adobe After Effects", label: "Adobe After Effects" },
    { value: "2D Design", label: "2D Design" },
    { value: "3D Design", label: "3D Design" },
    { value: "Figma", label: "Figma" },
    { value: "InVision", label: "InVision" },
    { value: "Adobe XD", label: "Adobe XD" },
    { value: "InDesign", label: "InDesign" },
    { value: "Zeplin", label: "Zeplin" },
    { value: "Marvel", label: "Marvel" },
    { value: "Procreate", label: "Procreate" },
    { value: "Principle", label: "Principle" },
    { value: "Axure RP", label: "Axure RP" },
];

export const pmSpecialityOptions = [
    { value: "Project Manager", label: "Project Manager" },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "Delivery Manager", label: "Delivery Manager" },
    { value: "Account Manager", label: "Account Manager" },
];
export const pmIndustriesOptions = [
    { value: "FinTech", label: "FinTech" },
    { value: "EdTech", label: "EdTech" },
    { value: "AdTech", label: "AdTech" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Blockhain Development", label: "Blockhain Development" },
    { value: "Retail", label: "Retail" },
    { value: "Transportation and Logistics", label: "Transportation and Logistics" },
    { value: "Media and Entertainment", label: "Media and Entertainment" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Other", label: "Other" },
];
export const salesSpecialityOptions = [
    { value: "Sales Representative", label: "Sales Representative" },
    { value: "Business Development Manager", label: "Business Development Manager" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Leadgen Manager", label: "Leadgen Manager" },
];
export const salesIndustriesOptions = [
    { value: "FinTech", label: "FinTech" },
    { value: "EdTech", label: "EdTech" },
    { value: "AdTech", label: "AdTech" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Blockhain Development", label: "Blockhain Development" },
    { value: "Retail", label: "Retail" },
    { value: "Transportation and Logistics", label: "Transportation and Logistics" },
    { value: "Media and Entertainment", label: "Media and Entertainment" },
    { value: "Real Estate", label: "Real Estate" },
];
