import React, { useState, memo } from "react";
import { ReactSVG } from "react-svg";
import { useTabletMedia, useMobileMedia } from "../../utils/useMediaQueries";
import Box from "@mui/material/Box";
import LetsWork from "../../components/LetsWork/LetsWork";

import ContactMapDesktop from "./ContactMapDesktop";
import ContactMapTablet from "./ContactMapTablet";
import ContactMapMobile from "./ContactMapMobile";

import globeIcon from "./img/globe.svg";
import spainIcon from "./img/sagrada-familia.svg";
import uaIcon from "./img/ua.svg";
import usaIcon from "./img/usa.svg";
import whatsappIcon from "./img/whatsapp.svg";
import telegramIcon from "./img/telegram.svg";
import emailIcon from "./img/email.svg";

import "./Contacts.scss";
import { useNavigate } from "react-router-dom";
import { routePaths } from "./../../shared/routes";
import Loader from "../../components/Loader/Loader";

const topText = "START YOUR ELEVATE PHASE";
const letsWorkTitle = "Looking for someone to help with your problems?";
const emails = [
    "sales@focusdigitalsyndicate.com",
    "partner@focusdigitalsyndicate.com",
    "career@focusdigitalsyndicate.com",
];

const Contacts = () => {
    const isTabletMedia = useTabletMedia();
    const isMobileMedia = useMobileMedia();
    const navigate = useNavigate();
    const [countryActive, setCountryActive] = useState("");
    const [countryUsa, setCountryUsa] = useState("");
    const [countryUa, setCountryUa] = useState("");
    const [countrySpain, setCountrySpain] = useState("");
    const [notUsa, setNotUsa] = useState("");
    const [notUa, setNotUa] = useState("");
    const [notSpain, setNotSpain] = useState("");

    const mouseHoverUsa = () => {
        setCountryUsa("is-visible");
        setNotUsa("is-hidden");
        setCountryActive("is-active");
    };

    const mouseHoverUa = () => {
        setCountryUa("is-visible");
        setNotUa("is-hidden");
        setCountryActive("is-active");
    };

    const mouseHoverSpain = () => {
        setCountrySpain("is-visible");
        setNotSpain("is-hidden");
        setCountryActive("is-active");
    };

    const mouseHoverUsaLeave = () => {
        setCountryUsa("");
        setNotUsa("");
        setCountryActive("");
    };

    const mouseHoverUaLeave = () => {
        setCountryUa("");
        setNotUa("");
        setCountryActive("");
    };

    const mouseHoverSpainLeave = () => {
        setCountrySpain("");
        setNotSpain("");
        setCountryActive("");
    };

    const contactsBlock = [
        {
            name: "World Wide Support - Online",
            nameIcon: globeIcon,
            phoneWhatsappIcon: whatsappIcon,
            whatsappLink: "https://wa.me/+34674740712",
            phoneTelegramIcon: telegramIcon,
            telegramLink: "https://t.me/focusdigitalsyndicate",
            mailIcon: emailIcon,
            mail: "sales@focusdigitalsyndicate.com",
        },
        {
            name: "Barcelona, ES - Online & Offline",
            nameIcon: spainIcon,
            countryPosClass: "spain",
            countryClass: countrySpain,
            nameMapIcon: spainIcon,
            phoneWhatsappIcon: whatsappIcon,
            whatsappLink: "https://wa.me/+34674740712",
            phoneTelegramIcon: telegramIcon,
            telegramLink: "https://t.me/focusdigitalsyndicate",
            mailIcon: emailIcon,
            mail: "sales@focusdigitalsyndicate.com",
            handleHoverCountry: mouseHoverSpain,
            handleLeaveCountry: mouseHoverSpainLeave,
        },
        {
            name: "Kyiv, UA - Online & Offline",
            nameIcon: uaIcon,
            countryPosClass: "ua",
            countryClass: countryUa,
            nameMapIcon: uaIcon,
            phoneWhatsappIcon: whatsappIcon,
            whatsappLink: "https://wa.me/+34674740712",
            phoneTelegramIcon: telegramIcon,
            telegramLink: "https://t.me/focusdigitalsyndicate",
            mailIcon: emailIcon,
            mail: "sales@focusdigitalsyndicate.com",
            handleHoverCountry: mouseHoverUa,
            handleLeaveCountry: mouseHoverUaLeave,
        },
        {
            name: "California, USA - Online & Offline",
            nameIcon: usaIcon,
            nameMapIcon: usaIcon,
            countryPosClass: "usa",
            countryClass: countryUsa,
            phoneWhatsappIcon: whatsappIcon,
            whatsappLink: "https://wa.me/+34674740712",
            phoneTelegramIcon: telegramIcon,
            telegramLink: "https://t.me/focusdigitalsyndicate",
            mailIcon: emailIcon,
            mail: "sales@focusdigitalsyndicate.com",
            handleHoverCountry: mouseHoverUsa,
            handleLeaveCountry: mouseHoverUsaLeave,
        },
    ];

    return (
        <section id='contactsSection' className='pageSection'>
            <div className='wrapper'>
                <h1 className='communityTitle basicTitle'>CONTACTS</h1>
            </div>
            <div className={`contactsMap `}>
                <div className={`contactsMap__svg ${countryActive}`}>
                    {isMobileMedia && (
                        <ContactMapMobile
                            mouseHoverUsa={mouseHoverUsa}
                            mouseHoverUsaLeave={mouseHoverUsaLeave}
                            mouseHoverSpain={mouseHoverSpain}
                            mouseHoverSpainLeave={mouseHoverSpainLeave}
                            mouseHoverUa={mouseHoverUa}
                            mouseHoverUaLeave={mouseHoverUaLeave}
                            notUsa={notUsa}
                            notUa={notUa}
                            notSpain={notSpain}
                        />
                    )}
                    {isTabletMedia && !isMobileMedia && (
                        <ContactMapTablet
                            mouseHoverUsa={mouseHoverUsa}
                            mouseHoverUsaLeave={mouseHoverUsaLeave}
                            mouseHoverSpain={mouseHoverSpain}
                            mouseHoverSpainLeave={mouseHoverSpainLeave}
                            mouseHoverUa={mouseHoverUa}
                            mouseHoverUaLeave={mouseHoverUaLeave}
                            notUsa={notUsa}
                            notUa={notUa}
                            notSpain={notSpain}
                        />
                    )}
                    {!isTabletMedia && !isMobileMedia && (
                        <ContactMapDesktop
                            mouseHoverUsa={mouseHoverUsa}
                            mouseHoverUsaLeave={mouseHoverUsaLeave}
                            mouseHoverSpain={mouseHoverSpain}
                            mouseHoverSpainLeave={mouseHoverSpainLeave}
                            mouseHoverUa={mouseHoverUa}
                            mouseHoverUaLeave={mouseHoverUaLeave}
                            notUsa={notUsa}
                            notUa={notUa}
                            notSpain={notSpain}
                        />
                    )}
                </div>

                <div className='mapContacts'>
                    {contactsBlock.slice(1).map((block, index) => (
                        <Box
                            component='div'
                            className={`contactsInfo ${block.countryPosClass} ${block.countryClass}`}
                            key={index}
                            onMouseEnter={block.handleHoverCountry}
                            onMouseLeave={block.handleLeaveCountry}
                            sx={{ "&:hover": { cursor: "pointer" } }}
                            onClick={() => {
                                navigate(routePaths.clientForm);
                                window.scrollTo(0, 0);
                            }}
                        >
                            <div className='contactsInfo__title'>
                                <ReactSVG
                                    src={block.nameMapIcon}
                                    className='contactsInfo__title-svg'
                                    wrapper='span'
                                    loading={() => <Loader />}
                                />

                                {block.name}
                            </div>
                        </Box>
                    ))}
                </div>
            </div>

            <div className='wrapper'>
                <div className='contactsContent'>
                    <div className='contacts'>
                        <div className='contacts__left'>
                            <h3 className='contacts__main'>FOCUS DIGITAL SYNDICATE</h3>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {emails.map((email, index) => (
                                    <Box sx={{ padding: "0.25rem" }} key={index}>
                                        <a
                                            className='contacts__mail'
                                            href={`mailto:${email}`}
                                            target='_blank'
                                            rel='noreferrer noopener'
                                        >
                                            {email}
                                        </a>
                                    </Box>
                                ))}
                            </Box>
                        </div>

                        <div className='contacts__right'>
                            {contactsBlock.map((block, index) => (
                                <div className='contactsInfo' key={index}>
                                    <div className='contactsInfo__title'>
                                        {block.nameIcon && (
                                            <img
                                                alt={block.name}
                                                src={block.nameIcon}
                                                className='contactsInfo__title-svg'
                                            />
                                        )}

                                        {block.name}
                                    </div>

                                    <div className='contactsInfo__link contactsInfo__link--phone'>
                                        {block.phoneWhatsappIcon && block.whatsappLink && (
                                            <Box
                                                component='a'
                                                href={block.whatsappLink}
                                                sx={{ marginRight: "0.25rem" }}
                                            >
                                                <img
                                                    alt={block.name}
                                                    src={block.phoneWhatsappIcon}
                                                    className='contactsInfo__link-svg'
                                                />
                                            </Box>
                                        )}
                                        {block.phoneTelegramIcon && block.telegramLink && (
                                            <Box
                                                component='a'
                                                href={block.telegramLink}
                                                target='_blank'
                                                sx={{ marginRight: "0.25rem" }}
                                            >
                                                <img
                                                    alt={block.name}
                                                    src={block.phoneTelegramIcon}
                                                    className='contactsInfo__link-svg'
                                                />
                                            </Box>
                                        )}
                                        {block.mailIcon && block.mail && (
                                            <Box
                                                component='a'
                                                href={`mailto:${block.mail}`}
                                                sx={{ marginRight: "0.25rem" }}
                                            >
                                                <img
                                                    alt={block.name}
                                                    src={block.mailIcon}
                                                    className='contactsInfo__link-svg'
                                                />
                                            </Box>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <LetsWork letsWorkTitle={letsWorkTitle} topText={topText} />
                </div>
            </div>
        </section>
    );
};

export default memo(Contacts);
