import React, { useState } from "react";
import EndFormModal from "./components/EndFormModal";
import { Formik, Form } from "formik";
import DevAdditionalBlock from "./components/EmployeeFormBlocks/DevAdditionalBlock";
import PersonalInfo from "./components/EmployeeFormBlocks/PersonalInfo";
import { useTranslation } from "react-i18next";
import { getEndModalProps } from "./components/EndFormModalProps";
import PrivacyCheckboxField from "./FormFields/PrivacyCheckboxField";
import { FormNavigation } from "./components/FormNavigation";
import MainProfileInfo from "./components/EmployeeFormBlocks/MainProfileInfo";
import "./Forms.scss";
import { formIdObject } from "./constants";
import DesignAdditionalBlock from "./components/EmployeeFormBlocks/DesignAdditionalBlock";
import PMAdditionalBlock from "./components/EmployeeFormBlocks/PMAdditionalBlock";
import SalesAdditionalBlock from "./components/EmployeeFormBlocks/SalesAdditionalBlock";

const EmployeeForm = ({ formModel, submitFunction, formInitialValues, vacancyId }) => {
    const [privacyCheckboxIsActive, setPrivacyCheckboxIsActive] = useState(false);
    const { t } = useTranslation();
    const { formId, formField } = formModel;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);

    const submitForm = (values, actions) => {
        submitFunction(values)
            .then(() => {
                setIsSuccess(true);
                setIsModalOpen(true);
                actions.setSubmitting(false);
            })
            .catch(() => {
                setIsSuccess(false);
                setIsModalOpen(true);
                actions.setSubmitting(false);
            });
    };

    const handleSubmit = (values, actions) => {
        const skillsString =
            values.skills &&
            values.skills[0].technology &&
            values.skills[0].experience &&
            values.skills.length &&
            values.skills.map((skill) => `${skill.technology} (${skill.experience} years)`);

        const currentValues = {
            ...values,
            languages_level: `English ${values.languages_level}`,
            skills: skillsString,
            source_id: 17078,
            urls: [values.linkedin, values.portfolio],
            phone_numbers: [values.phone_numbers],
            vacancy_id: vacancyId,
        };

        submitForm(currentValues, actions);
    };

    const popupProps = getEndModalProps(t);

    return (
        <div className='pageSection formPage'>
            <div className='wrapper'>
                <h2 className='formPage__title'>{t("page.dev-form.title")}</h2>
                <Formik
                    initialValues={formInitialValues}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, values }) => (
                        <Form id={formId} className='actionForm'>
                            <MainProfileInfo formField={formField} values={values} />
                            {formId === formIdObject.devForm && (
                                <DevAdditionalBlock formField={formField} values={values} />
                            )}
                            {formId === formIdObject.designForm && (
                                <DesignAdditionalBlock formField={formField} values={values} />
                            )}
                            {formId === formIdObject.salesForm && (
                                <SalesAdditionalBlock formField={formField} values={values} />
                            )}
                            {formId === formIdObject.pmForm && (
                                <PMAdditionalBlock formField={formField} values={values} />
                            )}
                            <PersonalInfo formField={formField} values={values} formId={formId} />
                            <PrivacyCheckboxField
                                privacyCheckboxIsActive={privacyCheckboxIsActive}
                                setPrivacyCheckboxIsActive={setPrivacyCheckboxIsActive}
                            />
                            <FormNavigation
                                errors={errors}
                                isSubmitting={isSubmitting}
                                privacyCheckboxIsActive={privacyCheckboxIsActive}
                            />
                        </Form>
                    )}
                </Formik>
            </div>

            {isModalOpen && (
                <EndFormModal
                    isSuccess={isSuccess}
                    isOpen={isModalOpen}
                    succeedImage={popupProps.succeedImage}
                    succeedImageAlt={popupProps.succeedImageAlt}
                    succeedLabel={popupProps.succeedLabel}
                    succeedButtonLabel={popupProps.succeedButtonLabel}
                    onClickSucceedButton={popupProps.onClickSucceedButton}
                    failImage={popupProps.failImage}
                    failImageAlt={popupProps.failImageAlt}
                    failLabel={popupProps.failLabel}
                    failButtonLabel={popupProps.failButtonLabel}
                    onClickFailButton={popupProps.onClickFailButton}
                />
            )}
        </div>
    );
};
export default EmployeeForm;
