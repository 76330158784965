import React, { useState } from "react";
import "./OutstaffBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import ProcessContent from "./ProcessContent/ProcessContent";
import outstaff from "../../../../assets/animations/services/outstaf/outstaf.json";

import Lottie from "lottie-light-react";
import { useMediaQuery } from "react-responsive";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import { processParagraphs } from "./OutstaffBlock.constants";
import animation from "../../../../assets/animations/servicesTabs/outstaff-scheme.json";
const OutstaffBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("Outstaff Process");
    const [isShema, setIsShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='outstaff__wrapper'>
            <div className='outstaff__title'>
                <h4 className='outstaff__title-text'>Outstaff Services</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={outstaff} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='outstaff__description'>
                With our IT company, you can count on us to provide outstaffing services in the field of
                software development and design. Our team of experts will provide you with access to a
                highly skilled workforce and relevant experience to help you ensure the success of your
                project. We offer flexible terms of cooperation and the ability to customize the team to
                your needs. Contact us to learn more about our services and how we can help you achieve
                your business goals.
            </div>
            <ServiceNavigation
                firstNavItem='Outstaff Process'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={wayIcon}
                oneBlock
            />
            <div className='outstaf__content'>
                {!isMobile ? (
                    <ProcessContent animation={animation} content={processParagraphs} />
                ) : (
                    <ServicesMobileTab
                        isShema={isShema}
                        setIsShema={setIsShema}
                        animation={animation}
                        content={processParagraphs}
                        isList
                    />
                )}
            </div>
        </div>
    );
};

export default OutstaffBlock;
