import React, { useState } from "react";
import "./DevopsBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import microchipIcon from "../../../../assets/svg/services/microchip.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import ProcessContent from "./ProcessContent/ProcessContent";
import TechnologyContent from "./TechnologyContent/TechnologyContent";
import developmentAnimation from "../../../../assets/animations/services/engineering/DevOps.json";
import Lottie from "lottie-light-react";
import { useMediaQuery } from "react-responsive";
import { processParagraphs } from "./DevopsBlock.constants";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import animation from "../../../../assets/animations/servicesTabs/DevOps-tab-animation.json";
const DevopsBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("Process");
    const [isShema, setIsShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='devops__wrapper'>
            <div className='devops__title'>
                <h4 className='devops__title-text'>DevOps</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={developmentAnimation} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='devops__description'>
                Since we are striving to meet clients requirements in the most effective way possible, we
                have a preference for DevOps practice over other popular methodologies such as the
                Waterfall model or AGILE. DevOps increases the speed, efficacy, and output of the
                software lifecycle by optimizing the workflow, making every phase of digital product
                creation continuous. This method is 100% working for those who strive to produce
                high-quality end-products that exceed your expectations. DevOps contributes to cross-team
                autonomy, causing the automation of different development stages. Our experienced DevOps
                team is waiting for new challenges from you regardless of the complexity of your demands!
            </div>
            <ServiceNavigation
                firstNavItem='Process'
                secondNavItem='Technology'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={currentNavItem === "Process" ? wayIcon : microchipIcon}
            />
            <div className='devops__content'>
                {currentNavItem === "Process" ? (
                    <>
                        {!isMobile ? (
                            <ProcessContent animation={animation} content={processParagraphs} />
                        ) : (
                            <ServicesMobileTab
                                isShema={isShema}
                                setIsShema={setIsShema}
                                animation={animation}
                                isList
                                content={processParagraphs}
                            />
                        )}
                    </>
                ) : (
                    <TechnologyContent />
                )}
            </div>
        </div>
    );
};

export default DevopsBlock;
