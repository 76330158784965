import React from "react";
import "./FormRadioGroup.scss";
import { Field } from "formik";

const FormRadioGroup = ({ title, field, options, currentValue, className }) => {
    return (
        <div className='actionForm__radioGroup'>
            <div id={field.name}>{title}</div>
            <div
                role='group'
                aria-labelledby={field.name}
                className={`radioGroup__groupBlock ${className ? className : ""}`}
            >
                {options.map((option) => (
                    <label
                        key={option.id}
                        className={`radioGroup__groupItem ${
                            currentValue === option.value ? "activeRadioItem" : ""
                        }`}
                    >
                        <Field type='radio' name={field.name} value={option.value} />
                        {option.value}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default FormRadioGroup;
