export const servicesGroupsConstants = {
    engineering: "engineering",
    design: "design",
    outstaff: "outstaff",
};

export const servicesConstants = {
    webDev: "webDev",
    aiml: "aiml",
    devops: "devops",
    phase: "phase",
    ux: "ux",
    motionDesign: "motionDesign",
    outstaff: "outstaff",
};
