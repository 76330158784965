import React from "react";
import "./ProcessContent.scss";
import Lottie from "lottie-light-react";
const ProcessContent = ({ animation, content }) => {
    return (
        <div className='devops__content'>
            <div className='devops__content-paragraphs'>
                {content.map((processParagraph) => (
                    <div className='devops__content-paragraph' key={processParagraph.id}>
                        <span className='devops__content-paragraph-title'>{processParagraph.title}</span>
                        <ul className='devops__content-paragraph-description'>
                            {processParagraph.descriptions.map((description) => (
                                <li className='devops__content-item' key={description}>
                                    {description}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className='devops__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default ProcessContent;
