import React from "react";
import { chooseServiceOptions, budgetOptions } from "../../SelectOptions/dataSelection";
import { Box } from "@mui/material";
import FormRadioGroup from "../../../../shared/components/FormRadioGroup/FormRadioGroup";
import { useTranslation } from "react-i18next";

const ClientNeedsFormStep = (props) => {
    const { t } = useTranslation();
    const {
        formField: { client_choose_service, client_budget },
        values,
    } = props;

    const currentService = values.client_choose_service;
    const currentBudget = values.client_budget;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                marginBottom: "30px",
            }}
        >
            <FormRadioGroup
                title={t("page.client-form.needs-form.service-title")}
                field={client_choose_service}
                options={chooseServiceOptions}
                currentValue={currentService}
                className={"radio-group-wrap"}
            />
            <FormRadioGroup
                title={t("page.client-form.needs-form.budget-title")}
                field={client_budget}
                options={budgetOptions}
                currentValue={currentBudget}
            />
        </Box>
    );
};

export default ClientNeedsFormStep;
