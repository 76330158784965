import React from "react";
import { ReactSVG } from "react-svg";
import "./ErrorPage.scss";
import errorIcon from "../../assets/svg/404.svg";

import { useNavigate } from "react-router-dom";
import { routePaths } from "../../shared/routes";
import ErrorAnimation from "../../assets/animations/error-page-animation.gif";

const ErrorPage = () => {
    const navigate = useNavigate();

    const handleBackHome = () => {
        navigate(routePaths.mainPage);
        window.scrollTo(0, 0);
    };

    return (
        <section className='errorPage'>
            <div className='errorPage__background' />
            <div className='errorPage__wrapper'>
                <div className='errorPage__logo'>
                    <ReactSVG src={errorIcon} wrapper='span' />
                </div>
                <div className='errorPage__info'>
                    <h1 className='info__title'>Oh no... We lost this page</h1>
                    <h5 className='info__description'>
                        We searched everywhere but couldn&apos;t find what you&apos;re looking for.
                        Let&apos;s find a better place for you to go.
                    </h5>
                    <button type='button' className='info__button' onClick={handleBackHome}>
                        Back to Home
                    </button>
                </div>
                <img className='errorPage__animation' src={ErrorAnimation} alt='Error page animation' />
            </div>
        </section>
    );
};

export default ErrorPage;
