import React from "react";
import "./DevelopmentProcessContent.scss";
import Lottie from "lottie-light-react";

const DevelopmentProcessContent = ({ animation, content }) => {
    return (
        <div className='development__content'>
            <div className='development__content-paragraphs'>
                {content.map((devProcessParagraph) => (
                    <div className='development__content-paragraph' key={devProcessParagraph.id}>
                        <span className='development__content-paragraph-title'>
                            {devProcessParagraph.title}
                        </span>
                        <p className='development__content-paragraph-description'>
                            {devProcessParagraph.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className='development__content-schema-wrapper'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default DevelopmentProcessContent;
