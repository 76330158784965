import React, { useState } from "react";
import "./AIMLBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import ProcessContent from "./ProcessContent/ProcessContent";
import developmentAnimation from "../../../../assets/animations/services/engineering/ai-services.json";
import Lottie from "lottie-light-react";
import { useMediaQuery } from "react-responsive";
import { aimlParagraphs } from "./AIMLBlock.constants";
import animation from "../../../../assets/animations/servicesTabs/ai-tab-animation.json";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
const AIMLBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("AI/ML Service");
    const [isShema, setIsShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='aiml__wrapper'>
            <div className='aiml__title'>
                <h4 className='aiml__title-text'>AI/ML services</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={developmentAnimation} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='aiml__description'>
                Our skilled machine learning engineers provide expertise, dedication, and a wide range of
                skills to support your projects journey. We offer tools and services to develop, train,
                test, automate data preparation, feature engineering, monitor, evaluate model
                performance, and deploy machine learning models to production. We aim to optimize your
                resources, speed up development, and exceed your expectations. We are flexible and eager
                to identify the best solution for your unique needs. Whether you are new or seasoned, we
                can help you achieve your goals.
            </div>
            <ServiceNavigation
                firstNavItem='AI/ML Service'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={wayIcon}
                oneBlock
            />
            <div className='aiml__content'>
                {!isMobile ? (
                    <ProcessContent animation={animation} content={aimlParagraphs} />
                ) : (
                    <ServicesMobileTab
                        isShema={isShema}
                        setIsShema={setIsShema}
                        animation={animation}
                        content={aimlParagraphs}
                    />
                )}
            </div>
        </div>
    );
};

export default AIMLBlock;
