import { formIdObject } from "../constants";

export const designFormModelObject = {
    formId: formIdObject.designForm,
    formField: {
        full_name: {
            name: "full_name",
            label: "Full name*",
            requiredErrorMsg: "Full name is required",
            specialCharactersErrorMsg: "This field should not contain special characters",
        },
        location: {
            name: "location",
            label: "Country of residence*",
            requiredErrorMsg: "Country of residence is required",
        },
        languages_level: {
            name: "languages_level",
            label: "Level of English",
            requiredErrorMsg: "Level of English is required",
        },
        availability: {
            name: "availability",
            label: "Kind of project",
            requiredErrorMsg: "Kind of project is required",
        },
        // solo_team: {
        //   name: 'solo_team',
        //   label: 'Do you have a team?',
        //   requiredErrorMsg: 'This field is required',
        // },
        // team_expirience: {
        //   name: 'team_expirience',
        //   label: 'Describe your teamwork experience',
        //   requiredErrorMsg: 'This field is required',
        // },
        //--additional block--
        position: {
            name: "position",
            label: "Your specialty*",
            requiredErrorMsg: "Your specialty is required",
        },
        employee_level: {
            name: "employee_level",
            label: "Seniority level*",
            requiredErrorMsg: "Seniority level is required",
        },
        experience: {
            name: "experience",
            label: "Years of experience*",
            requiredErrorMsg: "Years of experience is required",
        },
        skills: [{ id: Math.random(), technology: "", experience: "" }],
        //--additional block--
        hourly_salary: {
            name: "hourly_salary",
            label: "Desired hourly rate*",
            requiredErrorMsg: "Hourly rate is required",
        },
        email: {
            name: "email",
            label: "Email*",
            requiredErrorMsg: "Email is required",
            invalidErrorMsg: "Email is not valid",
        },
        phone_numbers: {
            name: "phone_numbers",
            label: "Phone number",
            requiredErrorMsg: "Phone number is required",
            invalidErrorMsg: "Phone number is not valid",
        },
        telegram: {
            name: "telegram",
            label: "Telegram username",
            specialCharactersErrorMsg:
                'This field should not contain special characters except "@", "." and "_"',
            requiredErrorMsg: "Telegram nickname is required",
        },
        linkedin: {
            name: "linkedin",
            label: "LinkedIn link",
            requiredErrorMsg: "LinkedIn link is required",
        },
        portfolio: {
            name: "portfolio",
            label: "Portfolio link",
            requiredErrorMsg: "Portfolio link is required",
        },
    },
};
