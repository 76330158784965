import React, { memo } from "react";

import "./AlienClick.scss";
import PageTemplate from "../../components/PageTemplate/PageTemplate";
import AlienClickBlock from "./AlienClickBlock/AlienClickBlock";

const AlienClick = () => {
    return (
        <PageTemplate id='alienSection' title='Products & cases'>
            <AlienClickBlock />
        </PageTemplate>
    );
};

export default memo(AlienClick);
