import React, { useState } from "react";
import "./MotionDesignBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import GraphicContent from "./GraphicContent/GraphicContent";
import MotionContent from "./MotionContent/MotionContent";
import design from "../../../../assets/animations/services/design/motion.json";
import Lottie from "lottie-light-react";
import { motionDesignParagraps } from "./MotionDesignBlock.constants";
import motionAnimation from "../../../../assets/animations/servicesTabs/graphic-motion-scheme.json";
import { useMediaQuery } from "react-responsive";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import { graphicDesignParagraps } from "./MotionDesignBlock.constants";

const MotionDesignBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("Graphic Design");
    const [isShema, setIsShema] = useState(true);
    const [isGraphicShema, setIsGraphicShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='motion__wrapper'>
            <div className='motion__title'>
                <h4 className='motion__title-text'>Graphic & Motion Services</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={design} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='motion__description'>
                Our motion design service has several great advantages, one of which is that our files
                are very lightweight but high quality. This gives a huge speed advantage to our clients
                websites over competitors, as well as adding unique branding and a pleasant user
                experience.
            </div>
            <ServiceNavigation
                firstNavItem='Graphic Design'
                secondNavItem='Motion Design'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={wayIcon}
            />
            {currentNavItem === "Graphic Design" ? (
                !isMobile ? (
                    <GraphicContent animation={motionAnimation} content={graphicDesignParagraps} />
                ) : (
                    <ServicesMobileTab
                        isShema={isGraphicShema}
                        setIsShema={setIsGraphicShema}
                        animation={motionAnimation}
                        content={graphicDesignParagraps}
                        isList
                    />
                )
            ) : !isMobile ? (
                <MotionContent animation={motionAnimation} content={motionDesignParagraps} />
            ) : (
                <ServicesMobileTab
                    isShema={isShema}
                    setIsShema={setIsShema}
                    animation={motionAnimation}
                    content={motionDesignParagraps}
                    isList
                />
            )}
        </div>
    );
};

export default MotionDesignBlock;
