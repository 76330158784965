import { Box } from "@mui/material";
import InputField from "../../FormFields/InputField";
import React from "react";
import { useTranslation } from "react-i18next";
import { invalidNumberInputChars } from "../../validations/regexp";
import InputPhoneField from "../../FormFields/InputPhoneField";
import { formIdObject } from "../../constants";

const PersonalInfo = (props) => {
    const {
        formField: { hourly_salary, email, telegram, phone_numbers, linkedin, portfolio },
        formId,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <Box className='formFieldInfo'>
                <InputField
                    name={hourly_salary.name}
                    label={t("page.dev-form.hourly-rate")}
                    type='number'
                    onKeyDown={(e) => {
                        if (invalidNumberInputChars.includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    fullWidth
                />
            </Box>
            <div className='formFieldInfo__title'>
                <p className='formFieldInfo__title-block'>
                    {t("page.dev-form.personal-information.title")}
                </p>
            </div>
            <InputField
                name={email.name}
                label={t("page.dev-form.personal-information.email")}
                type='email'
            />
            <Box className='formField formField--flex formField--smallMargin'>
                <InputField
                    name={telegram.name}
                    label={t("page.dev-form.personal-information.telegram")}
                    type='text'
                />
                <Box sx={{ width: "2.5rem" }} />
                <InputPhoneField
                    name={phone_numbers.name}
                    label={t("page.dev-form.personal-information.phone-number")}
                    type='text'
                />
            </Box>
            <Box className='formField formField--flex formField--smallMargin'>
                <InputField
                    name={linkedin.name}
                    label={t("page.dev-form.personal-information.linkedin")}
                    type='text'
                />
                <Box sx={{ width: "2.5rem" }} />
                <InputField
                    name={portfolio.name}
                    label={
                        formId === formIdObject.designForm
                            ? t("page.design-form.personal-information.portfolio")
                            : t("page.dev-form.personal-information.portfolio")
                    }
                    type='text'
                />
            </Box>
        </>
    );
};

export default PersonalInfo;
