import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Products from "./pages/Products/Products";
import Community from "./pages/Community/Community";
import Contacts from "./pages/Contacts/Contacts";
import EmployeeForm from "./pages/Forms/EmployeeForm";
import ClientForm from "./pages/Forms/ClientForm";
import "./lang/i18n";

import ModernaCasa from "./pages/ModernaCasa/ModernaCasa";
import { routePaths } from "./shared/routes";
import AffPro from "./pages/AffPro/AffPro";
import Rico from "./pages/Rico/Rico";

import "./App.scss";
import ArchivableLayer from "./components/ArchivableLayer/ArchivableLayer";
import { useMobileMedia } from "./utils/useMediaQueries";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import MainPage from "./pages/MainPage/MainPage";
import AlienClick from "./pages/AlienClick/AlienClick";
import { submitEmployeeForm } from "./pages/Forms/services/formSubmitService";
import { devFormInitialValuesObject } from "./pages/Forms/InitialValues/devFormInitialValues";
import { devValidationSchema } from "./pages/Forms/validations/validationsDevForm";
import { devFormModelObject } from "./pages/Forms/FormModels/devFormModel";
import { designFormModelObject } from "./pages/Forms/FormModels/designFormModel";
import { designFormInitialValuesObject } from "./pages/Forms/InitialValues/designFormInitialValues";
import { designValidationSchema } from "./pages/Forms/validations/validationsDesignForm";
import { pmFormModelObject } from "./pages/Forms/FormModels/pmFormModel";
import { pmFormInitialValuesObject } from "./pages/Forms/InitialValues/pmFormInitialValues";
import { pmValidationSchema } from "./pages/Forms/validations/validationsPMForm";
import { salesFormModelObject } from "./pages/Forms/FormModels/salesFormModel";
import { salesFormInitialValuesObject } from "./pages/Forms/InitialValues/salesFormInitialValues";
import { salesValidationSchema } from "./pages/Forms/validations/validationsSalesForm";
import CookiesPopup from "./components/CookiesPopup/CookiesPopup";
import { Box } from "@mui/material";

//Sentry tracking
Sentry.init({
    environment: process.env.REACT_APP_NODE_ENV,
    dsn: "https://f85b4f84f39b4cb48b1e65598b3b1c38@o4505376053264384.ingest.sentry.io/4505376062111744",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                /^https:\/\/newdev\.focusdigitalsyndicate\.com\//,
                /^https:\/\/focusdigitalsyndicate\.com\//,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const layers = [
    {
        title: "Who we are",
        content: <Home />,
        backgroundColor: "#1D1D1E",
    },
    {
        title: "Services",
        content: <Services />,
        backgroundColor: "#282828",
    },
    {
        title: "Product & cases",
        content: <Products />,
        backgroundColor: "#2F2F2F",
    },
    {
        title: "Community",
        content: <Community />,
        backgroundColor: "#3A3A3A",
    },
    {
        title: "Contact",
        content: <Contacts />,
        backgroundColor: "#454545",
    },
];
const getDataRoutes = (isMobile, isMenuOpen) => {
    return [
        {
            url: routePaths.mainPage,
            pageName: (
                <>
                    <div className='transitionPage'>
                        <Box
                            component='div'
                            className='pageAnimation'
                            id='mainSection'
                            sx={{ visibility: isMenuOpen ? "hidden" : "visible" }}
                        >
                            {isMobile ? <MainPage /> : <ArchivableLayer layers={layers} />}
                        </Box>
                    </div>
                </>
            ),
        },
        {
            url: routePaths.developmentForm,
            pageName: (
                <div className='transitionPage'>
                    <EmployeeForm
                        formModel={devFormModelObject}
                        submitFunction={submitEmployeeForm}
                        formInitialValues={devFormInitialValuesObject}
                        validationSchema={devValidationSchema}
                        vacancyId={39153}
                    />
                </div>
            ),
        },
        {
            url: routePaths.designForm,
            pageName: (
                <div className='transitionPage'>
                    <EmployeeForm
                        formModel={designFormModelObject}
                        submitFunction={submitEmployeeForm}
                        formInitialValues={designFormInitialValuesObject}
                        validationSchema={designValidationSchema}
                        vacancyId={40838}
                    />
                </div>
            ),
        },
        {
            url: routePaths.salesForm,
            pageName: (
                <div className='transitionPage'>
                    <EmployeeForm
                        formModel={salesFormModelObject}
                        submitFunction={submitEmployeeForm}
                        formInitialValues={salesFormInitialValuesObject}
                        validationSchema={salesValidationSchema}
                        vacancyId={40841}
                    />
                </div>
            ),
        },
        {
            url: routePaths.projectManagementForm,
            pageName: (
                <div className='transitionPage'>
                    <EmployeeForm
                        formModel={pmFormModelObject}
                        submitFunction={submitEmployeeForm}
                        formInitialValues={pmFormInitialValuesObject}
                        validationSchema={pmValidationSchema}
                        vacancyId={40546}
                    />
                </div>
            ),
        },
        {
            url: routePaths.clientForm,
            pageName: (
                <div className='transitionPage'>
                    <ClientForm />
                </div>
            ),
        },
        {
            url: routePaths.modernaCasa,
            pageName: <ModernaCasa />,
        },
        {
            url: routePaths.affPro,
            pageName: <AffPro />,
        },
        {
            url: routePaths.rico,
            pageName: <Rico />,
        },
        {
            url: routePaths.alienClickPage,
            pageName: <AlienClick />,
        },
        {
            url: routePaths.privacyPolicyPage,
            pageName: <PrivacyPolicy />,
        },
    ];
};

const App = () => {
    const location = useLocation();
    const isMobile = useMobileMedia();
    const [isCookie, setIsCookie] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // New scroll to section + 50 top
    let mobileTop = isMobile ? 70 : 60;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sectionToScrollTo = urlParams.get("section");
        if (sectionToScrollTo) {
            scrollTo(sectionToScrollTo);
        }
    }, []);
    useEffect(() => {
        const isOpen = localStorage.getItem("cookiesPopup");
        if (isOpen === "isOpen") {
            setIsCookie(true);
        }
    }, []);

    const scrollTo = (index) => {
        setTimeout(() => {
            let element;
            //TEMPORAR FIX FOR PROD MOBILE (NO ARCHIVABLE LAYER ON MOBILE YET)
            //TODO : REMOVE THIS CODE WHEN ARCHIVABLE LAYER IS READY FOR MOBILE
            if (isMobile && index === "3") {
                element = document.getElementById("communitySection");
            } else {
                element = document.getElementById("layerContentBox-" + index);
            }

            if (element) {
                const topOffset = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: index === "0" ? 0 : topOffset - mobileTop,
                });
                document.body.style.overflow = "auto";
            }
        }, 0);
    };

    return (
        <Provider store={store}>
            <div className='main'>
                {!isCookie && <CookiesPopup />}

                <Header setIsMenuOpen={setIsMenuOpen} />

                <Routes location={location}>
                    {getDataRoutes(isMobile, isMenuOpen).map(({ url, pageName }, i) => {
                        return <Route path={`/${url}`} element={pageName} key={i} />;
                    })}
                    <Route path={`/:word/${routePaths.privacyPolicyPage}`} element={<PrivacyPolicy />} />
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
                <Footer />
            </div>
        </Provider>
    );
};

export default App;
