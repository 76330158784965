import { getCountryCode } from "../../pages/Forms/SelectOptions/countryOptions";
import { SET_COUNTRY_CODE } from "../actionTypes/countyCodeActionTypes";
export const setCountryCode = (countryCode) => ({
    type: SET_COUNTRY_CODE,
    payload: countryCode,
});

export const fetchCountryCode = () => {
    return async (dispatch) => {
        try {
            const country_code = await getCountryCode();
            dispatch(setCountryCode(country_code));
        } catch (error) {
            console.log(error);
        }
    };
};
