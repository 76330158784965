import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#41b56f",
        },
        text: {
            primary: "#fff",
        },
        error: {
            main: "#f04a4a",
        },
    },
    shape: {
        borderRadius: 16,
    },
});

export { muiTheme };
