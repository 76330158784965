import React, { useState, useRef, useCallback, memo } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

import developmentLogo from "../../assets/svg/robot.svg";
import designLogo from "../../assets/svg/forms/design-form-logo.svg";
import salesLogo from "../../assets/svg/forms/sales-form-logo.svg";
import projectManegementLogo from "../../assets/svg/forms/project-management-logo.svg";

import { useMobileMedia, useTabletMedia } from "../../utils/useMediaQueries";

import "./Community.scss";
import { routePaths } from "../../shared/routes";

import Lottie from "lottie-light-react";
import animationFromCommunity from "./img/community-animation.json";
import joinCommunityAnimation from "./img/join-community-animation.json";
import Loader from "../../components/Loader/Loader";
import { formIdObject } from "../Forms/constants";

export const chooseWhoAreYouObject = [
    {
        id: formIdObject.devForm,
        name: "Development",
        text: "Looking for a job?",
        icons: [developmentLogo],
        routePath: routePaths.developmentForm,
    },
    {
        id: formIdObject.designForm,
        name: "Design",
        text: "Looking for a job?",
        icons: [designLogo],
        routePath: routePaths.designForm,
    },
    {
        id: formIdObject.salesForm,
        name: "BD & Sales",
        text: "Looking for a job?",
        icons: [salesLogo],
        routePath: routePaths.salesForm,
    },
    {
        id: formIdObject.pmForm,
        name: "Project management",
        text: "Looking for a job?",
        icons: [projectManegementLogo],
        routePath: routePaths.projectManagementForm,
    },
];

const Community = () => {
    const navigate = useNavigate();
    const isMobileMedia = useMobileMedia();
    const isTabletMedia = useTabletMedia();
    const communitySectionRef = useRef(null);
    const animationFromCommunityRef = useRef(null);
    const communityImageContainerRef = useRef(null);
    const [animationClass, setAnimationClass] = useState("");
    const [selectedItem, setSelectedItem] = useState("developmentForm");
    const [isJoinCommunityAnimation, setIsJoinCommunityAnimation] = useState(false);
    const [selectedFormUrl, setSelectedFormUrl] = useState(routePaths.developmentForm);

    let headerTop = isMobileMedia ? 107 : isTabletMedia ? 69 : 89;

    const handleSelectionChange = useCallback((id, routePath) => {
        setSelectedItem(id);
        setSelectedFormUrl(routePath);
    }, []);

    // animation uses repeaters, which add up every time you use the same animation instance. need to import the data as a string:
    const communityAnimData = JSON.parse(JSON.stringify(animationFromCommunity));
    const joinCommunityAnimData = JSON.parse(JSON.stringify(joinCommunityAnimation));

    const handleContinue = () => {
        setIsJoinCommunityAnimation(true);
        setAnimationClass("exit-active");
        document.body.classList.add("disable-scroll");
        window.scrollTo({
            top: communitySectionRef.current.offsetTop + headerTop,
            behavior: "smooth",
        });

        let animTopTime = setTimeout(() => {
            animationFromCommunityRef.current.goToAndPlay(0);
        }, 1000);

        let animPlay = setTimeout(() => {
            animationFromCommunityRef.current.destroy();
            navigate(selectedFormUrl);
            window.scrollTo(0, 0);

            // window.location.href = selectedFormUrl;
            document.body.classList.remove("disable-scroll");
            clearTimeout(animTopTime);
            clearTimeout(animPlay);
        }, 3900);
    };

    return (
        <section
            ref={communitySectionRef}
            id='communitySection'
            className={`pageSection ${animationClass}`}
        >
            <div className='communityWrapper'>
                <div className='wrapper'>
                    <h1 className='communityTitle basicTitle'>COMMUNITY</h1>

                    <div className='community'>
                        <div className='communityChoose'>
                            <h3 className='communityChoose__title'>Choose your sphere to continue</h3>
                            {chooseWhoAreYouObject.map((item, index) => (
                                <div className={`chooseItem ${item.id}`} key={index}>
                                    <label
                                        htmlFor={item.id}
                                        className={`chooseItem__label ${
                                            selectedItem === item.id ? "active" : ""
                                        }`}
                                    >
                                        <input
                                            type='radio'
                                            name='item'
                                            id={item.id}
                                            value={item.id}
                                            checked={selectedItem === item.id}
                                            onChange={() =>
                                                handleSelectionChange(item.id, item.routePath)
                                            }
                                        />

                                        <div className='chooseItem__icons'>
                                            {item.icons.map((icon, i) => (
                                                <ReactSVG
                                                    src={icon}
                                                    className='chooseItem__icon'
                                                    wrapper='span'
                                                    key={i}
                                                    loading={() => <Loader />}
                                                />
                                            ))}
                                        </div>

                                        <p>
                                            <span className='chooseItem__name'>{item.name}</span>
                                            <span className='chooseItem__text'>{item.text}</span>
                                        </p>
                                    </label>
                                </div>
                            ))}

                            <button
                                type='button'
                                className='communityBtn mainBtn'
                                disabled={!selectedItem}
                                onClick={handleContinue}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`communityImage`} ref={communityImageContainerRef}>
                    {isJoinCommunityAnimation ? (
                        <Lottie
                            animationData={joinCommunityAnimData}
                            lottieRef={animationFromCommunityRef}
                            autoplay={false}
                            loop={false}
                        />
                    ) : (
                        <Lottie
                            animationData={communityAnimData}
                            lottieRef={animationFromCommunityRef}
                            autoplay={true}
                            loop={true}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default memo(Community);
