import submitFailImg from "../../../assets/images/forms/bg.png";
import submitSucceedJson from "../../../assets/images/forms/success.json";

const goBackHome = () => {
    window.location.href = "/";
};

const getEndModalProps = (t) => {
    return {
        succeedImage: submitSucceedJson,
        succeedImageAlt: t("accessibility.asset.success-form-image-alt"),
        succeedLabel: t("component.end-form-modal.success.label"),
        succeedButtonLabel: t("component.end-form-modal.success.button-label"),
        onClickSucceedButton: goBackHome,
        failImage: submitFailImg,
        failImageAlt: t("accessibility.asset.failure-form-image-alt"),
        failLabel: t("component.end-form-modal.failure.label"),
        failButtonLabel: t("component.end-form-modal.failure.button-label"),
        onClickFailButton: goBackHome,
    };
};

export { getEndModalProps };
