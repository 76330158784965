import React, { memo, useState } from "react";
import { ReactSVG } from "react-svg";
import "./CookiesPopup.scss";
import cookieIcon from "../../assets/svg/cookie.svg";

const CookiesPopup = () => {
    const [isClick, setIsClick] = useState(false);

    const handleGotItClick = () => {
        setIsClick(true);
        localStorage.setItem("cookiesPopup", "isOpen");
    };

    return (
        <div className={`cookies-popup ${isClick ? "hidden" : ""}`}>
            <div className='cookies-popup__wrapper'>
                <div className='cookies-popup__icon-wrapper'>
                    <ReactSVG src={cookieIcon} className='cookies-popup__icon-wrapper__icon' />
                </div>
                <p className='cookies-popup__text'>
                    This website uses cookies to ensure you get the best experience on our website.
                </p>
                <button className='cookies-popup__button' onClick={handleGotItClick}>
                    GOT IT
                </button>
            </div>
        </div>
    );
};

export default memo(CookiesPopup);
