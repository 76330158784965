import React, { memo, useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import Home from "../Home/Home";
import Services from "../Services/Services";
import Products from "../Products/Products";
import Community from "../Community/Community";
import Contacts from "../Contacts/Contacts";
import { initialMainPageAnimationsStatus } from "../../loadingServices/loading.service";
import { useDispatch } from "react-redux";
import { setMainPageIsInitialState } from "../../redux/actions/loadingActions";

const MainPage = () => {
    const dispatch = useDispatch();
    const [allAnimationsLoaded, setAllAnimationsLoaded] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setAllAnimationsLoaded(true), 3000);

        return () => {
            clearTimeout(timeout);
            dispatch(setMainPageIsInitialState(initialMainPageAnimationsStatus));
        };
    }, []);

    return (
        <>
            {!allAnimationsLoaded && <Loader />}
            <Home />
            <Services />
            <Products />
            <Community />
            <Contacts />
        </>
    );
};

export default memo(MainPage);
