import * as Yup from "yup";
import clientFormModel from "../FormModels/clientFormModel";
import { emailRegexp, safeSQLStringRegexp } from "./regexp";
import { checkEmailValues, isEmailAlreadyUsed } from "../services/formSubmitService";

const {
    formField: {
        client_name,
        client_company,
        client_email,
        client_business_niche,

        client_choose_service,
        client_budget,

        client_discovered_company,
    },
} = clientFormModel;

export default [
    Yup.object().shape({
        [client_name.name]: Yup.string()
            .matches(safeSQLStringRegexp, `${client_name.specialCharactersErrorMsg}`)
            .required(`${client_name.requiredErrorMsg}`),
        [client_company.name]: Yup.string()
            .matches(safeSQLStringRegexp, `${client_company.specialCharactersErrorMsg}`)
            .required(`${client_company.requiredErrorMsg}`),
        [client_email.name]: Yup.string()
            .required(`${client_email.requiredErrorMsg}`)
            .matches(emailRegexp, `${client_email.invalidErrorMsg}`)
            .test("verified", "Email already used", async (value) => {
                const verified = await isEmailAlreadyUsed(value, checkEmailValues.clientFormValue);
                return !verified;
            }),
        [client_business_niche.name]: Yup.string().required(`${client_business_niche.requiredErrorMsg}`),
        [client_choose_service.name]: Yup.string().required(`${client_choose_service.requiredErrorMsg}`),
        [client_budget.name]: Yup.string().required(`${client_budget.requiredErrorMsg}`),
        [client_discovered_company.name]: Yup.string(),
    }),
];
