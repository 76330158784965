import React from "react";
import { Modal } from "@mui/material";
import Lottie from "lottie-light-react";
import { Fragment } from "react";

const EndFormModal = ({
    succeedLabel,
    succeedImage,
    succeedImageAlt,
    succeedButtonLabel,
    onClickSucceedButton,
    failLabel,
    failImage,
    failImageAlt,
    failButtonLabel,
    onClickFailButton,
    isSuccess,
    isOpen,
}) => {
    const content = (
        <Fragment>
            <Modal open={isOpen} aria-labelledby={""} aria-describedby={""}>
                <div className='submitMessage'>
                    <div className='submitMessage__block'>
                        {isSuccess ? (
                            <div className='submitMessage__json'>
                                <Lottie
                                    className='submitMessage__lottie'
                                    animationData={succeedImage}
                                    alt={succeedImageAlt}
                                    autoplay={true}
                                    loop={true}
                                />
                            </div>
                        ) : (
                            <div className='submitMessage__img'>
                                <img src={failImage} alt={failImageAlt} />
                            </div>
                        )}

                        <h2 className='submitMessage__title'>{isSuccess ? succeedLabel : failLabel}</h2>
                        <button
                            className={`submitMessage__action mainBtn mainBtn--continue`}
                            onClick={isSuccess ? onClickSucceedButton : onClickFailButton}
                        >
                            {isSuccess ? succeedButtonLabel : failButtonLabel}
                        </button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );

    return content;
};

export default EndFormModal;
