import SelectField from "../../FormFields/SelectField";
import { Box } from "@mui/material";
import InputField from "../../FormFields/InputField";
import React from "react";
import countryOptions from "../../SelectOptions/countryOptions";
import FormRadioGroup from "../../../../shared/components/FormRadioGroup/FormRadioGroup";
import { useTranslation } from "react-i18next";
import {
    englishLevelOptions,
    kindOfProjectOptions,
    // soloTeamlOptions,
} from "../../SelectOptions/dataSelection";
import CheckboxGroupField from "../../FormFields/CheckboxGroupField";

const MainProfileInfo = (props) => {
    const { t } = useTranslation();
    const {
        formField: {
            full_name,
            location,
            languages_level,
            availability,
            // solo_team,
            // team_expirience,
        },
        values,
    } = props;

    return (
        <>
            <Box className='formField formField--flex'>
                <InputField name={full_name.name} label={full_name.label} type='text' fullWidth />
                <Box sx={{ width: "2.5rem" }} />
                <SelectField name={location.name} label={location.label} data={countryOptions} />
            </Box>
            <Box className='formField__radioGroup'>
                <FormRadioGroup
                    title={t("page.dev-form.english-level")}
                    field={languages_level}
                    options={englishLevelOptions}
                    currentValue={values.languages_level}
                    style={{ marginBottom: "20px" }}
                />
            </Box>
            <Box className='formField__radioGroup'>
                <CheckboxGroupField name={availability.name} options={kindOfProjectOptions} />
            </Box>
            {/* <Box className='formField__radioGroup'>
                <FormRadioGroup
                    title={t("page.dev-form.team.title")}
                    field={solo_team}
                    options={soloTeamlOptions}
                    currentValue={values.solo_team}
                    style={{ marginBottom: "20px" }}
                />
            </Box>
            {values[solo_team.name] === soloTeamlOptions[1].value && (
                <Box className='formField formField--flex'>
                    <InputField
                        name={team_expirience.name}
                        label={team_expirience.label}
                        type='text'
                        fullWidth
                    />
                </Box>
            )} */}
        </>
    );
};

export default MainProfileInfo;
