import React, { useState } from "react";
import "./UIUXBlock.scss";
import brainsIcon from "../../../../assets/svg/services/brains.svg";
import pallete from "../../../../assets/svg/services/pallete.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import UXContent from "./UXContent/UXContent";
import UIContent from "./UIContent/UIContent";
import design from "../../../../assets/animations/services/design/ui-ux.json";
import Lottie from "lottie-light-react";
import { useMediaQuery } from "react-responsive";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import { uiParagraphs } from "./UIUXBlock.constants";
import uianimation from "../../../../assets/animations/servicesTabs/ui-scheme.json";
import { uxParagraphs } from "../UIUXBlock/UIUXBlock.constants";
import uxanimation from "../../../../assets/animations/servicesTabs/elevate-efficenty-tab-animation.json";
const UIUXBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("UX Process");
    const [isShema, setIsShema] = useState(true);
    const [isUxShema, setIsUxShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='uiux__wrapper'>
            <div className='uiux__title'>
                <h4 className='uiux__title-text'>UI/UX Services</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={design} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='uiux__description'>
                User Interface and User Experience design services are crucial elements in creating
                successful digital products such as websites, mobile applications, and software. While UI
                design focuses on the visual aspects of the product, such as its layout, colors,
                typography, and iconography, UX design focuses on the overall experience of the user,
                including the products ease of use, accessibility, and functionality.
            </div>
            <ServiceNavigation
                firstNavItem='UX Process'
                secondNavItem='UI Process'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={currentNavItem === "UX Process" ? brainsIcon : pallete}
            />
            {currentNavItem === "UX Process" ? (
                !isMobile ? (
                    <UXContent animation={uxanimation} content={uxParagraphs} />
                ) : (
                    <ServicesMobileTab
                        isShema={isUxShema}
                        setIsShema={setIsUxShema}
                        animation={uxanimation}
                        content={uxParagraphs}
                    />
                )
            ) : !isMobile ? (
                <UIContent animation={uianimation} content={uiParagraphs} />
            ) : (
                <ServicesMobileTab
                    isShema={isShema}
                    setIsShema={setIsShema}
                    animation={uianimation}
                    content={uiParagraphs}
                />
            )}
        </div>
    );
};

export default UIUXBlock;
