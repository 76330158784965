import React from "react";
import "./Menu.scss";
import { useMobileMedia } from "../../utils/useMediaQueries";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
const Menu = ({ className, setIsOpen, setIsMenuOpen }) => {
    const menuItems = i18next.t("component.menu.menuItems", {
        returnObjects: true,
    });
    const isMobile = useMobileMedia();
    const navigate = useNavigate();
    // New scroll to section + 50 top
    let mobileTop = isMobile ? 100 : 60;

    const handleCaseButtonClick = (id, routePath) => {
        setIsOpen(false);
        setIsMenuOpen(false);
        if (routePath) {
            // Navigate to a different page
            navigate(routePath);
        }
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                // Scroll to the selected section on the current page
                const topOffset = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: id === "mainSection" ? 0 : topOffset - mobileTop,
                });
                document.body.style.overflow = "auto";
            }
        }, 0);
    };

    return (
        <section className={`navigationMenu ${className} `}>
            <div className='wrapper'>
                <div className='menuList'>
                    {menuItems.map((item) => (
                        <div className='menuItem' key={item.id}>
                            <div
                                className='menuItem__title'
                                onClick={() => handleCaseButtonClick(item.id, item.routePath)}
                            >
                                <span className='menuItem__titleNum'>{item.number}</span>
                                <span className='menuItem__titleName'>{item.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Menu;
