import React from "react";
import "./HeaderTabletComponent.scss";
import { ReactSVG } from "react-svg";
import linkIcon from "../../../../assets/svg/link-icon.svg";
import behanceIcon from "../../../../assets/svg/behance.svg";
import { NavLink } from "react-router-dom";
import { alienclickLink, services } from "../AlienClickBlock";
import videoAlienCLick from "../../../../assets/animations/alienclick/alien-header-video.mp4";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlienPageIsLoading } from "../../../../redux/actions/loadingActions";
import { alienPageAnimationTitles } from "../../../../loadingServices/loading.service";
import Loader from "../../../../components/Loader/Loader";
const HeaderTabletComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const setIsLoading = (key, value) => {
        dispatch(
            setAlienPageIsLoading({
                key: key,
                value: value,
            }),
        );
    };

    return (
        <header className='affpro__mobile-header'>
            <div className='affpro__mobile-header-info'>
                <a
                    href={alienclickLink}
                    className='affpro__mobile-header-link'
                    target='_blank'
                    rel='noreferrer'
                >
                    <h1 className='affpro__mobile-header-title'>{t("page.alien-click.click-all")}</h1>
                    <ReactSVG
                        src={linkIcon}
                        className='affpro__mobile-header-svg'
                        wrapper='span'
                        loading={() => <Loader />}
                    />
                </a>
                <h4 className='affpro__mobile-header-description'>
                    {t("page.alien-click.header-description")}
                </h4>
                <div className='affpro__mobile-header-list'>
                    {services.map((service, index) => (
                        <div className='affpro__mobile-header-list-item' key={service + index}>
                            #{service}
                        </div>
                    ))}
                </div>
                <video
                    className='affpro__mobile-header-img'
                    autoPlay
                    muted
                    loop
                    playsInline
                    onCanPlayThrough={() =>
                        setIsLoading(alienPageAnimationTitles.alienHeaderVideo, true)
                    }
                >
                    <source src={videoAlienCLick} type='video/mp4' />
                </video>

                <a
                    className='affpro__mobile-header-behance'
                    href='https://www.behance.net/designfds'
                    target='_blank'
                    rel='noreferrer'
                >
                    <span>{t("page.alien-click.view-on-behance")}</span>
                    <NavLink to={alienclickLink} target='_blank'>
                        <ReactSVG
                            src={behanceIcon}
                            className='affpro__mobile-header-behance-svg'
                            wrapper='span'
                            loading={() => <Loader />}
                        />
                    </NavLink>
                </a>
            </div>
        </header>
    );
};

export default HeaderTabletComponent;
