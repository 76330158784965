import { React } from "react";
import { useField } from "formik";
import TextareaAutosize from "@mui/base/TextareaAutosize";

const TextareaComponent = (props) => {
    // const { errorText, placeholder, ...rest } = props; // there was prittier error
    const { placeholder, ...rest } = props;
    const [field] = useField(props);
    return (
        <TextareaAutosize
            minRows={3}
            placeholder={placeholder}
            className='formField__textarea'
            fullWidth
            {...field}
            {...rest}
        />
    );
};

export default TextareaComponent;
