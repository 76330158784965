import React from "react";
import "./HeaderTabletComponent.scss";
import { ReactSVG } from "react-svg";
import behanceIcon from "../../../../assets/svg/behance.svg";
import { ricoLink, services } from "../RicoBlock";
import Loader from "../../../../components/Loader/Loader";

const HeaderTabletComponent = () => {
    return (
        <header className='rico__mobile-header'>
            <div className='rico__mobile-header-info'>
                <div className='rico__mobile-header-link' target='_blank'>
                    <h1 className='rico__mobile-header-title'>RICO</h1>
                </div>
                <h4 className='rico__mobile-header-description'>
                    Save time with Rico’s property management instruments and rent an appropriate place
                    to experience your vivid moments. Depending on your requirements, the App renders
                    relevant listings of available realty thanks to numerous categories and tags provided
                    by a progressive search toolset. Find the perfect fit for your lifestyle with Rico is
                    wide interface offering extra features to help you make the right decision!
                </h4>
                <div className='rico__mobile-header-list'>
                    {services.map((service, index) => (
                        <div className='rico__mobile-header-list-item' key={service + index}>
                            #{service}
                        </div>
                    ))}
                </div>
                <div className='rico__mobile-header-svg-block' />
                <div className='rico__mobile-header-behance'>
                    <a
                        href={ricoLink}
                        target='_blank'
                        className='rico__mobile-header-behance-svg'
                        rel='noreferrer'
                    >
                        <span>View the project on Behance</span>
                        <ReactSVG src={behanceIcon} wrapper='span' loading={() => <Loader />} />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default HeaderTabletComponent;
