// technology icons
import row11icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-1.svg";
import row12icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-2.svg";
import row13icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-3.svg";
import row14icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-4.svg";
import row15icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-5.svg";
import row16icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-6.svg";
import row17icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-1-7.svg";
import row21icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-1.svg";
import row22icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-2.svg";
import row23icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-3.svg";
import row31icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-3-1.svg";
import row32icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-3-2.svg";
import row33icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-3-3.svg";
import row24icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-4.svg";
import row25icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-5.svg";
import row26icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-6.svg";
import row27icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-2-7.svg";
import row41icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-4-1.svg";
import row42icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-4-2.svg";
import row43icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-4-3.svg";
import row44icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-4-4.svg";
import row45icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-4-5.svg";
import row51icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-5-1.svg";
import row52icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-5-2.svg";
import row53icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-5-3.svg";
import row61icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-6-1.svg";
import row62icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-6-2.svg";
import row63icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-6-3.svg";
import row71icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-7-1.svg";
import row72icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-7-2.svg";
import row73icon from "../../../../../assets/svg/services/developmentTechnologyIcons/row-7-3.svg";

export const technologyIcons = [
    {
        id: 1,
        rowTitle: "Front-end development",
        icons: [
            {
                id: 11,
                icon: row11icon,
            },
            {
                id: 12,
                icon: row12icon,
            },
            {
                id: 13,
                icon: row13icon,
            },
            {
                id: 14,
                icon: row14icon,
            },
            {
                id: 15,
                icon: row15icon,
            },
            {
                id: 16,
                icon: row16icon,
            },
            {
                id: 17,
                icon: row17icon,
            },
        ],
    },
    {
        id: 2,
        rowTitle: "Back-end development",
        icons: [
            {
                id: 21,
                icon: row21icon,
            },
            {
                id: 22,
                icon: row22icon,
            },
            {
                id: 23,
                icon: row23icon,
            },
            {
                id: 24,
                icon: row24icon,
            },
            {
                id: 25,
                icon: row25icon,
            },
            {
                id: 26,
                icon: row26icon,
            },
            {
                id: 27,
                icon: row27icon,
            },
        ],
    },
    {
        id: 3,
        rowTitle: "Mobile development",
        icons: [
            {
                id: 31,
                icon: row31icon,
            },
            {
                id: 32,
                icon: row32icon,
            },
            {
                id: 33,
                icon: row33icon,
            },
        ],
    },
    {
        id: 4,
        rowTitle: "Database",
        icons: [
            {
                id: 41,
                icon: row41icon,
            },
            {
                id: 42,
                icon: row42icon,
            },
            {
                id: 43,
                icon: row43icon,
            },
            {
                id: 44,
                icon: row44icon,
            },
            {
                id: 45,
                icon: row45icon,
            },
        ],
    },
    {
        id: 5,
        rowTitle: "Content Management Systems",
        icons: [
            {
                id: 51,
                icon: row51icon,
            },
            {
                id: 52,
                icon: row52icon,
            },
            {
                id: 53,
                icon: row53icon,
            },
        ],
    },
    {
        id: 6,
        rowTitle: "Version control, code quality and testing",
        icons: [
            {
                id: 61,
                icon: row61icon,
            },
            {
                id: 62,
                icon: row62icon,
            },
            {
                id: 63,
                icon: row63icon,
            },
        ],
    },
    {
        id: 7,
        rowTitle: "Cloud platforms",
        icons: [
            {
                id: 71,
                icon: row71icon,
            },
            {
                id: 72,
                icon: row72icon,
            },
            {
                id: 73,
                icon: row73icon,
            },
        ],
    },
];
