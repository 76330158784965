import React from "react";
import { Button, Box } from "@mui/material";
import hoverCursor from "../../../assets/hover-cursor.png";

const FormNavigation = (props) => {
    const { isSubmitting, privacyCheckboxIsActive } = props;

    return (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", marginTop: "0.5rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    disabled={isSubmitting || !privacyCheckboxIsActive}
                    type='submit'
                    variant='contained'
                    sx={{ marginLeft: "1rem", cursor: `url(${hoverCursor}), pointer` }}
                    className='actionForm__submitButton'
                >
                    Send
                </Button>
                {/* {isSubmitting && <CircularProgress size={24} />} */}
            </Box>
        </Box>
    );
};

export { FormNavigation };
