import React, { memo } from "react";
import { policyData, termsAndConditionsData } from "./policyData";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    return (
        <section className='policyPage'>
            <div className='wrapper' id='mainSection'>
                <h2 className='policyPage__title'>TERMS & CONDITIONS</h2>

                <div className='policyContent'>
                    {termsAndConditionsData.map((block, index) => (
                        <div className='policyBlock' key={index}>
                            <h3 className='policyBlock__title'>{block.title}</h3>
                            {block.text &&
                                block.text.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}

                            {block.list && (
                                <ul>
                                    {block.list.map((listItem, i) => (
                                        <li className='policyBlock__list' key={i}>
                                            {listItem}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {block.text2 &&
                                block.text2.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}

                            {block.list2 && (
                                <ul>
                                    {block.list.map((listItem, i) => (
                                        <li className='policyBlock__list' key={i}>
                                            {listItem}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {block.text3 &&
                                block.text3.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}
                        </div>
                    ))}
                </div>

                <h2 className='policyPage__title' id='privacy-section'>
                    PRIVACY POLICY
                </h2>

                <div className='policyContent'>
                    {policyData.map((block, index) => (
                        <div className='policyBlock' key={index}>
                            <h3 className='policyBlock__title'>{block.title}</h3>
                            {block.text &&
                                block.text.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}

                            {block.list && (
                                <ul>
                                    {block.list.map((listItem, i) => (
                                        <li className='policyBlock__list' key={i}>
                                            {listItem}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {block.text2 &&
                                block.text2.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}

                            {block.list2 && (
                                <ul>
                                    {block.list.map((listItem, i) => (
                                        <li className='policyBlock__list' key={i}>
                                            {listItem}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {block.text3 &&
                                block.text3.map((blockItem, i) => (
                                    <p className='policyBlock__text' key={i}>
                                        {blockItem}
                                    </p>
                                ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default memo(PrivacyPolicy);
