export const processParagraphs = [
    {
        id: 1,
        title: "Initial consultation",
        descriptions: [
            "Consider scheduling a meeting to clarify any ambiguous or incomplete requirements.",
        ],
    },
    {
        id: 2,
        title: "Discovery phase",
        descriptions: [
            `Pre-analyze the client’s infrastructure and understanding the scope of work.`,
            "Consider using automation tools to reduce manual effort and ensure consistency in analysis.",
            `Consider involving the client’s IT team (if available) in the analysis process to ensure an accurate understanding of the infrastructure.`,
        ],
    },
    {
        id: 3,
        title: "Create a roadmap",
        descriptions: [
            "Prioritize improvements based on business impact and technical feasibility.",
            "Consider creating multiple roadmaps to address different aspects of the infrastructure (e.g., security, performance, scalability).",
        ],
    },
    {
        id: 4,
        title: "Getting to know the team",
        descriptions: [
            "Introduce team members that will work on the project, describe responsible areas.",
            `Meet the client’s team, if any.`,
            "Consider assigning a backup team member for each critical task to mitigate risks.",
        ],
    },
    {
        id: 5,
        title: "PoC",
        descriptions: [
            `Perform a PoC for solution in the current infrastructure.`,
            `Make sure that PoC covers all relevant use cases and scenarios.`,
            "Involving develop team to test and ensure compatibility with existing systems.",
        ],
    },
    {
        id: 6,
        title: "Epics",
        descriptions: [
            `Ensure each task is well-defined, independent, and achievable within a reasonable time frame.`,
            `Assign ownership for each task to a specific team member.`,
        ],
    },
    {
        id: 7,
        title: "Define processes",
        descriptions: [
            `Create boards, assign tasks to team members, retros  and other Agile ceremonies if needed.`,
            `We ensuring that all team members are aware of their tasks, deadlines, and dependencies.`,
            "Schedule regular sync meetings to review progress, address issues, and plan for the next sprint.",
        ],
    },
];
