import SelectField from "../../FormFields/SelectField";
import { Box } from "@mui/material";
import InputField from "../../FormFields/InputField";
import { salesSpecialityOptions, seniorityLevelOptions } from "../../SelectOptions/dataSelection";
import React, { useEffect } from "react";
import { invalidNumberInputChars } from "../../validations/regexp";
import TextareaComponent from "../../FormFields/Textarea";
import { useTranslation } from "react-i18next";
import { FieldArray, useFormikContext } from "formik";

const SalesAdditionalBlock = (props) => {
    const {
        formField: { position, employee_level, experience, achievements },
        values,
    } = props;

    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (!values.skills || values.skills.length === 0) {
            setFieldValue("skills", [{ id: Math.random(), technology: "", experience: "" }]);
        }
    }, []);

    return (
        <>
            <Box className='formField formField--flex formField--smallMargin'>
                <SelectField name={position.name} label={position.label} data={salesSpecialityOptions} />
                <Box sx={{ width: "2.5rem" }} />
                <SelectField
                    name={employee_level.name}
                    label={employee_level.label}
                    data={seniorityLevelOptions}
                />
            </Box>
            <Box className='formField formField--flex formField--smallMargin'>
                <InputField
                    name={experience.name}
                    label={experience.label}
                    type='number'
                    onKeyDown={(e) => {
                        if (invalidNumberInputChars.includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
            </Box>
            <Box className='formFieldInfo'>
                <div className='formFieldInfo__title'>
                    <p className='formFieldInfo__title-desk'>
                        {t("page.sales-form.core-technologies.subtitle")}
                    </p>
                </div>
                <FieldArray name='skills'>
                    {({ remove, push }) => (
                        <div>
                            {values?.skills?.length > 0 &&
                                values?.skills?.map((tech, index) => (
                                    <div
                                        className='formField formField--flex formField--selection'
                                        key={index}
                                    >
                                        <InputField
                                            name={`skills[${index}].technology`}
                                            placeholder={t(
                                                "page.sales-form.core-technologies.technology-placeholder",
                                            )}
                                            type='text'
                                        />
                                        <Box sx={{ width: "2.5rem" }} />
                                        <InputField
                                            name={`skills[${index}].experience`}
                                            placeholder={t(
                                                "page.team-form.tech-stack-step.experience-placeholder",
                                            )}
                                            type='number'
                                            onKeyDown={(e) => {
                                                if (invalidNumberInputChars.includes(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        <Box sx={{ width: "2.5rem" }} />
                                        <div className='formMore formField--flex'>
                                            {index === 0 ? (
                                                <button
                                                    className={`formField__btnMore`}
                                                    type='button'
                                                    onClick={() =>
                                                        push({
                                                            id: Math.random(),
                                                            technology: "",
                                                            experience: "",
                                                        })
                                                    }
                                                ></button>
                                            ) : (
                                                <button
                                                    className='formField__btnMore formField__btnMore--remove'
                                                    type='button'
                                                    onClick={() => remove(index)}
                                                ></button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </FieldArray>
            </Box>
            <TextareaComponent name={achievements.name} placeholder={achievements.label} fullWidth />
        </>
    );
};

export default SalesAdditionalBlock;
