import React from "react";
import "./TechnologyContent.scss";
import IconsBlock from "../../../IconBlock/IconBlock";
import { technologyIcons } from "./TechnologyContent.constants";

const TechnologyContent = () => {
    return (
        <div className='technology__wrapper'>
            <div className='technology__right-side'>
                <div className='technology__title'>
                    <span>Our core</span>
                    <span>Technology</span>
                    <span>(but not the only ones)</span>
                </div>
            </div>
            <div className='technology__icons'>
                <IconsBlock technologyIcons={technologyIcons} notCentered />
            </div>
        </div>
    );
};

export default TechnologyContent;
