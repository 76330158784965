import first from "../../../assets/animations/rico/first-animation.json";
import second from "../../../assets/animations/rico/second-animation.json";
import third from "../../../assets/animations/rico/third-animation.json";
import fourth from "../../../assets/animations/rico/fourth-animation.json";
import fifth from "../../../assets/animations/rico/fifth-animation.json";
import sixth from "../../../assets/animations/rico/sixth-animation.json";

export const tickerElements = [
    {
        id: 1,
        animation: first,
    },
    {
        id: 2,
        animation: second,
    },
    {
        id: 3,
        animation: third,
    },
    {
        id: 4,
        animation: fourth,
    },
    {
        id: 5,
        animation: fifth,
    },
    {
        id: 6,
        animation: sixth,
    },
    {
        id: 7,
        animation: first,
    },
    {
        id: 8,
        animation: second,
    },
    {
        id: 9,
        animation: third,
    },
    {
        id: 10,
        animation: fourth,
    },
    {
        id: 11,
        animation: fifth,
    },
    {
        id: 12,
        animation: sixth,
    },
    {
        id: 13,
        animation: first,
    },
    {
        id: 14,
        animation: second,
    },
    {
        id: 15,
        animation: third,
    },
    {
        id: 16,
        animation: fourth,
    },
    {
        id: 17,
        animation: fifth,
    },
    {
        id: 18,
        animation: sixth,
    },
];
