export const launchParagraphs = [
    {
        id: 1,
        title: "Stakeholders interview",
        description: `Method of gathering information, Study process of the client's business, goals and needs, Establishing the client's mission, values and brand identity.`,
    },
    {
        id: 2,
        title: "Expert evaluation of UX/UI",
        description:
            "Evaluation of the user experience and interface design by an expert to ensure its effectiveness and convenience for users.",
    },
    {
        id: 3,
        title: "Hypothesis definition",
        description:
            "Formulation of a hypothesis about the cause-and-effect relationship between product elements and user behavior.",
    },
    {
        id: 4,
        title: "User Research",
        description: `Conducting qualitative and quantitative research to gather insights into users' behaviors, preferences, and needs.`,
    },
    {
        id: 5,
        title: "Competitors Research & Gap Analysis",
        description: `Analyzing the client's market and competitors to identify opportunities and challenges.`,
    },
    {
        id: 6,
        title: "Product Decomposition",
        description:
            "Breaking down the product into its key components and analyzing how they interact with each other.",
    },
    {
        id: 7,
        title: "Technical Background Analysis",
        description:
            "Assessing the technical feasibility of the proposed product and identifying any potential roadblocks or limitations.",
    },
    {
        id: 8,
        title: "Proposal Development",
        description: "Preparing a proposal for the client based on the data and analysis.",
    },
];

export const efficiencyParagraphs = [
    {
        id: 1,
        title: "Architectural Solution",
        description: `Building a thought-out strategy for developing a potentially prosperous product considering your expectations and requirements combined with our innovative ideas and expert approach.`,
    },
    {
        id: 2,
        title: "Agile UI Prototype",
        description:
            "Developing a design prototype operational enough to compare it with alternate options.",
    },
    {
        id: 3,
        title: "Prototype Evaluation with Users",
        description: `Involving the target audience to hear the users' voices to prevent features and get feedback on design, UX/UI, and overall expectations.`,
    },
    {
        id: 4,
        title: "UX Writing",
        description: `Developing UX content considering the company's tone of voice and the target audience's expected needs to provide a smooth user experience.`,
    },
    {
        id: 5,
        title: "Interactive UI prototype of main functionality",
        description: `Defining must-haves for the project's structure and developing a core UI to prevent users get confused when facing atypical design patterns.`,
    },
    {
        id: 6,
        title: "Design architecture vision",
        description: `Overviewing the product's key components and ensuring their 100% consistency across a website/app to represent an exceptionally user-friendly interface.`,
    },
    {
        id: 7,
        title: "Motion Design",
        description:
            "Incorporating complex motion design techniques such as animations, effects, vectors, sounds, typography, logos, and others, bringing some creativity.",
    },
    {
        id: 8,
        title: "Proposal Development",
        description: "Preparing a proposal for the client based on the data and analysis.",
    },
    {
        id: 9,
        title: "Design of the first page (2-4 options of design)",
        description: `We put together interaction design and information hierarchy to represent a few options in Figma for you to feel the initial UX, which includes visuals, colors, illustrations, typography, etc.`,
    },
    {
        id: 10,
        title: "Early UI-kit",
        description:
            "As the proper version is approved, the designer moves to UI kit creation, completing the project with relevant navigation components, input controls, loaders, modal windows, toggles, containers, etc.",
    },
    {
        id: 11,
        title: "Tech solution",
        description:
            "Automating processes, improving productivity, increasing security, and providing better user experiences.",
    },
    {
        id: 12,
        title: "Influence technology vision",
        description:
            "Ensuring that technology is used in a way that supports and enhances the overall mission",
    },
    {
        id: 13,
        title: "Roadmap",
        description:
            "Serves as a communication tool for stakeholders, providing a clear understanding of the scope.",
    },
];
