import React from "react";
import "./GraphicContent.scss";
import Lottie from "lottie-light-react";
const GraphicContent = ({ animation, content }) => {
    return (
        <div className='motion__content'>
            <div className='motion__content-paragraphs'>
                {content.map((paragraph) => (
                    <div className='motion__content-paragraph' key={paragraph.id}>
                        <span className='motion__content-paragraph-title'>{paragraph.title}</span>
                        <ul className='motion__content-paragraph-description'>
                            {paragraph.descriptions.map((description) => (
                                <li className='motion__content-item' key={description}>
                                    {description}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className='motion__content-schema graphic'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default GraphicContent;
