import { ReactSVG } from "react-svg";
import React from "react";
import iconIG from "./media/instagram.svg";
import iconLinkedIn from "./media/linkedin.svg";
import iconDribble from "./media/dribble.svg";
import iconBehance from "./media/behance.svg";

const socialData = [
    {
        url: "https://instagram.com/focusdigitalsyndicate?igshid=MzRlODBiNWFlZA==",
        icon: iconIG,
    },
    {
        url: "https://www.linkedin.com/company/focus-digital-syndicate/",
        icon: iconLinkedIn,
    },
    {
        url: "https://dribbble.com/fds-des",
        icon: iconDribble,
    },
    {
        url: "https://www.behance.net/designfds",
        icon: iconBehance,
    },
];

const FooterCopyright = () => {
    return (
        <div className='footerCopyright'>
            <div className='footerCopyright__info'>
                <p className='footerCopyright__text'>
                    {new Date().getFullYear()} &copy; Focus Digital Syndicate
                    <span>All rights reserved</span>
                </p>

                <p className='footerCopyright__address'>
                    FOCUS DIGITAL SYNDICATE SL, B72772981, ES, Barcelona, Av. De Les Drassanes 6{" "}
                </p>
            </div>

            <div className='footerCopyright__social'>
                {socialData.map((item, index) => (
                    <a
                        href={item.url}
                        target='_blank'
                        rel='noreferrer noopener'
                        key={index}
                        className='footerCopyright__social-item'
                    >
                        <ReactSVG src={item.icon} wrapper='span' />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default FooterCopyright;
