import React, { useState } from "react";
import EndFormModal from "./components/EndFormModal";
import { Formik, Form } from "formik";
import clientFormModel from "./FormModels/clientFormModel";
import ClientFormInfoBlock from "./components/ClientFormBlocks/ClientFormInfoBlock";
import ClientFormRadioBlock from "./components/ClientFormBlocks/ClientFormRadioBlock";
import ClientFormContactBlock from "./components/ClientFormBlocks/ClientFormContactBlock";
import validationSchema from "./validations/validationsClientForm";
import "./Forms.scss";
import clientFormInitialValues from "./InitialValues/clientFormInitialValues";
import { submitClientForm } from "./services/formSubmitService";
import { useTranslation } from "react-i18next";
import { getEndModalProps } from "./components/EndFormModalProps";
import { FormNavigation } from "./components/FormNavigation";
import PrivacyCheckboxField from "./FormFields/PrivacyCheckboxField";
const { formId, formField } = clientFormModel;

const ClientForm = () => {
    const { t } = useTranslation();
    const currentValidationSchema = validationSchema[0];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);
    const [privacyCheckboxIsActive, setPrivacyCheckboxIsActive] = useState(false);

    const submitForm = (values, actions) => {
        submitClientForm(values)
            .then(() => {
                setIsSuccess(true);
                setIsModalOpen(true);
                actions.setSubmitting(false);
            })
            .catch(() => {
                setIsSuccess(false);
                setIsModalOpen(true);
                actions.setSubmitting(false);
            });
    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    const popupProps = getEndModalProps(t);

    return (
        <div className='pageSection formPage'>
            <div className='wrapper client-wrapper'>
                <h2 className='formPage__title client-title'>{t("page.client-form.title")}</h2>
                <Formik
                    initialValues={clientFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, values }) => (
                        <Form id={formId} className={`actionForm actionFormSmall`}>
                            <ClientFormInfoBlock formField={formField} />
                            <ClientFormRadioBlock formField={formField} values={values} />
                            <ClientFormContactBlock formField={formField} values={values} />
                            <PrivacyCheckboxField
                                privacyCheckboxIsActive={privacyCheckboxIsActive}
                                setPrivacyCheckboxIsActive={setPrivacyCheckboxIsActive}
                            />
                            <FormNavigation
                                errors={errors}
                                isSubmitting={isSubmitting}
                                privacyCheckboxIsActive={privacyCheckboxIsActive}
                            />
                        </Form>
                    )}
                </Formik>
            </div>

            {isModalOpen && (
                <EndFormModal
                    isSuccess={isSuccess}
                    isOpen={isModalOpen}
                    succeedImage={popupProps.succeedImage}
                    succeedImageAlt={popupProps.succeedImageAlt}
                    succeedLabel={popupProps.succeedLabel}
                    succeedButtonLabel={popupProps.succeedButtonLabel}
                    onClickSucceedButton={popupProps.onClickSucceedButton}
                    failImage={popupProps.failImage}
                    failImageAlt={popupProps.failImageAlt}
                    failLabel={popupProps.failLabel}
                    failButtonLabel={popupProps.failButtonLabel}
                    onClickFailButton={popupProps.onClickFailButton}
                />
            )}
        </div>
    );
};
export default ClientForm;
