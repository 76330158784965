export const processParagraphs = [
    {
        id: 1,
        title: "Initial Contact",
        descriptions: [
            `Initial consultation and assessment of the client's needs and requirements`,
            `Identification of the right team with the necessary skills and expertise`,
            `Agreement on the scope of work, project timeline, and milestones`,
            `Discussion of communication protocols, reporting requirements, and quality assurance processes`,
            `Preparation and submission of a proposal`,
        ],
    },
    {
        id: 2,
        title: "Terms of Cooperation",
        descriptions: [
            `Finalization of the contract terms, including payment terms, intellectual property rights, and dispute resolution mechanisms`,
            `Creation of a detailed project plan and timeline`,
            `Establishment of clear roles and responsibilities for both the client and the outstaffing provider`,
            `Discussion of communication protocols, reporting requirements, and quality assurance processes`,
            `Onboarding of the team, including the provision of necessary equipment and software tools`,
            `Implementation of communication and reporting systems`,
        ],
    },
    {
        id: 3,
        title: "Proposal and Contract",
        descriptions: [
            `Analysis of the client's needs and requirements to develop a tailored solution`,
            `Identification of the specific skill sets and expertise required for the project`,
            `Development of a detailed proposal outlining the scope of work, team structure, costs, and project timeline`,
            `Negotiation and finalization of contract terms and conditions`,
            `The signing of the contract and commencement of the project`,
        ],
    },
];
