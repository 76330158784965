import { budgetOptions, chooseServiceOptions } from "../SelectOptions/dataSelection";
import clientFormModel from "../FormModels/clientFormModel";

const {
    formField: {
        client_name,
        client_company,
        client_email,
        client_business_niche,

        client_choose_service,
        client_budget,

        client_discovered_company,
    },
} = clientFormModel;

export default {
    [client_name.name]: "",
    [client_company.name]: "",
    [client_email.name]: "",
    [client_business_niche.name]: "",

    [client_choose_service.name]: chooseServiceOptions[0].value,
    [client_budget.name]: budgetOptions[0].value,

    [client_discovered_company.name]: "",
};
