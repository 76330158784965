import React from "react";
import Lottie from "lottie-light-react";
import { motion } from "framer-motion";
const ServicesMobileTab = ({ isShema, setIsShema, animation, content, isList }) => {
    return (
        <div className='development__content'>
            {!isShema ? (
                <motion.div
                    className='development__content-paragraphs'
                    initial={{ opacity: 0, transition: { duration: 1 } }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                    {content.map((content) =>
                        !isList ? (
                            <div className='development__content-paragraph' key={content.id}>
                                <span className='development__content-paragraph-title'>
                                    {content.title}
                                </span>
                                <p className='development__content-paragraph-description'>
                                    {content.description}
                                </p>
                            </div>
                        ) : (
                            <div className='devops__content-paragraph' key={content.id}>
                                <span className='devops__content-paragraph-title'>{content.title}</span>
                                <ul className='devops__content-paragraph-description'>
                                    {content.descriptions.map((description) => (
                                        <li className='devops__content-item' key={description}>
                                            {description}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ),
                    )}
                </motion.div>
            ) : (
                <div className='development__content-schema-wrapper'>
                    <Lottie animationData={animation} autoplay={true} loop={false} />
                </div>
            )}

            <button className='development__content-btn' onClick={() => setIsShema((prev) => !prev)}>
                View the {!isShema ? "flow" : "Process"}
            </button>
        </div>
    );
};

export default ServicesMobileTab;
