import React from "react";
import { useField } from "formik";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useFormikContext } from "formik";
import hoverCursor from "../../../assets/hover-cursor.png";
import { useTranslation } from "react-i18next";
const CheckboxGroupField = (props) => {
    const { t } = useTranslation();
    // const { errorText, options, ...rest } = props;
    // const [field, meta] = useField(props); // there were prittier errors
    const { options } = props;
    const [field] = useField(props);
    const { value: currentOption } = field;
    const { setFieldValue } = useFormikContext();

    return (
        <FormControl component='fieldset' sx={{ width: "100%" }}>
            <RadioGroup
                row
                name={field.name}
                onChange={(event) => {
                    setFieldValue(field.name, event.currentTarget.value);
                }}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <span
                    style={{
                        fontSize: "18px",
                        fontStyle: "italic",
                        fontWeight: 700,
                        lineHeight: "150%",
                    }}
                >
                    {t("page.dev-form.kind-of-project.title")}
                </span>
                <div className='checkbox-group-box-wrapper'>
                    {options.map((option, index) => (
                        <Box
                            key={"box-" + index}
                            sx={{
                                border: "1px solid #ccc",
                                padding: "8px 16px",
                                textAlign: "center",
                                borderRadius: "12px",
                                borderColor: currentOption === option.value ? "#41b56f" : "#ccc",
                                cursor: `url(${hoverCursor}), pointer`,
                            }}
                        >
                            <FormControlLabel
                                value={option.value}
                                sx={{ margin: "0", cursor: "inherit", height: "20px" }}
                                control={
                                    <Radio sx={{ visibility: "hidden", width: "0", height: "0" }} />
                                }
                                label={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                color: "#fff",
                                                marginRight: "5px",
                                            }}
                                        >
                                            {option.label}
                                        </Box>
                                        <Box sx={{ color: "grey" }}>{option.sublabel}</Box>
                                    </Box>
                                }
                            />
                        </Box>
                    ))}
                </div>
            </RadioGroup>
        </FormControl>
    );
};

export default CheckboxGroupField;
