import React, { useEffect } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { fetchCountryCode } from "../../../redux/actions/countyCodeActions";
import { useDispatch } from "react-redux";

const InputPhoneField = (props) => {
    // const { errorText, ...rest } = props; // there was prittier error
    const { ...rest } = props;
    const [field, meta] = useField(props);
    const [value, setValue] = React.useState("");
    const { setFieldValue } = useFormikContext();
    const countryCode = useSelector((state) => state.countryCodeReducer);
    const dispatch = useDispatch();
    const renderHelperText = () => {
        const [touched, error] = at(meta, "touched", "error");
        if (touched && error) {
            return error;
        }
    };

    useEffect(() => {
        if (countryCode) return;

        dispatch(fetchCountryCode());
    }, [countryCode, dispatch]);

    const handleChange = (newValue) => {
        //phone value never exceed 15 characters
        if (newValue.length > 15) return;
        setValue(newValue);
        setFieldValue(field.name, newValue.replace(/\s/g, "")); // remove space
    };

    return (
        <MuiTelInput
            sx={{ marginBottom: "2rem", ".MuiTelInput-Flag": { width: "35px" } }}
            fullWidth
            disableFormatting
            flagSize='small'
            defaultCountry={countryCode}
            excludedCountries={["AC", "TA"]}
            name={field.name}
            value={value}
            onChange={handleChange}
            error={meta.touched && meta.error}
            helperText={renderHelperText()}
            {...rest}
        />
    );
};

export default InputPhoneField;
