import React, { useRef, useEffect } from "react";
import "./BottomTabletComponent.scss";
import { ReactSVG } from "react-svg";
import { useMediaQuery } from "react-responsive";
import mobileVideo from "../../../../assets/animations/alienclick/mobile-video.mp4";
import videoAlienGreen from "../../../../pages/Products/img/alien-green.mp4";
import { iconsList } from "../AlienClick.constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlienPageIsLoading } from "../../../../redux/actions/loadingActions";
import { alienPageAnimationTitles } from "../../../../loadingServices/loading.service";
import Loader from "../../../../components/Loader/Loader";

const BottomTabletComponent = () => {
    const { t } = useTranslation();
    const videoMobileRef = useRef(null);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({
        query: `(max-width: 719px)`,
    });

    useEffect(() => {
        if (videoMobileRef.current) {
            videoMobileRef.current.currentTime = 0;
            videoMobileRef?.current?.play().catch((error) => console.log("Autoplay error:", error));
        }
    }, []);

    const setIsLoading = (key, value) => {
        dispatch(
            setAlienPageIsLoading({
                key: key,
                value: value,
            }),
        );
    };

    return (
        <div className='alienclick__tablet-content'>
            {isMobile ? (
                <div className='alienclick__tablet-content_top'>
                    <div className='alienclick__tablet-content_top-mobile'>
                        <div className='alienclick__tablet-content_top-logo alienclick-bottom-block'>
                            <video
                                autoPlay
                                muted
                                loop
                                playsInline
                                className='alienclick__content_top-logo-video'
                                onCanPlayThrough={() =>
                                    setIsLoading(alienPageAnimationTitles.alienContentTopVideo, true)
                                }
                            >
                                <source src={videoAlienGreen} type='video/mp4' />
                            </video>
                        </div>
                    </div>

                    <div className='alienclick__tablet-content_top-colors alienclick-bottom-block'>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-tang' />
                            <div className='alienclick__tablet-content_top-color-title '>#2757C1</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-rich' />
                            <div className='alienclick__tablet-content_top-color-title'>#08071B</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-penn' />
                            <div className='alienclick__tablet-content_top-color-title'>#121040</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-delft' />
                            <div className='alienclick__tablet-content_top-color-title'>#232C67</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-light-delft' />
                            <div className='alienclick__tablet-content_top-color-title'>#2E387A</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-marian' />
                            <div className='alienclick__tablet-content_top-color-title'>#36418E</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='alienclick__tablet-content_top'>
                    <div className='alienclick__tablet-content_top-logo alienclick-bottom-block'>
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            onCanPlayThrough={() =>
                                setIsLoading(alienPageAnimationTitles.alienContentTopVideo, true)
                            }
                        >
                            <source src={videoAlienGreen} type='video/mp4' />
                        </video>
                    </div>
                    <div className='alienclick__tablet-content_top-colors alienclick-bottom-block'>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-tang' />
                            <div className='alienclick__tablet-content_top-color-title '>#2757C1</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-rich' />
                            <div className='alienclick__tablet-content_top-color-title'>#08071B</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-penn' />
                            <div className='alienclick__tablet-content_top-color-title'>#121040</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-delft' />
                            <div className='alienclick__tablet-content_top-color-title'>#232C67</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-light-delft' />
                            <div className='alienclick__tablet-content_top-color-title'>#2E387A</div>
                        </div>
                        <div className='alienclick__tablet-content_top-color-block'>
                            <div className='alienclick__tablet-content_top-color alienclick-marian' />
                            <div className='alienclick__tablet-content_top-color-title'>#36418E</div>
                        </div>
                    </div>
                </div>
            )}

            <div className='alienclick__tablet-content_bottom'>
                <div className='alienclick__tablet-content_bottom-info-block'>
                    <div className='alienclick__tablet-content_bottom-info alienclick-bottom-block'>
                        <h3 className='alienclick__tablet-content_bottom-info-title'>
                            {t("page.alien-click.bottom-info-title")}
                        </h3>
                        <p className='alienclick__tablet-content_bottom-info-description'>
                            {t("page.alien-click.bottom-info-description")}
                        </p>
                    </div>
                    <div className='alienclick__tablet-content_bottom-images'>
                        <ul className='alienclick__tablet-content_bottom-list'>
                            {iconsList.map(({ id, icon }) => {
                                return (
                                    <li key={id} className='alienclick__content_bottom-item'>
                                        <ReactSVG
                                            src={icon}
                                            className='alienclick__content_bottom-icon'
                                            loading={() => <Loader />}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className='alienclick__tablet-content_bottom-devices-block alienclick-bottom-block'>
                    <video
                        muted
                        loop
                        className='alienclick__tablet-content_bottom-video'
                        ref={videoMobileRef}
                        onCanPlayThrough={() =>
                            setIsLoading(alienPageAnimationTitles.alienBottomVideo, true)
                        }
                    >
                        <source src={mobileVideo} type='video/mp4' />
                        {t("page.alien-click.not-support-video")}
                    </video>
                </div>
            </div>
        </div>
    );
};

export default BottomTabletComponent;
