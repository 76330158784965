import { Box } from "@mui/material";
import React from "react";
import InputField from "../../FormFields/InputField";

const ClientFormContactBlock = (props) => {
    const {
        formField: { client_discovered_company },
    } = props;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <InputField name={client_discovered_company.name} label={client_discovered_company.label} />
        </Box>
    );
};

export default ClientFormContactBlock;
