import React from "react";
import { Box } from "@mui/material";
import InputField from "../../FormFields/InputField";

const ClientInfoFormStep = (props) => {
    const {
        formField: { client_name, client_company, client_email, client_business_niche },
    } = props;

    return (
        <Box className='actionForm__fieldBlock'>
            <InputField
                name={client_name.name}
                label={client_name.label}
                className='actionForm__inputField'
            />
            <InputField
                name={client_company.name}
                label={client_company.label}
                className='actionForm__inputField'
            />
            <InputField
                name={client_email.name}
                label={client_email.label}
                type='email'
                className='actionForm__inputField'
            />
            <InputField
                name={client_business_niche.name}
                label={client_business_niche.label}
                type='text'
                className='actionForm__inputField'
            />
        </Box>
    );
};

export default ClientInfoFormStep;
