import React from "react";
import Lottie from "lottie-light-react";
const UXContent = ({ animation, content }) => {
    return (
        <div className='uiux__content'>
            <div className='uiux__content-paragraphs'>
                {content.map((uiParagraph) => (
                    <div className='uiux__content-paragraph' key={uiParagraph.id}>
                        <span className='uiux__content-paragraph-title'>{uiParagraph.title}</span>
                        <p className='uiux__content-paragraph-description'>{uiParagraph.description}</p>
                    </div>
                ))}
            </div>
            <div className='uiux__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default UXContent;
