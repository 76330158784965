export const routePaths = {
    mainPage: "/",
    modernaCasa: "moderna-casa",
    affPro: "affpro",
    rico: "rico",
    developmentForm: "development-form",
    designForm: "design-form",
    salesForm: "bd-sales-form",
    projectManagementForm: "project-manegement-form",
    clientForm: "client",
    privacyPolicyPage: "policy",
    alienClickPage: "alien-click",
    careersPage: "careers",
};
