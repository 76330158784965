export const uxParagraphs = [
    {
        id: 1,
        title: "Research and Analysis",
        description:
            "This stage involves gathering information about the target audience, their behaviors, and their needs, as well as analyzing the business goals and objectives.",
    },
    {
        id: 2,
        title: "User Persona Development",
        description:
            "We create user personas based on the research to represent the different user types and their goals, preferences, and pain points.",
    },
    {
        id: 3,
        title: "Information Architecture",
        description:
            "We create a structure and hierarchy for the content of the website or mobile application that is easy for users to navigate and find the information they need.",
    },
    {
        id: 4,
        title: "Wireframing",
        description:
            "We create low-fidelity wireframes to outline the layout and functionality of each screen, including the placement of buttons, menus, and other interactive elements.",
    },
    {
        id: 5,
        title: "Prototyping",
        description:
            "We create interactive prototypes of the website or mobile application to test the usability and functionality of the design and identify any issues that need to be addressed.",
    },
    {
        id: 6,
        title: "Usability Testing",
        description:
            "We conduct usability testing with real users to gather feedback and insights into how users interact with the product and make necessary adjustments to the design.",
    },
];

export const uiParagraphs = [
    {
        id: 1,
        title: "Research and Analysis",
        description:
            "This stage involves understanding the target audience, the business goals and objectives, and the brand identity.",
    },
    {
        id: 2,
        title: "Style Exploration",
        description:
            "We explore different styles, color palettes, typography, and iconography to create a visual language that aligns with the brand identity and resonates with the target audience.",
    },
    {
        id: 3,
        title: "Sketching and Ideation",
        description:
            "We brainstorm ideas and sketch out different design concepts to identify the best direction for the design.",
    },
    {
        id: 4,
        title: "Visual Design",
        description:
            "We create high-fidelity designs that incorporate visual elements such as typography, color schemes, and images to create a visually appealing and cohesive design.",
    },
    {
        id: 5,
        title: "Handoff and Collaboration",
        description:
            "We work closely with the development team to ensure the design is implemented accurately, and provide assets and documentation for a smooth handoff.",
    },
];
