import { useEffect, useRef } from "react";
import lottie from "lottie-web";

const usePointAnimations = () => {
    const containerUsa = useRef(null);
    const containerSpain = useRef(null);
    const containerUkraine = useRef(null);

    const animationUsa = useRef(null);
    const animationSpain = useRef(null);
    const animationUkraine = useRef(null);

    useEffect(() => {
        const containerRefs = [
            { containerRef: containerSpain, animationRef: animationSpain },
            { containerRef: containerUsa, animationRef: animationUsa },
            { containerRef: containerUkraine, animationRef: animationUkraine },
        ];

        containerRefs.forEach(({ containerRef, animationRef }) => {
            const animationConfigs = {
                container: containerRef.current,
                renderer: "svg",
                loop: true,
                autoplay: false,
                animationData: require("../../assets/animations/contactMap/point-location.json"),
            };

            animationRef.current = lottie.loadAnimation(animationConfigs);
        });

        return () => {
            containerRefs.forEach(({ animationRef }) => {
                if (animationRef.current) {
                    animationRef.current.destroy();
                }
            });
        };
    }, []);

    const playAnimation = (animationRef) => {
        if (animationRef.current) {
            animationRef.current.play();
        }
    };

    const handleMouseLeave = (animationRef) => {
        if (animationRef.current) {
            animationRef.current.stop();
            animationRef.current.goToAndStop(0);
        }
    };

    return [
        animationUsa,
        animationSpain,
        animationUkraine,
        containerUsa,
        containerSpain,
        containerUkraine,
        playAnimation,
        handleMouseLeave,
    ];
};

export default usePointAnimations;
