import React from "react";
import "./ProcessContent.scss";

import Lottie from "lottie-light-react";
const ProcessContent = ({ animation, content }) => {
    return (
        <div className='aiml__content'>
            <div className='aiml__content-paragraphs'>
                {content.map((aimlParagraph) => (
                    <div className='aiml__content-paragraph' key={aimlParagraph.id}>
                        <span className='aiml__content-paragraph-title'>{aimlParagraph.title}</span>
                        <p className='aiml__content-paragraph-description'>
                            {aimlParagraph.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className='aiml__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={false} />
            </div>
        </div>
    );
};

export default ProcessContent;
