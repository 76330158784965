import React from "react";
import { NavLink } from "react-router-dom";
import { routePaths } from "../../../shared/routes";

const PrivacyCheckboxField = (props) => {
    const { privacyCheckboxIsActive, setPrivacyCheckboxIsActive } = props;

    return (
        <div className='actionForm__privacyWrapper'>
            <div className='privacyCheckbox'>
                <input
                    type='checkbox'
                    checked={privacyCheckboxIsActive}
                    onClick={() => setPrivacyCheckboxIsActive(!privacyCheckboxIsActive)}
                    id='checkbox'
                    readOnly
                />
                <label htmlFor='checkbox'></label>
            </div>
            <span>
                I have read and accept the{" "}
                <NavLink to={routePaths.privacyPolicyPage}>terms and conditions</NavLink> and the{" "}
                <NavLink to={routePaths.privacyPolicyPage}>privacy policy</NavLink> of FDS.
            </span>
        </div>
    );
};

export default PrivacyCheckboxField;
