export const LoadingActionTypes = {
    // MAIN PAGE
    MAIN_PAGE_SET_IS_LOADING: "MAIN_PAGE_SET_IS_LOADING",
    MAIN_PAGE_SET_IS_INITIAL_STATE: "MAIN_PAGE_SET_IS_INITIAL_STATE",
    // ALIEN PAGE
    ALIEN_PAGE_SET_IS_LOADING: "ALIEN_PAGE_SET_IS_LOADING",
    ALIEN_PAGE_SET_IS_INITIAL_STATE: "ALIEN_PAGE_SET_IS_INITIAL_STATE",
    // AFFPRO PAGE
    AFFPRO_PAGE_SET_IS_LOADING: "AFFPRO_PAGE_SET_IS_LOADING",
    AFFPRO_PAGE_SET_IS_INITIAL_STATE: "AFFPRO_PAGE_SET_IS_INITIAL_STATE",
};
