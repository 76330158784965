export const checkIfAllLoaded = (animationsData) =>
    Object.values(animationsData).every((value) => value === true);
// export const checkIfAllLoaded = (animationsData) =>false;  // to be able to continuously view the loader's work //

// MAIN PAGE

export const mainPageAnimationTitles = {
    focusAnimation: "focus-animation",
    digitalAnimation: "digital-animation",
    syndicateAnimation: "syndicate-animation",
    ricoVideo: "main-page-rico-video",
    affProVideo: "main-page-affpro-video",
};

export const initialMainPageAnimationsStatus = {
    [mainPageAnimationTitles.focusAnimation]: false,
    [mainPageAnimationTitles.digitalAnimation]: false,
    [mainPageAnimationTitles.syndicateAnimation]: false,
    [mainPageAnimationTitles.ricoVideo]: false,
    [mainPageAnimationTitles.affProVideo]: false,
};

// ALIEN PAGE

export const alienPageAnimationTitles = {
    alienHeaderVideo: "alien-header-video",
    // alienAboutVideo: 'alien-about-video',
    alienBottomVideo: "alien-bottom-video",
    // alienContentTopVideo: 'alien-content-top-video',
};

export const initialAlienPageAnimationsStatus = {
    [alienPageAnimationTitles.alienHeaderVideo]: false,
    // [alienPageAnimationTitles.alienAboutVideo]: false,
    [alienPageAnimationTitles.alienBottomVideo]: false,
    // [alienPageAnimationTitles.alienContentTopVideo]: false,
};

// AFFPRO PAGE

export const affproPageAnimationTitles = {
    alienAboutVideo: "affpro-about-video",
};

export const initialAffproPageAnimationsStatus = {
    [alienPageAnimationTitles.alienAboutVideo]: false,
};
