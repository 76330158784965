import { React } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { TextField } from "@mui/material";
import renderAsterisk from "../../../shared/renderAsterisk";
const InputField = (props) => {
    // const { errorText, ...rest } = props; // there was prittier error
    const { label, ...rest } = props;
    const [field, meta] = useField(props);
    const isError = meta.touched && meta.error;
    const renderHelperText = () => {
        const [touched, error] = at(meta, "touched", "error");
        if (touched && error) {
            return error;
        }
    };

    return (
        <TextField
            sx={{ marginBottom: "2rem" }}
            fullWidth
            className='customTextField'
            type='text'
            error={isError}
            helperText={renderHelperText()}
            label={renderAsterisk(label, isError)}
            {...field}
            {...rest}
        />
    );
};

export default InputField;
