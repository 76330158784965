import React from "react";
import "./ProcessContent.scss";
import Lottie from "lottie-light-react";
const ProcessContent = ({ animation, content, isLoop }) => {
    return (
        <div className='outstaff__content'>
            <div className='outstaff__content-paragraphs'>
                {content.map((processParagraph) => (
                    <div className='outstaff__content-paragraph' key={processParagraph.id}>
                        <span className='outstaff__content-paragraph-title'>
                            {processParagraph.title}
                        </span>
                        <ul className='outstaff__content-paragraph-description'>
                            {processParagraph.descriptions.map((description) => (
                                <li className='outstaff__content-item' key={description}>
                                    {description}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <div className='outstaff__content-schema'>
                <Lottie animationData={animation} autoplay={true} loop={isLoop ? true : false} />
            </div>
        </div>
    );
};

export default ProcessContent;
