export default {
    formId: "clientForm",
    formField: {
        client_name: {
            name: "client_name",
            label: "Name*",
            requiredErrorMsg: "Name is required",
            specialCharactersErrorMsg: "This field should not contain special characters",
        },
        client_company: {
            name: "client_company",
            label: "Company name*",
            requiredErrorMsg: "Company name is required",
            specialCharactersErrorMsg: "This field should not contain special characters",
        },
        client_email: {
            name: "client_email",
            label: "Email*",
            requiredErrorMsg: "Email is required",
            invalidErrorMsg: "Email is not valid",
        },
        client_business_niche: {
            name: "client_business_niche",
            label: "Your business niche*",
            requiredErrorMsg: "Business niche is required",
            invalidErrorMsg: "Email is not valid",
        },
        client_choose_service: {
            name: "client_choose_service",
            label: "Service*",
            requiredErrorMsg: "Service is required",
        },
        client_budget: {
            name: "client_budget",
            label: "Budget*",
            requiredErrorMsg: "Budget is required",
        },
        client_discovered_company: {
            name: "client_discovered_company",
            label: "I discovered your company via...",
        },
    },
};
