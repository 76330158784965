import * as Yup from "yup";
import { devFormModelObject } from "../FormModels/devFormModel";
import { emailRegexp, linkRegex, phoneRegexp, safeSQLStringRegexp } from "./regexp";
import { checkEmailValues, isEmailAlreadyUsed } from "../services/formSubmitService";

const {
    formField: {
        full_name,
        location,

        position,
        employee_level,
        experience,
        skills,

        hourly_salary,
        email,
        phone_numbers,
        telegram,
        linkedin,
        portfolio,
    },
} = devFormModelObject;

export const devValidationSchema = Yup.object().shape({
    [full_name.name]: Yup.string()
        .matches(safeSQLStringRegexp, `${full_name.specialCharactersErrorMsg}`)
        .required(`${full_name.requiredErrorMsg}`),
    [location.name]: Yup.string().required(`${location.requiredErrorMsg}`),
    [position.name]: Yup.string().required(`${position.requiredErrorMsg}`),
    [employee_level.name]: Yup.string().required(`${employee_level.requiredErrorMsg}`),
    [experience.name]: Yup.number().required(`${experience.requiredErrorMsg}`).positive().integer(),
    [skills.name]: Yup.array().of(
        Yup.object().shape({
            technology: Yup.string().matches(safeSQLStringRegexp, `${skills.specialCharactersErrorMsg}`),
            experience: Yup.number(),
        }),
    ),
    [hourly_salary.name]: Yup.number()
        .required(`${hourly_salary.requiredErrorMsg}`)
        .positive()
        .integer(),
    [phone_numbers.name]: Yup.string().matches(phoneRegexp, `${phone_numbers.invalidErrorMsg}`),
    [email.name]: Yup.string()
        .required(`${email.requiredErrorMsg}`)
        .matches(emailRegexp, `${email.invalidErrorMsg}`)
        .test("verified", "Email already used", async (value) => {
            const verified = await isEmailAlreadyUsed(value, checkEmailValues.devFormValue);
            return !verified;
        }),
    [telegram.name]: Yup.string()
        .matches(safeSQLStringRegexp, `${telegram.specialCharactersErrorMsg}`)
        .required(`${telegram.requiredErrorMsg}`),
    [linkedin.name]: Yup.string()
        .matches(linkRegex, `${linkedin.specialCharactersErrorMsg}`)
        .required(`${linkedin.requiredErrorMsg}`),
    [portfolio.name]: Yup.string().matches(linkRegex, `${portfolio.specialCharactersErrorMsg}`),
    // [candidate_cv_link.name]: Yup.string().matches(linkRegex,`${candidate_cv_link.specialCharactersErrorMsg}`).required(`${candidate_cv_link.requiredErrorMsg}`),
});
