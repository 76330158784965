import React, { useEffect, useRef, useState } from "react";
import "./AlienClickBlock.scss";
import { ReactSVG } from "react-svg";
import linkIcon from "../../../assets/svg/link-icon.svg";
import alienclick from "../../../assets/svg/alien-click-logo.svg";
import behanceIcon from "../../../assets/svg/behance.svg";
import { useMediaQuery } from "react-responsive";
import HeaderTabletComponent from "./HeaderTabletComponent/HeaderTabletComponent";
import BottomTabletComponent from "./BottomTabletComponent/BottomTabletComponent";
import Marquee from "react-fast-marquee";
import Lottie from "lottie-light-react";
import { tickerElements, iconsList } from "./AlienClick.constants";
import videoAlienCLick from "../../../assets/animations/alienclick/alien-header-video.mp4";
import mobileVideo from "../../../assets/animations/alienclick/mobile-video.mp4";
import alienGreen from "../../../assets/animations/alienclick/alien-green.json";
import { useTranslation } from "react-i18next";
import {
    alienPageAnimationTitles,
    checkIfAllLoaded,
    initialAlienPageAnimationsStatus,
} from "../../../loadingServices/loading.service";
import { useDispatch, useSelector } from "react-redux";
import {
    setAlienPageIsInitialState,
    setAlienPageIsLoading,
} from "../../../redux/actions/loadingActions";
import Loader from "../../../components/Loader/Loader";
import alienUfo from "../../../assets/animations/alienclick/alien-ufo.json";

export const services = [
    "Engineering",
    "Web development",
    "Web design",
    "UI",
    "UX",
    "Research",
    "Prototyping",
    "Construction",
    "Real estate",
];

export const stack = [
    {
        id: 1,
        title: "Research",
        subtitles: [
            {
                id: 11,
                subtitle: "Research and Analysis",
            },
            {
                id: 12,
                subtitle: "User Persona Development",
            },
            {
                id: 13,
                subtitle: "Content Strategy",
            },
            {
                id: 14,
                subtitle: "Information Architecture",
            },
            {
                id: 15,
                subtitle: "Usability Testing",
            },
        ],
    },
    {
        id: 2,
        title: "Design",
        subtitles: [
            {
                id: 21,
                subtitle: "Style Exploration",
            },
            {
                id: 22,
                subtitle: "Sketching and Ideation",
            },
            {
                id: 23,
                subtitle: "Wireframing",
            },
            {
                id: 24,
                subtitle: "Prototyping",
            },
            {
                id: 25,
                subtitle: "Visual Design",
            },
        ],
    },
    {
        id: 3,
        title: "Development",
        subtitles: [
            {
                id: 21,
                subtitle: "Front-end development",
            },
            {
                id: 22,
                subtitle: "Back-end development",
            },
            {
                id: 23,
                subtitle: "Testing and quality assurance",
            },
        ],
    },
];

export const alienclickLink = "https://alienclick.io/";

const AlienClickBlock = () => {
    const [currentStack, setCurrentStack] = useState(stack[0]);
    const [allAnimationsLoaded, setAllAnimationsLoaded] = useState(false);
    const aboutInfoBlockRef = useRef(null);
    const videoUfoRef = useRef(null);
    const videoUfoRefBlock = useRef(null);
    const videoMobileRef = useRef(null);
    const colorsBlockRef = useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery({
        query: `(min-width: 1280px)`,
    });

    const isMobile = useMediaQuery({
        query: `(max-width: 719px)`,
    });

    useEffect(() => {
        const handleScroll = () => {
            if (!isDesktop) {
                return;
            }
            const leftBlock = aboutInfoBlockRef.current;
            const leftBlockBottom = leftBlock.getBoundingClientRect().bottom;
            const rightBlock = videoUfoRefBlock.current;
            const rightBlockBottom = rightBlock.getBoundingClientRect().bottom;
            const colorsBlock = colorsBlockRef.current;
            const colorsBlockBottom = colorsBlock.getBoundingClientRect().bottom;
            const windowHeight = window.innerHeight;

            if (leftBlockBottom < windowHeight) {
                leftBlock.classList.add("alienclick-animate-in");
            }

            if (rightBlockBottom < windowHeight) {
                rightBlock.classList.add("alienclick-animate-in");
            }

            if (colorsBlockBottom < windowHeight) {
                colorsBlock.classList.add("alienclick-animate-in");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isDesktop]);

    useEffect(() => {
        if (videoUfoRef.current) {
            videoUfoRef.current.currentTime = 0;
            videoUfoRef?.current?.play().catch((error) => console.log("Autoplay error:", error));
        }
    }, []);
    useEffect(() => {
        if (videoMobileRef.current) {
            videoMobileRef.current.currentTime = 0;
            videoMobileRef?.current?.play().catch((error) => console.log("Autoplay error:", error));
        }
    }, []);

    const alienPageAnimationsData = useSelector((state) => state.loadingReducer.alienPageAnimationsData);

    useEffect(() => {
        if (checkIfAllLoaded(alienPageAnimationsData)) {
            setAllAnimationsLoaded(true);
        }
    }, [alienPageAnimationsData]);

    useEffect(() => {
        return () => {
            dispatch(setAlienPageIsInitialState(initialAlienPageAnimationsStatus));
        };
    }, []);

    const setIsLoading = (key, value) => {
        dispatch(
            setAlienPageIsLoading({
                key: key,
                value: value,
            }),
        );
    };

    return (
        <div className='alienclick__wrapper'>
            {!allAnimationsLoaded && <Loader />}
            <div className='alienclick__navigation'>
                {!isMobile && (
                    <div className='alienclick__item'>
                        <div className='alienclick__item-main-svg'>
                            <ReactSVG src={alienclick} wrapper='span' loading={() => <Loader />} />
                        </div>
                        <p>{t("page.alien-click.click-all")}</p>
                    </div>
                )}
                <div className='alienclick__item'>2023</div>
                <div className='alienclick__item'>{t("page.alien-click.location")}</div>
            </div>
            {isDesktop ? (
                <header className='alienclick__header'>
                    <video
                        className='alienclick__header-video'
                        autoPlay
                        muted
                        loop
                        playsInline
                        onCanPlayThrough={() =>
                            setIsLoading(alienPageAnimationTitles.alienHeaderVideo, true)
                        }
                    >
                        <source src={videoAlienCLick} type='video/mp4' />
                    </video>
                    <div className='alienclick__header-info'>
                        <a
                            href={alienclickLink}
                            className='alienclick__header-link'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <h1 className='alienclick__header-title'>
                                {t("page.alien-click.click-all")}
                            </h1>
                            <ReactSVG
                                src={linkIcon}
                                className='alienclick__header-svg'
                                wrapper='span'
                                loading={() => <Loader />}
                            />
                        </a>
                        <h4 className='alienclick__header-description'>
                            {t("page.alien-click.header-description")}
                        </h4>
                        <div className='alienclick__header-list'>
                            {services.map((service, index) => (
                                <div className='alienclick__header-list-item' key={service + index}>
                                    {service}
                                </div>
                            ))}
                        </div>
                        <a
                            className='alienclick__header-behance'
                            href='https://www.behance.net/designfds'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span>{t("page.alien-click.view-on-behance")}</span>

                            <div className='alienclick__header-behance-svg'>
                                <ReactSVG
                                    src={behanceIcon}
                                    className='alienclick__header-behance-svg'
                                    wrapper='span'
                                    loading={() => <Loader />}
                                />
                            </div>
                        </a>
                    </div>
                </header>
            ) : (
                <HeaderTabletComponent />
            )}
            <>
                {isDesktop && (
                    <Marquee
                        pauseOnHover
                        style={{
                            display: "flex",
                            padding: "15px 0",
                        }}
                    >
                        {tickerElements.map((tickerElement) => (
                            <div className='alienclick__animation' key={tickerElement.id}>
                                <div className='alienclick__animation-img'>
                                    {/* <Lottie
                    //   animationData={tickerElement.animation}
                    autoplay={true}
                    loop={true}
                  /> */}
                                </div>
                                <div className='alienclick__animation-text'>{tickerElement.title}</div>
                            </div>
                        ))}
                    </Marquee>
                )}
            </>
            <div className='alienclick__stack'>
                <div className='alienclick__stack-titles'>
                    {stack.map((stackItem) => (
                        <div
                            key={stackItem.id}
                            className={`alienclick__stack-title ${
                                stackItem.id === currentStack.id ? "active-stack-title" : ""
                            }`}
                            onClick={() => setCurrentStack(stackItem)}
                        >
                            {stackItem.title}
                        </div>
                    ))}
                </div>
                <div className='alienclick__stack-subtitles'>
                    {currentStack.subtitles.map((subtitleItem) => (
                        <div key={subtitleItem.id} className='alienclick__stack-subtitle'>
                            {subtitleItem.subtitle}
                        </div>
                    ))}
                </div>
            </div>
            <div className='alienclick__about'>
                <div className='alienclick__about-info' ref={aboutInfoBlockRef}>
                    <div className='alienclick__about-title'>
                        <h2> {t("page.alien-click.about")}</h2>
                        <h2>{t("page.alien-click.click-all")}</h2>
                    </div>
                    <p className='alienclick__about-description'>
                        {t("page.alien-click.about-description")}
                    </p>
                </div>
                {isDesktop && (
                    <div className='alienclick__about-video' ref={videoUfoRefBlock}>
                        {/* <video
              muted
              loop
              ref={videoUfoRef}
              onCanPlayThrough={() =>
                setIsLoading(alienPageAnimationTitles.alienAboutVideo, true)
              }
            >
              <source src={ufoVideo} type="video/mp4" />
              {t("page.alien-click.not-support-video")}
            </video> */}

                        <Lottie animationData={alienUfo} autoplay={true} loop={true} />
                    </div>
                )}
            </div>
            {isDesktop ? (
                <div className='alienclick__content'>
                    <div className='alienclick__content_top'>
                        <div className='alienclick__content_top-logo alienclick-bottom-block'>
                            <Lottie animationData={alienGreen} autoplay={true} loop={true} />
                        </div>
                        <div className='alienclick__content_top-colors alienclick-bottom-block'>
                            <div className='alienclick__content_top-colors-block' ref={colorsBlockRef}>
                                <div className='alienclick__content_top-color-block'>
                                    <div className='alienclick__content_top-color alienclick-tang' />
                                    <div className='alienclick__content_top-color-title '>#0A0A0A</div>
                                </div>
                                <div className='alienclick__content_top-color-block'>
                                    <div className='alienclick__content_top-color alienclick-rich' />
                                    <div className='alienclick__content_top-color-title'>#00FF66</div>
                                </div>
                                <div className='alienclick__content_top-color-block'>
                                    <div className='alienclick__content_top-color alienclick-penn' />
                                    <div className='alienclick__content_top-color-title'>#187E41</div>
                                </div>
                                <div className='alienclick__content_top-color-block'>
                                    <div className='alienclick__content_top-color alienclick-delft' />
                                    <div className='alienclick__content_top-color-title'>#6C14DC</div>
                                </div>
                                <div className='alienclick__content_top-color-block'>
                                    <div className='alienclick__content_top-color alienclick-light-delft' />
                                    <div className='alienclick__content_top-color-title'>#300A60</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='alienclick__content_bottom'>
                        <div className='alienclick__content_bottom-info-block'>
                            <div className='alienclick__content_bottom-info alienclick-bottom-block'>
                                <span className='alienclick__content_bottom-info-title'>
                                    {t("page.alien-click.bottom-info-title")}
                                </span>
                                <p className='alienclick__content_bottom-info-description'>
                                    {t("page.alien-click.bottom-info-description")}
                                </p>
                            </div>
                            <div className='alienclick__content_bottom-images'>
                                <ul className='alienclick__content_bottom-list'>
                                    {iconsList.map(({ id, icon }) => {
                                        return (
                                            <li key={id} className='alienclick__content_bottom-item'>
                                                <ReactSVG
                                                    src={icon}
                                                    className='alienclick__content_bottom-icon'
                                                    loading={() => <Loader />}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div
                            className='alienclick__content_bottom-devices-block alienclick-bottom-block'
                            //   ref={videoAfproRefBlock}
                        >
                            <video
                                muted
                                loop
                                className='alienclick__content_bottom-devices-video'
                                ref={videoMobileRef}
                                onCanPlayThrough={() =>
                                    setIsLoading(alienPageAnimationTitles.alienBottomVideo, true)
                                }
                            >
                                <source src={mobileVideo} type='video/mp4' />
                                {t("page.alien-click.not-support-video")}
                            </video>
                        </div>
                    </div>
                </div>
            ) : (
                <BottomTabletComponent />
            )}
        </div>
    );
};

export default AlienClickBlock;
