import React, { useState } from "react";
import "./DesignTab.scss";
import UIUXBlock from "./UIUXBlock/UIUXBlock";
import MotionDesignBlock from "./MotionDesignBlock/MotionDesignBlock";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentService } from "../../../redux/actions/servicesActions";
import desing from "../../../assets/animations/services/design/ui-ux.json";
import desingMotion from "../../../assets/animations/services/design/motion.json";
import HoverIconsAnimation from "../../../components/HoverIconsAnimation/HoverIconsAnimation";
import { handleTabClick } from "../../../shared/handleTabClick";
const designItems = [
    {
        number: 1,
        id: "ux",
        icon: desing,
        title: "UI/UX Services",
        titleBottom: "",
        className: "ux",
        children: <UIUXBlock />,
    },
    {
        number: 2,
        id: "motionDesign",
        icon: desingMotion,
        title: "Graphic & Motion",
        titleBottom: "",
        className: "motionDesign",
        children: <MotionDesignBlock />,
    },
];

const DesignTab = () => {
    const [currentItem, setCurrentItem] = useState(0);
    const dispatch = useDispatch();
    const currentService = useSelector((state) => state.servicesReducer.currentService);

    return (
        <div className='service_tab'>
            <div className='service_tab-titles'>
                {designItems.map((item, index) => (
                    <div
                        className={`servicesItem ${"servicesItem--" + item.className} ${
                            currentService === item.id ? "active" : ""
                        }`}
                        key={item.id}
                        onClick={() =>
                            handleTabClick(item.id, dispatch, setCurrentService, currentService)
                        }
                        onMouseEnter={() =>
                            item.number === index + 1 ? setCurrentItem(item.number) : 0
                        }
                        onMouseLeave={() => {
                            setCurrentItem(0);
                        }}
                    >
                        <div className='servicesItem__icon'>
                            <HoverIconsAnimation
                                animationData={item.icon}
                                currentItem={currentItem}
                                currentNumb={item.number}
                            />
                        </div>
                        <div className='servicesItem__text'>
                            <p>
                                {item.title}{" "}
                                <span className='servicesItem__textLine'>{item.titleBottom}</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div>{designItems.find((designItem) => designItem.id === currentService)?.children}</div>
        </div>
    );
};

export default DesignTab;
