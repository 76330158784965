import React, { useState } from "react";
import "./ElevantePhaseBlock.scss";
import wayIcon from "../../../../assets/svg/services/way.svg";
import ServiceNavigation from "../../../../shared/components/ServiceNavigation/ServiceNavigation";
import LaunchContent from "./LaunchContent/LaunchContent";
import EfficiencyContent from "./EfficiencyContent/EfficiencyContent";
import developmentAnimation from "../../../../assets/animations/services/engineering/Elevate.json";
import Lottie from "lottie-light-react";
import ServicesMobileTab from "../../ServicesMobileTab/ServicesMobileTab";
import animation from "../../../../assets/animations/servicesTabs/elevate-launch.json";
import { launchParagraphs } from "./ElevantePhase.constants";
import { useMediaQuery } from "react-responsive";

const ElevantePhaseBlock = () => {
    const [currentNavItem, setCurrentNavItem] = useState("Launch");
    const [isShema, setIsShema] = useState(true);
    const isMobile = useMediaQuery({
        query: `(max-width: 720px)`,
    });
    return (
        <div className='phase__wrapper'>
            <div className='phase__title'>
                <h4 className='phase__title-text'>Elevate Phase</h4>
                <div className='servicesItem__icon'>
                    <Lottie animationData={developmentAnimation} autoplay={true} loop={true} />
                </div>
            </div>
            <div className='phase__description'>
                Elevate Phase is a comprehensive set of activities designed to help businesses create a
                custom service that meets their unique needs, from stakeholder interviews to product
                concept validation. We provide a complete range of services, including expert evaluations
                of UX/UI, target audience analysis, competitors research, and proposal development to
                produce a user-friendly custom service. Our Elevate Phase approach includes creating an
                agile UI prototype, an interactive UI prototype, motion design, and product decomposition
                to develop a service that aligns with the clients needs and meets their objectives.
            </div>
            <ServiceNavigation
                firstNavItem='Launch'
                secondNavItem='Our efficiency'
                currentNavItem={currentNavItem}
                setCurrentNavItem={setCurrentNavItem}
                icon={wayIcon}
            />
            <div className='phase__content'>
                {currentNavItem === "Launch" ? (
                    <>
                        {!isMobile ? (
                            <LaunchContent animation={animation} content={launchParagraphs} />
                        ) : (
                            <ServicesMobileTab
                                isShema={isShema}
                                setIsShema={setIsShema}
                                animation={animation}
                                content={launchParagraphs}
                            />
                        )}
                    </>
                ) : (
                    <EfficiencyContent />
                )}
            </div>
        </div>
    );
};

export default ElevantePhaseBlock;
